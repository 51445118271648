import { SelectOption, Language } from 'models';

export const genders: SelectOption[] = [
  {
    name: 'Unknown',
    value: 'Unknown',
  },
  {
    name: 'Male',
    value: 'Male',
  },
  {
    name: 'Female',
    value: 'Female',
  },
  {
    name: 'NonBinary',
    value: 'NonBinary',
  },
];

export const defaultLanguage: Language = {
  name: 'English',
  countryCode: 'GB',
  languageCode: 'en',
};

export const days = [
  {
    date: '2023-01-02',
    index: 1,
  },
  {
    date: '2023-01-03',
    index: 2,
  },
  {
    date: '2023-01-04',
    index: 3,
  },
  {
    date: '2023-01-05',
    index: 4,
  },
  {
    date: '2023-01-06',
    index: 5,
  },
  {
    date: '2023-01-07',
    index: 6,
  },
  {
    date: '2023-01-08',
    index: 0,
  },
];
