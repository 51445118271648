import { FC, ChangeEvent } from 'react';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
} from './styles';

type Props = {
  name: string;
  isChecked?: boolean;
  isDefaultChecked?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: FC<Props> = ({
  name,
  isChecked,
  isDefaultChecked,
  isDisabled = false,
  isError = false,
  onChange,
}) => (
  <CheckboxContainer>
    <HiddenCheckbox
      id={name}
      name={name}
      checked={isChecked}
      defaultChecked={isDefaultChecked}
      disabled={isDisabled}
      onChange={onChange}
      readOnly={!onChange}
    />
    <StyledCheckbox
      isChecked={isChecked || isDefaultChecked || false}
      isError={isError}
    >
      <Icon viewBox="0 0 24 24">
        <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
