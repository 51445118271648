import { useState, useEffect } from 'react';

type WindowSize = {
  width: number;
  height: number;
};

function getWindowSize(): WindowSize {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize(): void {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
