import styled from 'styled-components';

type Props = {
  isLast: boolean;
};

export const Item = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-bottom: ${({ theme, isLast }) =>
    !isLast ? `1px solid ${theme.colors.borderLight}` : 'none'};

  h4 {
    margin: 0;
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  & > * {
    overflow: hidden;
  }
`;

export const ItemHeaderCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * { 
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ItemGrid = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ItemText = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  white-space: nowrap;
  overflow: visible;
`;

export const ItemProgress = styled.div`
  width: 100%;
`;

export const ImgContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 99px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border: 1px solid ${({ theme }) => theme.colors.backgroundDark};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
