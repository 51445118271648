import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import {
  localizationSelector,
  useGetActivityTypesQuery,
  useRegisterActivityMutation,
} from 'store';
import { useAppSelector } from 'hooks/redux';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import RegisterActivityForm from 'components/forms/RegisterActivityForm';

import { Header, Body } from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  allowAllActivities?: boolean;
  allowedActivities?: string[];
};

const RegisterActivityModal: FC<Props> = ({
  isOpen,
  onClose,
  allowedActivities,
  allowAllActivities = true,
}) => {
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const [registerActivity, registerResult] = useRegisterActivityMutation();

  // Activity types
  const types = useGetActivityTypesQuery({
    language: language?.languageCode,
  });

  // Success
  useEffect(() => {
    if (registerResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'registerActivityModalSuccess',
          defaultMessage: 'Activity registration successful',
          description: 'Register activity modal success',
        })
      );
      onClose();
    }
  }, [registerResult, intl, onClose]);

  // Data
  const data = useMemo(() => {
    if (!types.data) {
      return [];
    }
    if (allowAllActivities || !allowedActivities) {
      return types.data;
    }
    return types.data.filter((type) =>
      allowedActivities.some((activity) => activity === type.slug)
    );
  }, [types, allowedActivities, allowAllActivities]);

  // Content
  const content = useMemo(() => {
    if (registerResult.isLoading || types.isLoading) {
      return <Loader padding color="blue" />;
    }
    if (!data.length) {
      return null;
    }
    return (
      <RegisterActivityForm
        data={data}
        submitForm={registerActivity}
        isOpen={isOpen}
      />
    );
  }, [data, types, registerResult, registerActivity, isOpen]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="registerActivityModalTitle"
              defaultMessage="Register activity"
              description="Register activity modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default RegisterActivityModal;
