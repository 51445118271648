import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { TabMenuItem } from 'models';
import { ContentWidth, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useGetMyHealthWheelQuery,
  useGetUserLevelsQuery,
  useGetUserQuery,
} from 'store';
import * as routes from 'router/routes';

import TabMenu from 'components/UI/TabMenu';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import { HealthIcon, HeroCol, HeroGrid } from './styles';
import Heading, { Tag } from 'components/UI/Heading';

import coinBadge from 'assets/vectors/wellr-badge.svg';
import heartBadge from 'assets/vectors/heart-badge.svg';
import HeadingWithInfo from 'components/UI/HeadingWithInfo';
import LevelsModal from 'components/modals/LevelsModal';

import useLevelIcon from 'hooks/useLevelIcon';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import useNotifications from 'hooks/useNotifications';

const HealthTabLayout: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'myHealthTitle',
          defaultMessage: 'My Health ',
          description: 'Title for my health page',
        }),
        inverted: true,
      })
    );
  }, [dispatch, intl]);

  const { languageCode } = useActiveLanguage();

  const { healthQuiz, pulseQuiz, activeMeasure, proposedMeasure } =
    useNotifications();

  // Tab menu
  const tabMenu: TabMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'tabHealthOverview',
          defaultMessage: 'Overview',
          description: 'Tab item for health overview',
        }),
        link: routes.MY_HEALTH,
        hasNotification: false,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'tabHealthTests',
          defaultMessage: 'Tests',
          description: 'Tab item for health tests',
        }),
        link: routes.TESTS,
        hasNotification: healthQuiz || pulseQuiz,
      },
      {
        id: 3,
        text: intl.formatMessage({
          id: 'tabHealthMeasures',
          defaultMessage: 'Plans',
          description: 'Tab item for health measures',
        }),
        link: routes.MEASURES,
        hasNotification: activeMeasure || proposedMeasure,
      },
    ];
  }, [activeMeasure, healthQuiz, intl, proposedMeasure, pulseQuiz]);

  // State
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  // On close
  const onClose = useCallback(() => setModalOpen(false), []);
  const onOpen = useCallback(() => setModalOpen(true), []);

  const userLevels = useGetUserLevelsQuery({
    language: languageCode,
  });

  const wheel = useGetMyHealthWheelQuery({ language: languageCode });
  const user = useGetUserQuery();
  const levelIcon = useLevelIcon(user.data?.level ?? null, 96);

  const healthLevel = user.data?.level ?? '-';
  const wellrPoints = user.data?.points ?? '-';
  const healthIndex = wheel.data?.latest.averagePercentage;

  return (
    <>
      <HeroSmall background="blue" illustrationColor="blueDark">
        <HeroGrid>
          <HeroCol>
            {levelIcon && (
              <HealthIcon src={levelIcon.src} alt={levelIcon.alt} />
            )}
            <Heading tag={Tag.H2}>{healthLevel}</Heading>
            <HeadingWithInfo
              tag={Tag.H6}
              onClickInfo={onOpen}
              center
              color="white"
            >
              <FormattedMessage
                id="pageMyHealthLevelTitle"
                defaultMessage="Health level"
                description="My health page health level title"
              />
            </HeadingWithInfo>
          </HeroCol>
          <HeroCol>
            <HealthIcon src={coinBadge} alt="points badge" />
            <Heading tag={Tag.H2}>{wellrPoints}</Heading>
            <Heading tag={Tag.H6}>
              <FormattedMessage
                id="pageMyHealthWellrPointsTitle"
                defaultMessage="Wellr points"
                description="My health page wellr points title"
              />
            </Heading>
          </HeroCol>
          <HeroCol>
            <HealthIcon src={heartBadge} alt="points badge" />
            <Heading tag={Tag.H2}>
              {healthIndex != null ? `${healthIndex} %` : '-'}
            </Heading>
            <Heading tag={Tag.H6}>
              <FormattedMessage
                id="pageMyHealthHealthIndexTitle"
                defaultMessage="Health index"
                description="My health page health index title"
              />
            </Heading>
          </HeroCol>
        </HeroGrid>
      </HeroSmall>
      <ContentWidth isSurface noMargins>
        <TabMenu menuId="my-health" menu={tabMenu} />
        <Gap />
        <Outlet />
      </ContentWidth>
      <LevelsModal
        isOpen={modalOpen}
        onClose={onClose}
        levels={userLevels?.data}
      />
    </>
  );
};

export default HealthTabLayout;
