import { backendApi } from 'config';

export async function getAsset(
  assetId?: string | null,
  token?: string | null
): Promise<string> {
  const res = await fetch(`${backendApi}/assets/${assetId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  if (!res.ok) {
    throw new Error('Failed to fetch asset');
  }
  const assetBlob = await res.blob();
  return URL.createObjectURL(assetBlob);
}

type AssetType = 'ProfilePicture' | 'CompanyLogo' | 'UmbrellaCompanyLogo';

export async function uploadAsset(
  file: File,
  assetType: AssetType,
  token?: string | null
): Promise<void> {
  const formData = new FormData();
  formData.append('pictureFile', file);

  await fetch(`${backendApi}/assets?assetType=${assetType}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    credentials: 'include',
    body: formData,
  });
}

export async function removeProfileImage(token?: string | null): Promise<void> {
  await fetch(`${backendApi}/assets/profile-picture`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'DELETE',
    credentials: 'include',
  });
}

export async function uploadChatAttachment(
  chatRoomId: string,
  file: File,
  token?: string | null
): Promise<void> {
  const formData = new FormData();
  formData.append('attachment', file);

  await fetch(`${backendApi}/chat/entry/${chatRoomId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    credentials: 'include',
    body: formData,
  });
}

export async function getChatAttachment(
  attachmentId: string,
  chatEntryId: string,
  token?: string | null
): Promise<string> {
  const res = await fetch(
    `${backendApi}/chat/attachment/${chatEntryId}/${attachmentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: 'include',
    }
  );

  if (!res.ok) {
    throw new Error('Failed to fetch asset');
  }
  const assetBlob = await res.blob();
  return URL.createObjectURL(assetBlob);
}
