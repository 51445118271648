import {
  Contest,
  ContestAnswerBody,
  ContestAnswerResponse,
  ContestAvailable,
  ContestEditAliasBody,
  ContestHealthChoiceBody,
  ContestHealthGoalBody,
  ContestItem,
  ContestPreview,
  ContestQuery,
  ContestStartBody,
  ContestTeam,
  ContestTeamCreateBody,
  ContestTeamCreateReponse,
  ContestTeamDeleteBody,
  ContestTeamInviteAnswer,
  ContestTeamInviteBody,
  ContestTeamInviteResponse,
  ContestTeamSearchMembersBody,
  ContestTeamSearchMembersResponse,
} from 'models';
import { WellrEndpointDefinition, WellrEndpointPath } from 'utils/api';
import { dateStringToISOString, getTimezoneOffset } from 'utils/date';
import { baseApi } from '../baseApi';

const getContestDarkModePath: WellrEndpointPath =
  '/api/contest/{contestId}/dark-mode';
const getContestDarkModeMethod = 'get';
type GetContestDarkModeEndpoint = WellrEndpointDefinition<
  typeof getContestDarkModePath,
  typeof getContestDarkModeMethod
>;
const updateContestDarkModePath: WellrEndpointPath = getContestDarkModePath;
const updateContestDarkModeMethod = 'patch';
type UpdateContestDarkModeEndpoint = WellrEndpointDefinition<
  typeof updateContestDarkModePath,
  typeof updateContestDarkModeMethod
>;

const contestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveContests: builder.query<ContestItem[], ContestQuery>({
      query: ({ language }) => `/contest/me?language=${language}`,
      providesTags: ['Contest'],
    }),
    getCompanyContests: builder.query<ContestItem[], ContestQuery>({
      query: ({ language }) => `/contest/company?language=${language}`,
      providesTags: ['Contest'],
    }),
    getAvailableContests: builder.query<ContestAvailable[], ContestQuery>({
      query: ({ language }) => `/contest/available?language=${language}`,
      providesTags: ['Contest'],
    }),
    getAvailableContestPreview: builder.query<ContestAvailable, ContestQuery>({
      query: ({ language, slug }) =>
        `/contest/slug/${slug}?language=${language}`,
      providesTags: (_res, _err, query) => [
        { type: 'ContestPreview', id: query.contestId },
      ],
    }),
    getContest: builder.query<Contest, ContestQuery>({
      query: ({ language, contestId }) =>
        `/contest/${contestId}?language=${language}`,
      transformResponse: (response: Contest) => {
        // Add steps from current users pending activites that have yet not been part of aggregated data
        const pendingSteps = response.me.pendingActivitiesStepsToAdd;
        const { individualResults } = response;
        response.me.total += pendingSteps;
        if (individualResults && response.me.team?.users != null) {
          /* Update current user steps in individual toplist with steps from pending activities 
          and sort the toplist with the added steps in mind */
          const myUserIndex = response.me.team.users.findIndex(
            ({ id }) => id === response.me.id
          );
          if (myUserIndex !== -1) {
            response.me.team.users[myUserIndex].total = response.me.total;
            response.me.team.users.sort((a, b) => b.total - a.total);
          }
        }
        if (response.toplist != null) {
          const sortsByCompletedGoals =
            response.users?.some(
              ({ completedGoals }) => completedGoals.length > 0
            ) ?? false;
          const myUserIndex = response.toplist.findIndex(
            ({ id }) => id === response.me.id
          );
          if (myUserIndex !== -1) {
            response.toplist[myUserIndex].total = response.me.total;
            if (individualResults && sortsByCompletedGoals === false) {
              response.toplist.sort((a, b) => b.total - a.total);
            }
          }
        }
        if (response.users != null) {
          // Updates own map marker with steps from pending activities
          const myIndex = response.users.findIndex(
            ({ alias }) => alias === response.me.alias
          );
          if (myIndex !== -1) {
            response.users[myIndex].total = response.me.total;
          }
        }
        if (response.teams != null && response.me.team != null) {
          // Add pending steps to current users team total
          response.me.team.total += pendingSteps;
          for (let i = 0; i < response.teams.length; i++) {
            if (response.teams[i].id === response.me.team.id) {
              response.teams[i].total += pendingSteps;
              break;
            }
          }
        }
        return response;
      },
      providesTags: (_res, _err, query) => [
        { type: 'Contest', id: query.contestId },
      ],
    }),
    getContestPreview: builder.query<ContestPreview, ContestQuery>({
      query: ({ language, contestId }) =>
        `/contest/${contestId}/info?language=${language}`,
      providesTags: (_res, _err, query) => [
        { type: 'ContestPreview', id: query.contestId },
      ],
    }),
    startContest: builder.mutation<Contest, ContestStartBody>({
      query: (body) => ({
        url: '/contest/start',
        method: 'POST',
        body: {
          ...body,
          minutesOffsetFromUtc: getTimezoneOffset(),
          start: dateStringToISOString(body.start),
          stop:
            body.stop != null ? dateStringToISOString(body.stop) : undefined,
        },
      }),
      invalidatesTags: ['Contest'],
    }),
    startChallenge: builder.mutation<
      Contest,
      ContestStartBody & { displayCreatorsEmail: boolean }
    >({
      query: (body) => ({
        url: '/contest/challenge/start',
        method: 'POST',
        body: {
          ...body,
          minutesOffsetFromUtc: getTimezoneOffset(),
          start: dateStringToISOString(body.start),
          stop:
            body.stop != null ? dateStringToISOString(body.stop) : undefined,
        },
      }),
      invalidatesTags: ['Contest'],
    }),
    answerContestInvite: builder.mutation<
      ContestAnswerResponse,
      ContestAnswerBody
    >({
      query: (body) => ({
        url: '/contest/rsvp',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contest', 'Notifications'],
    }),
    getAllContestTeams: builder.query<ContestTeam[], ContestQuery>({
      query: ({ contestId }) => `/contest/${contestId}/teams`,
      providesTags: (_res, _err, query) => [
        { type: 'Contest', id: query.contestId },
      ],
    }),
    completeHealthChoice: builder.mutation<void, ContestHealthChoiceBody>({
      query: (body) => ({
        url: '/contest/health-choice/complete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contest'],
    }),
    completeGoal: builder.mutation<void, ContestHealthGoalBody>({
      query: (body) => ({
        url: '/contest/goal/complete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contest'],
    }),
    createContestTeam: builder.mutation<
      ContestTeamCreateReponse,
      ContestTeamCreateBody
    >({
      query: (body) => ({
        url: '/contest/user/create-team',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contest'],
    }),
    leaveContestTeam: builder.mutation<void, ContestTeamDeleteBody>({
      query: (body) => ({
        url: '/contest/user/leave-team',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Contest'],
    }),
    sendContestTeamInvite: builder.mutation<
      ContestTeamInviteResponse,
      ContestTeamInviteBody
    >({
      query: (body) => ({
        url: '/contest/user/invite-team',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contest'],
    }),
    answerContestTeamInvite: builder.mutation<
      ContestTeamInviteAnswer,
      ContestTeamInviteAnswer
    >({
      query: (body) => ({
        url: '/contest/user/invite-team/rsvp',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contest'],
    }),
    searchInvitableTeamMembers: builder.mutation<
      ContestTeamSearchMembersResponse,
      ContestTeamSearchMembersBody
    >({
      query: ({ contestId, body }) => ({
        url: `/contest/${contestId}/invitable-users`,
        method: 'POST',
        body,
      }),
    }),
    editContestUserAlias: builder.mutation<void, ContestEditAliasBody>({
      query: (body) => ({
        url: '/contest/user/change-alias',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Contest'],
    }),
    deleteContestMe: builder.mutation<void, { contestId: string }>({
      query: ({ contestId }) => ({
        url: `/contest/me/delete/${contestId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contest'],
    }),
    getContestDarkModeContent: builder.query<
      GetContestDarkModeEndpoint['responseBody'],
      GetContestDarkModeEndpoint['request']['params']['path']
    >({
      query: ({ contestId }) => `/contest/${contestId}/dark-mode`,
    }),
    updateContestDarkMode: builder.mutation<
      UpdateContestDarkModeEndpoint['responseBody'],
      UpdateContestDarkModeEndpoint['request']['params']['path'] &
        UpdateContestDarkModeEndpoint['request']['body']
    >({
      query: ({ contestId, start, stop }) => ({
        url: `/contest/${contestId}/dark-mode`,
        method: updateContestDarkModeMethod.toUpperCase(),
        body: {
          start: start != null ? dateStringToISOString(start) : null,
          stop: stop != null ? dateStringToISOString(stop) : null,
        },
      }),
      invalidatesTags: ['Contest'],
    }),
  }),
});

export const {
  useStartContestMutation,
  useStartChallengeMutation,
  useGetActiveContestsQuery,
  useGetCompanyContestsQuery,
  useGetAvailableContestsQuery,
  useGetAvailableContestPreviewQuery,
  useGetContestQuery,
  useGetContestPreviewQuery,
  useAnswerContestInviteMutation,
  useGetAllContestTeamsQuery,
  useCompleteHealthChoiceMutation,
  useCompleteGoalMutation,
  useCreateContestTeamMutation,
  useLeaveContestTeamMutation,
  useSendContestTeamInviteMutation,
  useAnswerContestTeamInviteMutation,
  useSearchInvitableTeamMembersMutation,
  useEditContestUserAliasMutation,
  useDeleteContestMeMutation,
  useGetContestDarkModeContentQuery,
  useUpdateContestDarkModeMutation,
} = contestApi;
