import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Image } from 'models';

import Heading, { Tag } from 'components/UI/Heading';
import ClickableCard from 'components/UI/Cards/ClickableCard/ClickableCard';

import {
  CardContent,
  CardImage,
  CardText,
  ImageView,
  Description,
} from './styles';

type Props = {
  title: string;
  description?: string | null;
  image: Image | null;
  link: string;
};

const ArticleCard: FC<Props> = ({ title, description, link, image }) => {
  const navigate = useNavigate();

  // Limit excerpt to 80 characters
  const excerpt = useMemo(() => {
    if (!description) {
      return null;
    }
    if (description.length > 80) {
      return `${description.substring(0, 80)}...`;
    }
    return description;
  }, [description]);

  // Link
  const onClick = useCallback(() => navigate(link), [navigate, link]);

  return (
    <ClickableCard onClick={onClick}>
      <CardContent>
        <CardImage>
          {image && <ImageView src={image.src} alt={image.alt} />}
        </CardImage>
        <CardText>
          <Heading tag={Tag.H5}>{title}</Heading>
          {excerpt && <Description>{excerpt}</Description>}
        </CardText>
      </CardContent>
    </ClickableCard>
  );
};

export default ArticleCard;
