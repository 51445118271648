import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  outline: none;
  cursor: pointer;

  @media (max-width: ${breakpoints.l}px) {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
`;
