import {
  ContentQuery,
  ContentResponse,
  HealthCategory,
  HealthWheelResponse,
  PulseQuiz,
  PulseQuizItem,
  PulseQuizResult,
  Quiz,
  QuizItem,
  QuizQuery,
  QuizResultData,
} from 'models';
import { getEndpointURL, WellrEndpointDefinition } from 'utils/api';
import { baseApi } from '../baseApi';

const myPersonalHealthPath = '/api/my-health/personal-health';
const myPersonalHealthMethod = 'get';
type MyPersonalHealthEndpoint = WellrEndpointDefinition<
  typeof myPersonalHealthPath,
  typeof myPersonalHealthMethod
>;

const healthApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyHealthWheel: builder.query<HealthWheelResponse, ContentQuery>({
      query: ({ language }) => `/my-health/wheel?language=${language}`,
      providesTags: ['Health'],
    }),
    getHealthCategories: builder.query<HealthCategory[], ContentQuery>({
      query: ({ language }) =>
        `/my-health/health-categories?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getAvailableQuizes: builder.query<QuizItem[], QuizQuery>({
      query: ({ language }) => `/quiz/me?language=${language}`,
      providesTags: ['Quiz'],
    }),
    createQuiz: builder.mutation<Quiz, QuizQuery>({
      query: ({ slug, language }) => ({
        url: `/quiz/me/${slug}?language=${language}`,
        method: 'POST',
      }),
    }),
    getQuiz: builder.query<Quiz, QuizQuery>({
      query: ({ slug, language }) => `/quiz/me/${slug}?language=${language}`,
      providesTags: ['Quiz'],
    }),
    getQuizResults: builder.query<QuizResultData, QuizQuery>({
      query: ({ slug, language, noOfResults = 3 }) =>
        `/quiz/me/results/${slug}/numberOfResults?language=${language}&numberOfResults=${noOfResults}`,
    }),
    answerQuizQuestion: builder.mutation<Quiz, QuizQuery>({
      query: ({ slug, answer, language }) => ({
        url: `/quiz/me/answer/${slug}?language=${language}`,
        method: 'POST',
        body: answer,
      }),
      invalidatesTags: ['Quiz'],
      async onQueryStarted(
        { answer, slug, language },
        { dispatch, queryFulfilled }
      ) {
        if (!answer || !answer?.questionId || answer.questionId.length === 0) {
          return;
        }
        // Optimistic update the cache to avoid laggy checkbox/radio in UI
        const patchResult = dispatch(
          healthApi.util.updateQueryData(
            'getQuiz',
            { slug, language },
            (data) => {
              const question = data.progress.questions.find(
                (q) => q.id === answer.questionId
              );
              if (question != null) {
                question.answered = true;
                if (answer.alternativeIds != null) {
                  question.answers = answer.alternativeIds;
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          // Revert optimistic update if the mutation fails
          patchResult.undo();
        }
      },
    }),
    submitQuiz: builder.mutation<void, QuizQuery>({
      query: ({ slug }) => ({
        url: `/quiz/me/submit/${slug}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [
        { type: 'Health' },
        { type: 'Measure', id: 'PROPOSED_LIST' }, // a submitted quiz may lead to a proposed health plan
        { type: 'Notifications' },
      ],
    }),
    getAvailablePulseQuizes: builder.query<PulseQuizItem[], QuizQuery>({
      query: ({ language }) => `/pulsequiz/me?language=${language}`,
      providesTags: ['PulseQuiz'],
    }),
    getPulseQuiz: builder.query<PulseQuiz, QuizQuery>({
      query: ({ pulseQuizInstanceId, language }) =>
        `/pulsequiz/me/${pulseQuizInstanceId}?language=${language}`,
      providesTags: ['PulseQuiz'],
    }),
    answerPulseQuizQuestion: builder.mutation<PulseQuiz, QuizQuery>({
      query: ({ pulseQuizInstanceId, answer, language }) => ({
        url: `/pulsequiz/me/answer/${pulseQuizInstanceId}?language=${language}`,
        method: 'POST',
        body: answer,
      }),
      invalidatesTags: ['PulseQuiz'],
    }),
    submitPulseQuiz: builder.mutation<PulseQuizResult, QuizQuery>({
      query: ({ pulseQuizInstanceId }) => ({
        url: `/pulsequiz/me/submit/${pulseQuizInstanceId}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Notifications'],
    }),
    getMyPersonalHealth: builder.query<
      MyPersonalHealthEndpoint['responseBody'],
      MyPersonalHealthEndpoint['request']['params']['query']
    >({
      query: () => getEndpointURL(myPersonalHealthPath),
      providesTags: ['Activity'],
    }),
  }),
});

export const {
  useGetMyHealthWheelQuery,
  useGetHealthCategoriesQuery,
  useGetAvailableQuizesQuery,
  useCreateQuizMutation,
  useGetQuizQuery,
  useGetQuizResultsQuery,
  useAnswerQuizQuestionMutation,
  useSubmitQuizMutation,
  useGetAvailablePulseQuizesQuery,
  useGetPulseQuizQuery,
  useAnswerPulseQuizQuestionMutation,
  useSubmitPulseQuizMutation,
  useGetMyPersonalHealthQuery,
} = healthApi;
