import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Background,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryPolarAxis,
} from 'victory';

import { Divider, Gap } from 'styles';
import { CategoryIcon } from 'models';
import { getHealthWheelValue } from 'utils/health';
import { getCategoryIcon } from 'utils/asset';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useGetHealthCategoriesQuery, useGetMyHealthWheelQuery } from 'store';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import * as routes from 'router/routes';

import {
  ChartGrid,
  ChartContainer,
  AssessmentGrid,
  Category,
  CategoryCircle,
  CategoryContainer,
  CategoryTitle,
} from './styles';

type Props = {
  language: string;
};

const HealthWheel: FC<Props> = ({ language }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const getText = useLocalizedText();

  // Hooks
  const wheel = useGetMyHealthWheelQuery({ language });
  const categories = useGetHealthCategoriesQuery({ language });

  // State
  const [active, setActive] = useState<number | null>(null);

  // On set active
  const onSetActive = useCallback(
    (slug: string) => () => {
      if (categories.data) {
        const index = categories.data.findIndex(
          (item) => item.slug.current === slug
        );
        setActive(index);
      }
    },
    [categories]
  );

  // Navigate
  const onNavigate = useCallback(() => navigate(routes.MEASURES), [navigate]);

  // Render category icon
  const renderIcon = useCallback(
    (icon: CategoryIcon, isActive: boolean = false) => {
      const img = getCategoryIcon(icon, isActive);
      return img ? <img src={img.src} alt={img.alt} /> : null;
    },
    []
  );

  // Get data by slug
  const getDataBySlug = useCallback(
    (slug: string) => {
      if (!wheel.data) {
        return {
          result: null,
          measure: null,
        };
      }
      const { latest, proposedMeasures } = wheel.data;
      return {
        result: latest.results.find((item) => item.healthCategorySlug === slug),
        measure: proposedMeasures.find(
          (item) => item.healthCategory.slug.current === slug
        ),
      };
    },
    [wheel]
  );

  // Wheel data
  const wheelData = useMemo(() => {
    if (!categories.data) {
      return [];
    }
    return categories.data.map((cat) => {
      const found = getDataBySlug(cat.slug.current);
      const percentage = found.result?.percentage || 0;
      const minPercentage = 24;
      return {
        y: getHealthWheelValue(percentage, minPercentage),
        x: cat.slug.current,
        label: `${percentage.toFixed()}%`,
        percentage,
        color: found.result?.quizAssessment.color?.hex ?? 'blue',
      };
    });
  }, [getDataBySlug, categories]);

  // Slider category data
  const sliderCategories = useMemo(() => {
    if (!categories.data) {
      return [];
    }
    return categories.data.map(({ slug, icon }, i) => {
      return {
        isActive: active === i,
        slug: slug.current,
        data: getDataBySlug(slug.current) || null,
        icon,
      };
    });
  }, [getDataBySlug, categories, active]);

  // Render active category
  const activeCategory = useMemo(() => {
    if (active === null || !categories.data || !categories.data[active]) {
      return null;
    }

    const { title, icon, slug } = categories.data[active];
    const found = getDataBySlug(slug.current);

    return (
      <Fragment>
        <Category>
          {renderIcon(icon)}
          <CategoryTitle>{getText(title)}</CategoryTitle>
        </Category>
        {found?.result && (
          <AssessmentGrid>
            <h2>
              {getText(found.result.quizAssessment.assessmentReference?.title)}{' '}
              {`(${Math.round(found.result.percentage)}%)`}
            </h2>
            {found.measure && (
              <Button background="blue" onClick={onNavigate}>
                <FormattedMessage
                  id="implementMeasuresButton"
                  defaultMessage="To the health plans"
                  description="Implement measures button text"
                />
              </Button>
            )}
          </AssessmentGrid>
        )}
      </Fragment>
    );
  }, [getDataBySlug, onNavigate, renderIcon, getText, categories, active]);

  // Initally select largest data pie
  useEffect(() => {
    if (!wheelData) {
      return;
    }
    const maxValue = Math.max(...wheelData.map((item) => item.y));
    const index = wheelData.findIndex(
      (item) => maxValue > 10 && maxValue === item.y
    );
    if (index >= 0) {
      setActive(index);
    }
  }, [wheelData]);

  // Loading
  if (categories.isLoading || wheel.isLoading) {
    return <Loader color="blue" padding />;
  }

  return (
    <Fragment>
      <ChartGrid>
        <ChartContainer>
          <VictoryChart
            polar
            width={350}
            height={350}
            padding={48}
            innerRadius={6}
            animate={{ duration: 150, onLoad: { duration: 500 } }}
            domain={{ y: [0, 100] }}
            containerComponent={
              <VictoryContainer
                style={{
                  touchAction: 'auto',
                }}
              />
            }
          >
            <VictoryPolarAxis
              tickFormat={() => ''}
              labelPlacement="vertical"
              circularAxisComponent={
                <Background
                  polar
                  circleComponent={
                    <svg viewBox="0 0 192 192">
                      <circle
                        opacity="0.1"
                        cx="96"
                        cy="96"
                        r="70"
                        fill="#2A6BF4"
                      />
                      <circle
                        opacity="0.1"
                        cx="96"
                        cy="96"
                        r="60"
                        fill="#2A6BF4"
                      />
                      <circle
                        opacity="0.1"
                        cx="96"
                        cy="96"
                        r="50"
                        fill="#2A6BF4"
                      />
                      <circle
                        opacity="0.1"
                        cx="96"
                        cy="96"
                        r="40"
                        fill="#2A6BF4"
                      />
                    </svg>
                  }
                />
              }
              style={{
                axis: {
                  fill: theme.colors.backgroundLight,
                  stroke: theme.colors.backgroundLight,
                },
                tickLabels: {
                  fontSize: 18,
                },
              }}
            />
            <VictoryBar
              style={{
                data: {
                  fill: ({ datum }) => {
                    const data = datum as (typeof wheelData)[number];
                    if (data.percentage === 0) {
                      return theme.colors.backgroundDark;
                    }
                    return data.color;
                  },
                  cursor: 'pointer',
                  stroke: ({ index }) =>
                    index === active ? theme.colors.blueLight : 'none',
                  strokeWidth: 2,
                  borderRadius: 8,
                },
                labels: {
                  display: 'none',
                },
              }}
              data={wheelData}
              cornerRadius={8}
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onClick: () => {
                      return [
                        {
                          target: 'data',
                          mutation: ({ index }) => {
                            if (sliderCategories[index].data.result) {
                              setActive(index);
                            }
                          },
                        },
                      ];
                    },
                  },
                },
              ]}
            />
          </VictoryChart>
          {sliderCategories.reverse().map(({ isActive, icon, data, slug }) => {
            return (
              <CategoryCircle
                key={slug}
                isActive={isActive}
                isDisabled={!data.result}
                onClick={onSetActive(slug)}
              >
                {renderIcon(icon, isActive)}
              </CategoryCircle>
            );
          })}
        </ChartContainer>
        <CategoryContainer>{activeCategory}</CategoryContainer>
      </ChartGrid>
      <Divider />
      <Gap />
    </Fragment>
  );
};

export default HealthWheel;
