import { FC, useCallback, useMemo } from 'react';

import logo from 'assets/vectors/logo-icon-white.svg';

import { Container, Coin, Text } from './styles';

type Props = {
  count?: number;
  text?: string;
  inverted?: boolean;
  right?: boolean;
};

const WellrCoins: FC<Props> = ({
  count,
  text,
  right = false,
  inverted = false,
}) => {
  // Memoize points
  const points = useMemo(() => {
    if (count) {
      return `+${count} WP`;
    }
    if (text) {
      return text;
    }
    return null;
  }, [count, text]);

  const renderCoin = useCallback((shouldRender: boolean) => {
    if (!shouldRender) {
      return null;
    }
    return (
      <Coin>
        <img src={logo} alt="Wellr points" />
      </Coin>
    );
  }, []);

  return (
    <Container>
      {renderCoin(right)}
      <Text inverted={inverted}>{points}</Text>
      {renderCoin(!right)}
    </Container>
  );
};

export default WellrCoins;
