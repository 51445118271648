import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 20px;

  h3 {
    margin: 0;
  }
`;

export const Body = styled.div`
  height: calc(100vh - 72px);
  overflow-y: auto;
  padding: 0 20px;
`;

export const Content = styled.div`
  max-width: 800px;
  padding: 32px 0 96px;
  margin: 0 auto;
`;
