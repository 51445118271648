import styled from 'styled-components';

export const CategoryHeader = styled.div`
  h1 {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const PreparationTime = styled.p`
  font-size: 12px;
  margin-top: 8px;
`;
