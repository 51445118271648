import { createContext, useContext, useState } from 'react';

export const SelectedMembershipsContext = createContext<{
  selected: string[];
  onToggle: (membershipId: string) => void;
}>({
  selected: [],
  onToggle: () => {},
});

export const useMembershipsContext = () =>
  useContext(SelectedMembershipsContext);

export const useMembershipState = () => {
  const [selectedMemberships, setSelectedMemberships] = useState<string[]>([]);
  const toggleSelectMembership = (membershipId: string) => {
    if (selectedMemberships.includes(membershipId)) {
      setSelectedMemberships(
        selectedMemberships.filter((id) => id !== membershipId)
      );
    } else {
      setSelectedMemberships([...selectedMemberships, membershipId]);
    }
  };
  return { selectedMemberships, toggleSelectMembership };
};
