import { baseApi } from '../baseApi';
import {
  GetNotificationsDto,
  NewNotificationsCountDto, Notification, NotificationsDto,
  RemovePushNotificationDeviceDto, SetNotificationDeviceIsActiveDto, SetNotificationIsViewedDto,
  UpdateSourcePermissionDto
} from '../../models/notification/notification';
import { NotificationPermission } from '../../models/notification/notification';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const notificationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationPermission: builder.query<NotificationPermission, void>({
      query: () => ({
        url: `/notification/permission`,
        method: 'GET',
      })
    }),
    removeNotificationDevice: builder.mutation<void, RemovePushNotificationDeviceDto>({
      query: (device) => ({
        url: '/notification/device/remove',
        method: 'POST',
        body: device
      }),
    }),
    setNotificationDeviceIsActive: builder.mutation<Notification | null, SetNotificationDeviceIsActiveDto>({
      query: (dto) => ({
        url: '/notification/device/set-active',
        method: 'POST',
        body: dto
      }),
    }),
    updateSourcePermission: builder.mutation<void, UpdateSourcePermissionDto>({
      query: (dto) => ({
        url: '/notification/source',
        method: 'POST',
        body: dto
      }),
    }),
    setNotificationIsViewed: builder.mutation<Notification | null, SetNotificationIsViewedDto>({
      query: (dto) => ({
        url: '/notification/view',
        method: 'POST',
        body: dto
      }),
    }),
    getNotifications: builder.query<NotificationsDto, GetNotificationsDto>({
      query: (dto) => ({
        url: `/notification/me`,
        method: 'GET',
        params: dto
      })
    }),
    getNewNotificationsCount: builder.query<NewNotificationsCountDto, void>({
      query: () => ({
        url: '/notification/new',
        method: 'GET'
      })
    })
  }),
});

export const {
  useGetNotificationPermissionQuery,
  useRemoveNotificationDeviceMutation,
  useUpdateSourcePermissionMutation,
  useGetNotificationsQuery,
  useGetNewNotificationsCountQuery,
  useSetNotificationIsViewedMutation,
  useSetNotificationDeviceIsActiveMutation
} = notificationApi;

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {notificationUpdateTime: 0},
  reducers: {
    triggerNotificationUpdate: (state, action: PayloadAction<any>) => {
      state.notificationUpdateTime = Date.now()
    }
  }
})

export const {
  triggerNotificationUpdate
} = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer

export const notificationSelector = (state: RootState) => state.notification
