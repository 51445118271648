import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Dot = styled.div`
  background: ${({ theme }) => theme.colors.blue};
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;

type Props = {
  isChecked: boolean;
  isError: boolean;
};

export const StyledRadioButton = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ isChecked, isError, theme }) => {
      const { blue, error, borderDark } = theme.colors;
      if (isChecked) {
        return blue;
      }
      if (isError) {
        return error;
      }
      return borderDark;
    }};
  border-radius: 50%;
  transition: all 150ms;
  cursor: pointer;

  ${Dot} {
    visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
  }
`;
