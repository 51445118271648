import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  localizationSelector,
  setLayoutHeader,
  useGetExerciseCategoryQuery,
  useGetExerciseTagsQuery,
} from 'store';

import { filterArrayByTags } from 'utils/array';
import { Category, ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getImage } from 'utils/asset';
import * as routes from 'router/routes';

import Loader from 'components/UI/Loader';
import Heading from 'components/UI/Heading';
import CategoryCard from 'components/UI/Cards/CategoryCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import { CardList, CategoryHeader } from './styles';
import { useTagFilters } from 'hooks/useTagFilters';

const ExerciseCategory: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'tabExercises',
            defaultMessage: 'Exercises',
            description: 'Tab item for exercises',
          }),
          icon: IconType.Back,
          link: routes.EXERCISES,
          favorite: {
            reference: slug,
            type: 'exerciseCategory',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);

  // Hooks
  const { data, isLoading } = useGetExerciseCategoryQuery({
    slug,
    language: language?.languageCode,
  });
  const tags = useGetExerciseTagsQuery({ language: language?.languageCode });
  const [filters, filterModal] = useTagFilters('exercise', tags.data);

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Exercise} padding>
        <FormattedMessage
          id="pageExercisesCategoryEmptyState"
          defaultMessage="Exercise category not found"
          description="Empty state for exercise category"
        />
      </EmptyState>
    );
  }

  const {
    exerciseCategory: [category],
    exercises,
  } = data;

  return (
    <ContentWidth isSurface>
      <CategoryHeader>
        <Category>
          <FormattedMessage
            id="pageExercisesCategoryTitle"
            defaultMessage="Exercise"
            description="Page title for exercises category"
          />
        </Category>
        <Heading>{getText(category.title)}</Heading>
      </CategoryHeader>
      {filterModal}
      <CardList>
        {exercises
          .filter((item) => filterArrayByTags(filters, item.tags))
          .map(({ _id, title, image, slug }) => {
            return (
              <CategoryCard
                key={_id}
                title={getText(title)}
                link={`${routes.EXERCISES}/${slug.current}`}
                image={getImage(image)}
                filter
              />
            );
          })}
      </CardList>
    </ContentWidth>
  );
};

export default ExerciseCategory;
