import { FC, ReactNode } from 'react';

import { Card } from './styles';

type Props = {
  children: ReactNode;
  padding?: boolean;
  narrow?: boolean;
};

const StaticCard: FC<Props> = ({ children, padding, narrow = false }) => {
  return (
    <Card padding={padding} narrow={narrow}>
      {children}
    </Card>
  );
};

export default StaticCard;
