import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useToggleMfaMutation } from 'store';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import Disable2FAForm from 'components/forms/Disable2FAForm';

import { Header, Body } from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Disabled2FAModal: FC<Props> = ({ isOpen, onClose }) => {
  // Hooks
  const [toggleMfa, result] = useToggleMfaMutation();

  // Success
  useEffect(() => {
    if (result.isSuccess) {
      onClose();
    }
  }, [result, onClose]);

  // Submit
  const onSubmit = useCallback(
    (code: string) => toggleMfa({ code }),
    [toggleMfa]
  );

  // Content
  const content = useMemo(() => {
    if (result.isLoading) {
      return <Loader padding color="blue" />;
    }

    return <Disable2FAForm submitForm={onSubmit} />;
  }, [onSubmit, result]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="disableTwoFactorAuthenticationModalTitle"
              defaultMessage="Disable Two-Factor authentication"
              description="Disable two-factor authentication modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default Disabled2FAModal;
