import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints, ThemeColor } from 'styles';

export const Wrapper = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const Background = styled(motion.div)`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 98;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  cursor: pointer;
`;

export const Container = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 32px 32px 0 0;
  position: fixed;
  z-index: 99;
  width: 100%;
  min-height: 100px;
  bottom: -100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 16px;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  h3 {
    margin: 0;
  }
`;

export const Menu = styled.ul`
  list-style: none;
  margin: 0 0 32px;
  padding: 0;
`;

type ListItemProps = {
  color?: ThemeColor;
};

export const MenuListItem = styled.li<ListItemProps>`
  padding: 12px 16px;
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`;
