import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Gap } from 'styles';
import {
  localizationSelector,
  useCreateQuizMutation,
  useGetAvailablePulseQuizesQuery,
  useGetAvailableQuizesQuery,
} from 'store';
import { useAppSelector } from 'hooks/redux';
import * as routes from 'router/routes';

import ListMenu from 'components/UI/ListMenu';
import Heading, { Tag } from 'components/UI/Heading';
import HealthCard from 'components/cards/TestCard';
import PulseCard from 'components/cards/PulseCard';
import Loader from 'components/UI/Loader';

import { CardList } from './styles';

const Tests: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);
  const pulseQuizes = useGetAvailablePulseQuizesQuery({
    language: language?.languageCode,
  });
  const quizes = useGetAvailableQuizesQuery({
    language: language?.languageCode,
  });
  const [createQiuz, createResult] = useCreateQuizMutation();

  // Actions
  const onQuizStartClick = useCallback(
    (slug: string) => async () => {
      await createQiuz({ language: language?.languageCode, slug });
      navigate(`${routes.QUIZ}/${slug}`);
    },
    [navigate, createQiuz, language]
  );
  const onQuizActiveClick = useCallback(
    () => navigate(routes.MEASURES),
    [navigate]
  );
  const onPulseQuizClick = useCallback(
    (id: string) => () => navigate(`${routes.PULSE_QUIZ}/${id}`),
    [navigate]
  );

  // Pulse quizes
  const pulseQuizContent = useMemo(() => {
    // No data
    if (!pulseQuizes.data?.length) {
      return null;
    }

    return (
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageTestsPulseTestsTitle"
            defaultMessage="Pulse tests"
            description="Section title for pulse tests"
          />
        </Heading>
        <CardList>
          {pulseQuizes.data.map((item) => (
            <PulseCard
              key={item.id}
              title={item.quizDefinition.title}
              summary={item.quizDefinition.summary}
              wellrPoints={item.quizDefinition.wellrPoints}
              date={item.endTime}
              onClick={onPulseQuizClick(item.id)}
              logoAssetId={item.companyLogoAssetId}
              buttonText={intl.formatMessage({
                id: 'takeTestButton',
                defaultMessage: 'Take test',
                description: 'Take test button text',
              })}
            />
          ))}
        </CardList>
      </Fragment>
    );
  }, [onPulseQuizClick, intl, pulseQuizes]);

  // Health quizes
  const healthQuizContent = useMemo(() => {
    // Loading
    if (quizes.isLoading || createResult.isLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!quizes.data) {
      return null;
    }

    return quizes.data.map((item) => (
      <HealthCard
        key={item.quizSlug}
        category={item.quizDefinition.healthCategory}
        text={item.quizDefinition.summary}
        wellrPoints={item.quizDefinition.wellrPoints}
        results={item.quizShouldBeTaken ? [] : item.quizResults}
        onClick={onQuizStartClick(item.quizSlug)}
        onActiveClick={onQuizActiveClick}
        buttonText={intl.formatMessage({
          id: 'takeTestButton',
          defaultMessage: 'Take test',
          description: 'Take test button text',
        })}
        buttonActiveText={intl.formatMessage({
          id: 'implementMeasuresButton',
          defaultMessage: 'To the health plans',
          description: 'Implement measures button text',
        })}
      />
    ));
  }, [
    onQuizStartClick,
    onQuizActiveClick,
    intl,
    quizes,
    createResult.isLoading,
  ]);

  return (
    <Fragment>
      <ListMenu
        menu={[
          {
            id: 1,
            text: intl.formatMessage({
              id: 'menuTestHistory',
              defaultMessage: 'Test History',
              description: 'Menu item for test history',
            }),
            link: routes.TEST_HISTORY,
          },
          {
            id: 2,
            text: intl.formatMessage({
              id: 'menuWellrAnonymous',
              defaultMessage: 'Is Wellr anonymous?',
              description: 'Menu item for is Wellr anonymous',
            }),
            link: `${routes.INSPIRATION_ARTICLE}/is-wellr-anonymous`,
          },
        ]}
      />
      <Gap />
      {pulseQuizContent}
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageTestsHealthTestsTitle"
          defaultMessage="Health tests"
          description="Section title for health tests"
        />
      </Heading>
      <CardList>{healthQuizContent}</CardList>
    </Fragment>
  );
};

export default Tests;
