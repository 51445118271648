import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DisabledTitle = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 12px;
`;

export const Container = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 72px;
  padding: 8px 0 8px 16px;

  h5 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Byline = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0 0;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px;
`;

type ImageProps = {
  filter?: number;
};

export const ImageContainer = styled.div<ImageProps>`
  position: relative;
  width: 72px;
  height: 72px;
  min-width: 72px;

  ${({ theme, filter }) => {
    if (filter) {
      return `
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          mix-blend-mode: multiply;
          background: ${theme.colors.black};
          opacity: 0.4;
        }
      `;
    }
  }};
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CheckmarkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  position: relative;
  bottom: 1px;
  left: 6px;
`;

export const Wrapper = styled.div`
  background-color: rgb(253 77 139 / 50%);
  padding: 0px;
  border: 1px solid rgb(253 77 139 / 50%);
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgb(253 77 139 / 50%);
`;
