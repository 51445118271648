import { FC, Fragment, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import {
  localizationSelector,
  setLayoutHeader,
  useGetCompanyCommunicationQuery,
  useGetLatestArticlesQuery,
  useGetStartPageQuery,
  useGetUserGreetingQuery,
  useGetUserQuery,
} from 'store';
import { ContentWidth, Gap } from 'styles';
import { getImage } from 'utils/asset';
import { getRefLink } from 'utils/link';

import ImageCard from 'components/UI/Cards/ImageCard';
import DragSlider from 'components/UI/DragSlider';
import Heading, { Tag } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import HeroLarge from 'components/UI/Heros/HeroLarge';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import CompanyCommunication from 'components/fragments/CompanyCommunication';
import { HeroSliderWrap } from './styles';
import ActivitiesWeekly from 'components/fragments/ActivitiesWeekly';

const Overview: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const user = useGetUserQuery();
  const greeting = useGetUserGreetingQuery({
    language: language?.languageCode,
  });
  const startPage = useGetStartPageQuery({ language: language?.languageCode });
  const latestArticles = useGetLatestArticlesQuery({
    limit: 6,
    language: language?.languageCode,
  });
  const { data: ccData } = useGetCompanyCommunicationQuery({
    language: language?.languageCode,
  });

  // Set header
  useEffect(() => {
    if (user.data) {
      const { firstName } = user.data;
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage(
            {
              id: 'pageOverviewGreetings',
              defaultMessage: 'Hello {firstName}!',
              description: 'Overview page greetings text',
            },
            { firstName }
          ),
          inverted: true,
        })
      );
    }
  }, [dispatch, intl, user]);

  // Navigate
  const onNavigate = useCallback(
    (link: string) => () => navigate(link),
    [navigate]
  );

  // Loading
  if (startPage.isLoading || latestArticles.isLoading || greeting.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!startPage.data || !latestArticles.data || !greeting.data) {
    return (
      <EmptyState iconType={IconType.Home} padding>
        <FormattedMessage
          id="pageOverviewEmptyState"
          defaultMessage="Home data not found"
          description="Empty state for overview page"
        />
      </EmptyState>
    );
  }

  const [{ startPageGroups }] = startPage.data;

  const umbrellaCompanyCommunicationContent =
    ccData?.umbrellaCompany?.companyCommunicationContent.filter(
      ({ displayToUser }) => displayToUser
    ) ?? [];

  // Show inspiration slider if no company communication content
  const noCompanyCommunicationContent =
    ccData?.company?.companyCommunicationContent?.length === 0 &&
    umbrellaCompanyCommunicationContent.length === 0;

  return (
    <Fragment>
      <HeroSliderWrap>
        <DragSlider id="hero-slider" slidesPerView={1} isHero>
          {greeting.data.map((item, index) => (
            <HeroLarge
              key={index}
              onClick={onNavigate(
                getRefLink(item.referenceType, item.reference)
              )}
              buttonText={getText(item.buttonText)}
              title={getText(item.title)}
              text={getText(item.contentString)}
              image={getImage(item.image)}
            />
          ))}
        </DragSlider>
      </HeroSliderWrap>
      <ContentWidth isSurface>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageMyHealthActivityTitle"
            defaultMessage="Activity"
            description="Section title for activity"
          />
        </Heading>
        <ActivitiesWeekly showLatest />
        <Gap />
        {startPageGroups.map(({ active, title, contentReference }, i) => {
          if (!active) {
            return null;
          }
          return (
            <Fragment key={i}>
              <Heading tag={Tag.H4}>{getText(title)}</Heading>
              <DragSlider id={`slider-${i}`}>
                {contentReference.map((ref, j) => {
                  const { category, image, title, _type, slug } = ref;
                  return (
                    <ImageCard
                      key={`ref-${j}`}
                      category={getText(category.title)}
                      image={getImage(image)}
                      title={getText(title)}
                      onClick={onNavigate(getRefLink(_type, slug.current))}
                      size="small"
                    />
                  );
                })}
              </DragSlider>
              <Gap />
            </Fragment>
          );
        })}
        {noCompanyCommunicationContent ? (
          <Fragment>
            <Heading tag={Tag.H4}>
              <FormattedMessage
                id="pageOverviewInspirationTitle"
                defaultMessage="Inspiration"
                description="Title for inspiration section on overview page"
              />
            </Heading>
            <DragSlider id="inspiration" slidesPerView={2}>
              {latestArticles.data.map((item) => (
                <ImageCard
                  key={item._id}
                  category={getText(item.category.title)}
                  title={getText(item.title)}
                  image={getImage(item.image)}
                  onClick={onNavigate(getRefLink('article', item.slug))}
                  size="large"
                />
              ))}
            </DragSlider>
            <Gap />
          </Fragment>
        ) : (
          <CompanyCommunication />
        )}
      </ContentWidth>
    </Fragment>
  );
};

export default Overview;
