import React, { FC } from 'react';
import { ThemeColor } from 'styles';
import { Spinner, LoaderSize, LoaderGrid } from './styles';

type Props = {
  size?: LoaderSize;
  color?: ThemeColor;
  padding?: boolean;
};

const Loader: FC<Props> = ({ size = 48, color = 'white', padding = false }) => {
  return (
    <LoaderGrid padding={padding}>
      <Spinner size={size} color={color} />
    </LoaderGrid>
  );
};

export default Loader;
