import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    margin: 0;
  }
`;

export const Barcode = styled.img`
  margin: 16px 0;
`;
