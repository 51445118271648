import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { TabMenuItem } from 'models';
import { Category, ContentWidth, Gap } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import {
  localizationSelector,
  setLayoutHeader,
  useGetRecipeQuery,
} from 'store';
import * as routes from 'router/routes';
import { getImage } from 'utils/asset';

import TabMenu from 'components/UI/TabMenu';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Heading from 'components/UI/Heading';

import { CategoryHeader, PreparationTime } from './styles';

const DietRecipeTabs: FC = () => {
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const intl = useIntl();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Tab menu
  const tabMenu: TabMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'tabRecipeIngredients',
          defaultMessage: 'Ingredients',
          description: 'Tab item for recipe ingredients',
        }),
        link: `${routes.RECIPE}/${slug}`,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'tabRecipeGuide',
          defaultMessage: 'Guide',
          description: 'Tab item for recipe guide',
        }),
        link: `${routes.RECIPE}/${slug}/guide`,
      },
    ];
  }, [intl, slug]);

  // Hooks
  const { data, isLoading } = useGetRecipeQuery({
    slug,
    language: language?.languageCode,
  });

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'backButton',
            defaultMessage: 'Back',
            description: 'Back button text',
          }),
          inverted: true,
          icon: IconType.Back,
          favorite: {
            reference: slug,
            type: 'recipe',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data?.length) {
    return (
      <EmptyState iconType={IconType.Food} padding>
        <FormattedMessage
          id="pageRecipeEmptyState"
          defaultMessage="Recipe not found"
          description="Empty state for recipe"
        />
      </EmptyState>
    );
  }

  const [recipe] = data;
  const { title, image, preparationTime } = recipe;

  return (
    <Fragment>
      <HeroSmall image={getImage(image)}>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageRecipeTitle"
              defaultMessage="Recipe"
              description="Page title for recipe"
            />
          </Category>
          <Heading>{getText(title)}</Heading>
          <PreparationTime>
            <FormattedMessage
              id="pageRecipePreparationTime"
              defaultMessage="Preparation time: {value} min"
              description="Page recipe preparation time in minutes"
              values={{ value: preparationTime }}
            />
          </PreparationTime>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        <TabMenu menuId="diet-recipe" menu={tabMenu} />
        <Gap />
        <Outlet context={{ data: recipe, language }} />
      </ContentWidth>
    </Fragment>
  );
};

export default DietRecipeTabs;
