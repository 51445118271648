import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetAssetImage } from 'hooks/useGetAssetImage';

import Heading, { Tag } from 'components/UI/Heading';
import ProgressBar from 'components/UI/ProgressBar';

import {
  Item,
  ItemText,
  ItemGrid,
  ItemProgress,
  ItemHeader,
  ItemHeaderCol,
  ImgContainer,
  Img,
} from './styles';
import { ThemeColor } from 'styles';
import Icon, { IconType } from 'components/UI/Icon';

type Props = {
  alias: string;
  email: string | null;
  profilePictureAssetId: string | null;
  showResults?: boolean;
  totalSteps: number;
  progress: number | null;
  progressColor?: ThemeColor;
  totalText?: string;
  isLast: boolean;
};

const TopListProgressItem: FC<Props> = ({
  alias,
  email,
  profilePictureAssetId,
  showResults = true,
  totalSteps,
  progress,
  progressColor = 'blue',
  totalText = null,
  isLast,
}) => {
  // Hooks
  const [imageSrc] = useGetAssetImage(profilePictureAssetId);
  const progressRounded =
    progress == null
      ? null
      : progress > 99
        ? Math.floor(progress)
        : Math.round(progress);

  return (
    <Item isLast={isLast}>
      <ItemHeader>
        <ItemHeaderCol>
          <ImgContainer>
            {imageSrc && (
              <Img src={imageSrc} alt={alias} />
            )}
            <Icon type={IconType.User} color="grayText" size={20} />
          </ImgContainer>
          <Heading tag={Tag.H4}>
            {alias}
            {email != null ? ` - ${email}` : ''}
          </Heading>
        </ItemHeaderCol>
        {showResults && (
          <ItemText>
            {totalText ? (
              totalText
            ) : (
              <>
                {totalSteps}{' '}
                <FormattedMessage
                  id="activitiesUnitText"
                  defaultMessage="steps"
                  description="Unit text for activities"
                />
              </>
            )}
          </ItemText>
        )}
      </ItemHeader>
      {showResults && progressRounded != null && (
        <ItemGrid>
          <ItemText>{`${progressRounded}%`}</ItemText>
          <ItemProgress>
            <ProgressBar progress={progressRounded} color={progressColor} />
          </ItemProgress>
        </ItemGrid>
      )}
    </Item>
  );
};

export default TopListProgressItem;
