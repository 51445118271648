import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FormEvent,
  ChangeEvent,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';

import Icon, { IconType } from 'components/UI/Icon';
import DropdownMenu from 'components/UI/DropdownMenu';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import EmptyState from 'components/UI/EmptyState';
import Loader from 'components/UI/Loader';

import { uploadChatAttachment } from 'services';
import { groupArrayByProperty } from 'utils/array';
import { formatReadableDateTime, getStartOfDay } from 'utils/date';
import { useAppSelector } from 'hooks/redux';
import { authSelector } from 'store';
import { ChatMessage, ChatRoom } from 'models';
import * as config from 'config';

import ChatMessageItem from './ChatMessageItem';
import {
  ChatButton,
  MessageGrid,
  MessageInput,
  ChatBody,
  ChatHeader,
  ChatInputGrid,
  MessageDate,
  ChatContainer,
  IconContainer,
  DropdownContent,
  DropdownWrapper,
  Input,
} from './styles';

type Props = {
  rooms: ChatRoom[];
};

const ChatModal: FC<Props> = ({ rooms }) => {
  const { accessToken } = useAppSelector(authSelector);

  // Refs
  const bottomRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeChatRoom, setActiveChatRoom] = useState<ChatRoom | null>(
    rooms.length ? rooms[0] : null
  );
  const [message, setMessage] = useState<string>('');
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  // Disabled
  const isDisabled = useMemo(() => message.length === 0, [message]);

  // Init
  const ws = useMemo(() => {
    if (!isOpen || !activeChatRoom) {
      return null;
    }
    return new WebSocket(
      `${config.webSocketUrl}/${JSON.stringify({
        messageCategory: 'chat',
        argument: activeChatRoom.chatId,
        accessToken,
      })}`
    );
  }, [isOpen, activeChatRoom, accessToken]);

  // Listen
  useEffect(() => {
    if (!ws) {
      return;
    }
    ws.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      switch (data.messageAction) {
        case 'CreateEntry':
          setMessages((prev) => [...prev, data.chatEntryDto]);
          break;
        case 'DeleteEntry':
          setMessages((prev) =>
            prev.filter((item) => item.id !== data.chatEntryId)
          );
          break;
        default:
          if (Array.isArray(data)) {
            setMessages(data);
            setIsLoading(false);
          }
      }
    };
    ws.onclose = () => setIsLoading(true);
    return () => ws.close();
  }, [ws]);

  // Scroll to bottom
  useEffect(() => {
    if (messages.length) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Open / close
  const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  // Handle change
  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value),
    []
  );

  // Handle input
  const onInput = useCallback((e: FormEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = '4px';
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  }, []);

  // Send message
  const onSend = useCallback(() => {
    ws?.send(
      JSON.stringify({
        messageCategory: 'chat',
        messageAction: 'createEntry',
        payload: { message },
      })
    );
    setMessage('');
  }, [ws, message]);

  // Upload attachment
  const onFileChangeCapture = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files && activeChatRoom) {
        await uploadChatAttachment(
          activeChatRoom.chatId,
          files[0],
          accessToken
        );
      }
    },
    [activeChatRoom, accessToken]
  );

  // On upload attachment
  const onUpload = useCallback(() => inputFileRef.current?.click(), []);

  // Remove message
  const onRemove = useCallback(
    (chatEntryId: string) => {
      ws?.send(
        JSON.stringify({
          messageCategory: 'chat',
          messageAction: 'deleteEntry',
          payload: { chatEntryId },
        })
      );
    },
    [ws]
  );

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader color="blue" padding />;
    }
    if (!messages.length) {
      return (
        <EmptyState iconType={IconType.Chat} padding>
          <FormattedMessage
            id="chatModalEmptyState"
            defaultMessage="No messages found"
            description="Empty state for chat modal"
          />
        </EmptyState>
      );
    }
    return Object.entries(
      groupArrayByProperty(
        messages.map((item) => ({
          ...item,
          day: getStartOfDay(item.created),
        })),
        'day'
      )
    ).map(([key, values]) => (
      <div key={key}>
        {values.length && (
          <MessageDate>
            {formatReadableDateTime(new Date(values[0].created))}
          </MessageDate>
        )}
        {values.map((item) => (
          <ChatMessageItem key={item.id} message={item} onRemove={onRemove} />
        ))}
      </div>
    ));
  }, [onRemove, isLoading, messages]);

  return (
    <Fragment>
      <ChatButton onClick={onToggle}>
        <Icon type={IconType.Chat} size={32} color="white" />
      </ChatButton>
      <SlideoutModal isOpen={isOpen} onClose={onToggle}>
        <ChatContainer>
          <ChatHeader>
            <Heading tag={Tag.H3}>
              <FormattedMessage
                id="chatModalTitle"
                defaultMessage="Chat"
                description="Title for chat modal"
              />
            </Heading>
            <IconButton onClick={onToggle} padding>
              <Icon type={IconType.Close} />
            </IconButton>
          </ChatHeader>
          <ChatBody>
            <MessageGrid>
              {content}
              <div ref={bottomRef} />
            </MessageGrid>
          </ChatBody>
          <ChatInputGrid>
            {activeChatRoom && rooms.length > 1 ? (
              <DropdownWrapper>
                <DropdownMenu
                  menu={rooms.map((item, i) => ({
                    id: i + 1,
                    text: item.title,
                    onClick: () => setActiveChatRoom(item),
                  }))}
                  direction="up"
                  align="left"
                >
                  <DropdownContent>
                    <p>{activeChatRoom.title}</p>
                    <IconContainer>
                      <Icon type={IconType.Arrow} color="black" />
                    </IconContainer>
                  </DropdownContent>
                </DropdownMenu>
              </DropdownWrapper>
            ) : null}
            <IconButton onClick={onUpload}>
              <Icon type={IconType.Upload} />
            </IconButton>
            <Input
              type="file"
              ref={inputFileRef}
              onChangeCapture={onFileChangeCapture}
            />
            <MessageInput
              placeholder="Aa"
              onInput={onInput}
              onChange={onChange}
              value={message}
            />
            <IconButton onClick={onSend} disabled={isDisabled}>
              <Icon
                type={IconType.Send}
                color={isDisabled ? 'grayText' : 'blue'}
              />
            </IconButton>
          </ChatInputGrid>
        </ChatContainer>
      </SlideoutModal>
    </Fragment>
  );
};

export default ChatModal;
