import { FC, MouseEvent, useCallback } from 'react';

import { Category } from 'styles';

import Heading, { Tag } from 'components/UI/Heading';
import ClickableCard from 'components/UI/Cards/ClickableCard';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';

import { Grid, Column } from './styles';

type Props = {
  category: string;
  title: string;
  icon: IconType;
  onClick?: () => void;
  onIconClick?: () => void;
};

const IconCard: FC<Props> = ({
  category,
  title,
  icon,
  onClick,
  onIconClick,
}) => {
  // Handle icon click
  const onIconButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onIconClick?.();
    },
    [onIconClick]
  );

  return (
    <ClickableCard onClick={onClick}>
      <Grid>
        <Column>
          <Category>{category}</Category>
          <Heading tag={Tag.H3}>{title}</Heading>
        </Column>
        <Column>
          <IconButton onClick={onIconButtonClick}>
            <Icon type={icon} color="blue" />
          </IconButton>
        </Column>
      </Grid>
    </ClickableCard>
  );
};

export default IconCard;
