import React, { FC, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
  localizationSelector,
  setLayoutHeader,
  useGetArticleQuery,
} from 'store';

import { Category, ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getImage } from 'utils/asset';

import RichText from 'components/fragments/RichText';
import Heading from 'components/UI/Heading';
import HeroImage from 'components/UI/Heros/HeroImage';
import Loader from 'components/UI/Loader';
import { IconType } from 'components/UI/Icon';

import { CategoryHeader } from './styles';

const InspirationArticle: FC = () => {
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const intl = useIntl();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data, isLoading } = useGetArticleQuery({
    slug,
    language: language?.languageCode,
  });

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'backButton',
            defaultMessage: 'Back',
            description: 'Back button text',
          }),
          inverted: true,
          icon: IconType.Back,
          favorite: {
            reference: slug,
            type: 'article',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return null;
  }

  const { title, content, image, category } = data;

  return (
    <Fragment>
      <HeroImage image={getImage(image)} />
      <ContentWidth isSurface>
        <CategoryHeader>
          {category?.title !== null && (
            <Category>{getText(category.title)}</Category>
          )}
          <Heading>{getText(title)}</Heading>
        </CategoryHeader>
        {content && <RichText>{content}</RichText>}
      </ContentWidth>
    </Fragment>
  );
};

export default InspirationArticle;
