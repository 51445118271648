import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth, FlexContainer } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { localizationSelector, setLayoutHeader, useGetUserQuery } from 'store';

import { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';
import Heading from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import {
  triggerNotificationUpdate,
  useGetNotificationsQuery,
  useSetNotificationIsViewedMutation
} from '../../store/notificationService/notificationService';
import StaticCard from '../../components/UI/Cards/StaticCard';
import { Container } from '../../components/Layout/styles';
import ContentModal from '../../components/modals/ContentModal';
import { GetNotificationsDto, Notification } from '../../models/notification/notification';
import NotificationFragment from '../../components/fragments/Notification';
import NotificationCard from '../../components/UI/Cards/NotificationCard';
import PaginationControlCard from '../../components/UI/Cards/PaginationControlCard';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Button from '../../components/UI/Button';

const Notifications: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  // Hooks
  const user = useGetUserQuery();
  const { slug } = useParams();
  const [setNotificationIsViewed] = useSetNotificationIsViewedMutation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<Notification | null>(null);
  const { language } = useAppSelector(localizationSelector);
  const [notificationPages, setNotificationPages] = useState<GetNotificationsDto>({ language: language?.languageCode });
  const { isLoading, data, refetch } = useGetNotificationsQuery(notificationPages);

  const { membership } = user.data!;

  useEffect(() => {
    if (slug
      && data !== undefined
      && data.notifications.length > 0) {
      const notification = data.notifications.find((e: Notification) => e.id === slug);
      if (notification !== undefined) {
        setModalData(notification);
        setModalOpen(true);
        return;
      }
    }
    setModalOpen(false);
    setModalData(null);
  }, [slug, data]);

  useEffect(() => {
    refetch();
  }, [notificationPages]);

  useEffect(() => {
    setNotificationPages({
      language: language?.languageCode,
      page: data?.currentPage ?? notificationPages.page,
      pageSize: data?.pageSize ?? notificationPages.pageSize
    });
  }, [language]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageNotificationsTitle',
          defaultMessage: 'Notifications',
          description: 'Page title for notifications'
        }),
        icon: IconType.Back
      })
    );
  }, [dispatch, intl]);

  const modalContent = useMemo(() => {
    if (isLoading || modalData == null) {
      return <Loader color='blue' padding />;
    }
    return (
      <NotificationFragment
        title={modalData.title}
        body={modalData.body ?? ''}
        ctaLabel={modalData.ctaLabel}
        link={modalData.deepLink ?? modalData.link ?? null}
        imageUrl={modalData.image}
      />
    );
  }, [modalData]);

  const onOpenNotification = useCallback(
    async (notification: Notification) => {
      navigate(`/notifications/${notification.id}`, { replace: false });

      if (!notification.isViewed) {
        try {
          const res = await setNotificationIsViewed({ notificationId: notification.id });
          if ('data' in res) {
            refetch();
          }
          dispatch(triggerNotificationUpdate(true));
        } catch (e) {
          console.log('Failed updating notification: ', e);
        }
      }
    },
    []
  );

  const onCloseNotification = useCallback(
    () => {
      navigate(-1);
      setModalOpen(false);
      setModalData(null);
    },
    []
  );

  const sendTestNotification = async () => {
    let session = localStorage.getItem('session');
    let token: any = {};
    if (session !== null) {
      token = JSON.parse(session);
    }

    const body =
      {
        'membershipIds': [
          membership.id
        ],
        'category': 'System',
        'image': 'https://images.ohmyhosting.se/7KT8nhJOZ4gumaOUVuwYmfuAFRU=/fit-in/2048x2048/smart/filters:quality(85)/https%3A%2F%2Fwellr.se%2Fwp-content%2Fuploads%2F2022%2F06%2Fwellr_app_web.png',
        'ctaLabel': {
          'en': 'OK',
          'sv': 'OK',
          'no': 'OK',
          'da': 'OK.'
        },
        'body': {
          'en': 'You have now received a test notification sent from Wellr.',
          'sv': 'Du har mottagit en testnotifikation som är skickad från Wellr.',
          'no': 'Du har nå mottatt et testvarsel sendt fra Wellr.',
          'da': 'Du har nu modtaget en testmeddelelse sendt fra Wellr.'
        },
        'notificationData': {
          'title': {
            'en': 'Test notification',
            'sv': 'Test notifikation',
            'no': 'Testvarsling',
            'da': 'Testmeddelelse '
          },
          'body': {
            'en': 'You have now received a test notification sent from Wellr.',
            'sv': 'Du har mottagit en testnotifikation som är skickad från Wellr.',
            'no': 'Du har nå mottatt et testvarsel sendt fra Wellr.',
            'da': 'Du har nu modtaget en testmeddelelse sendt fra Wellr.'
          },
          'deepLink': ''
        },
        'emailData': {
          'image': 'https://images.ohmyhosting.se/7KT8nhJOZ4gumaOUVuwYmfuAFRU=/fit-in/2048x2048/smart/filters:quality(85)/https%3A%2F%2Fwellr.se%2Fwp-content%2Fuploads%2F2022%2F06%2Fwellr_app_web.png',
          'title': {
            'en': 'Test notification',
            'sv': 'Test notifikation',
            'no': 'Testvarsling',
            'da': 'Testmeddelelse '
          },
          'body': {
            'en': 'You have now received a test notification sent from Wellr.',
            'sv': 'Du har mottagit en testnotifikation som är skickad från Wellr.',
            'no': 'Du har nå mottatt et testvarsel sendt fra Wellr.',
            'da': 'Du har nu modtaget en testmeddelelse sendt fra Wellr.'
          },
          'subject': {
            'en': 'Email subject',
            'sv': 'Email ämne',
            'no': 'Email emne',
            'da': 'Email emne'
          },
          'link': 'https://my-staging.wellr.app'
        }
      };

    const req = await fetch('https://api-staging.wellr.app/api/notification/test', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer: ${token.accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
    );
    const resp = req.status;
    console.log(resp);
  };

  // Notifications
  const notifications = useMemo(() => {
    if (isLoading) {
      return <Loader padding color='blue' />;
    }
    if (!data) {
      return (
        <EmptyState iconType={IconType.Chat} padding>
          <FormattedMessage
            id='pageMyNotificationEmptyState'
            defaultMessage='Unable to get notifications data'
            description='Empty state for user notifications'
          />
        </EmptyState>
      );
    }
    return (
      <StaticCard padding>
        <Container>

          <FlexContainer gap={12} flexDirection='column' style={{ width: '100%' }}>
            <Button onClick={sendTestNotification}>Send test notification</Button>
            {
              data.notifications.map((notification) => {
                return (<NotificationCard
                  key={notification.id}
                  onClick={async () => {
                    await onOpenNotification(notification);
                    // navigate(`/notifications/${notification.id}`, { replace: false })
                  }}
                  isViewed={notification.isViewed}
                  title={notification.title}
                  description={notification.subject ?? ''}
                />);
              })
            }
          </FlexContainer>
        </Container>
        <ContentModal
          isOpen={modalOpen}
          onClose={onCloseNotification}
          title={intl.formatMessage({
            id: 'modalNotificationTitle',
            defaultMessage: 'Notification',
            description: 'Modal for showing notification'
          })}
        >
          {modalContent}
        </ContentModal>
      </StaticCard>
    );
  }, [data, isLoading, modalOpen]);

  const paginationController = useMemo(() => {
    return (
      <PaginationControlCard
        currentPage={data?.currentPage ?? 0}
        pageSize={data?.pageSize ?? 25}
        qtyNotifications={data?.qtyNotifications ?? 0}
        onBack={() => {
          if (!data || data?.currentPage < 1) return;
          setNotificationPages({
            language: language?.languageCode,
            page: data.currentPage - 1,
            pageSize: data.pageSize
          });
        }}
        onForward={() => {
          if (!data || (data.currentPage) * data.pageSize > data.qtyNotifications) return;
          setNotificationPages({
            language: language?.languageCode,
            page: data.currentPage + 1,
            pageSize: data.pageSize
          });
        }}
      ></PaginationControlCard>
    );
  }, [data, notificationPages]);

  return (
    <ContentWidth isSurface>
      <Heading>
        <FormattedMessage
          id='pageMyNotificationsTitle'
          defaultMessage='My Notifications'
          description='Page title for notification settings'
        />
      </Heading>
      {notifications}
      {paginationController}
    </ContentWidth>
  );
};

export default Notifications;
