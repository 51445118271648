import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';

type Props = {
  submitForm: (code: string) => void;
};

const Disable2FAForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => submitForm(values.code),
    [submitForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputAuthenticatorCodeLabel',
          defaultMessage: 'Authenticator code',
          description: 'Label for authenticator code input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputAuthenticatorCodePlaceholder',
          defaultMessage: 'Enter authenticator code',
          description: 'Placeholder for authenticator code input',
        })}
        register={register('code', {
          required: intl.formatMessage({
            id: 'inputErrorCodeRequired',
            defaultMessage: 'Code is required',
            description: 'Input error for code required',
          }),
        })}
        error={errors.code}
      />
      <ActionContainer>
        <Button background="blue" type="submit" disabled={!isDirty}>
          <FormattedMessage
            id="disable2FAButton"
            defaultMessage="Disable 2FA"
            description="Disable 2FA button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default Disable2FAForm;
