import styled from 'styled-components';

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CardImage = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  height: 200px;
`;

export const ImageView = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  user-drag: none;
`;

export const CardText = styled.div`
  padding: 16px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  margin: 8px 0 0;
`;
