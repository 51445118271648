import { FC, createElement, ReactNode } from 'react';

export enum Tag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

type Props = {
  children: ReactNode;
  tag?: Tag;
  id?: string;
};

const Heading: FC<Props> = ({ children, tag = Tag.H1, id }) => {
  return createElement(tag, id ? { id } : null, children);
};

export default Heading;
