import React, { FC, ReactNode, useMemo, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import FlagIcon from '../FlagIcon';

import {
  Container,
  Label,
  Header,
  InputBase,
  InputMessage,
  HelperText,
  ErrorMessage,
  InputWrap,
} from './styles';

type Props = {
  name?: string;
  type?: string;
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  required?: boolean;
  action?: ReactNode;
  helperText?: string;
  error?: any;
  fieldCountryCode?: string;
  blurOnEnter?: boolean;
};

const TextField: FC<Props> = ({
  name,
  label,
  defaultValue,
  placeholder,
  type = 'text',
  disabled = false,
  required = false,
  action,
  register,
  helperText,
  error,
  fieldCountryCode,
  blurOnEnter = false,
}) => {
  // State
  const [active, setActive] = useState<boolean>(false);

  // Message
  const message = useMemo(() => {
    if (error) {
      return <ErrorMessage>{error.message}</ErrorMessage>;
    }
    if (helperText) {
      return <HelperText>{helperText}</HelperText>;
    }
    return null;
  }, [helperText, error]);

  // Focus
  const onFocus = () => setActive(true);
  const onBlur = () => setActive(false);

  return (
    <Container>
      {label && (
        <Header>
          <Label>
            {label}
            {required && '*'}
          </Label>
          {action}
        </Header>
      )}
      <InputWrap withFlag={fieldCountryCode != null}>
        {fieldCountryCode && <FlagIcon countryCode={fieldCountryCode} />}
        <InputBase
          {...register}
          type={type}
          name={name || register?.name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          error={Boolean(error)}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyUp={
            blurOnEnter
              ? (event) =>
                  event.key === 'Enter' && (event.target as HTMLElement).blur()
              : undefined
          }
          active={active}
        />
      </InputWrap>
      <InputMessage>{message}</InputMessage>
    </Container>
  );
};

export default TextField;
