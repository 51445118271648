import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';

import { useCreateUserMutation } from 'store';
import * as routes from 'router/routes';

import Heading from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import SignupForm from 'components/forms/SignupForm';
import CoverHeader from 'components/UI/CoverHeader';
import Loader from 'components/UI/Loader';

import { CoverContent, Description } from 'styles';

const Signup: FC = () => {
  // Hooks
  const [createUser, { isLoading, isSuccess }] = useCreateUserMutation();

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding />;
    }
    return <SignupForm submitForm={createUser} />;
  }, [createUser, isLoading]);

  // Success
  if (isSuccess) {
    return <Navigate to={routes.ROOT} />;
  }

  return (
    <BackgroundCover padding>
      <CoverHeader />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="signupTitle"
            defaultMessage="Register account"
            description="Signup page title"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="signupDescription"
            defaultMessage="Here you can register for an account. Enter any email address, name and password"
            description="Signup page description"
          />
        </Description>
        {content}
      </CoverContent>
    </BackgroundCover>
  );
};

export default Signup;
