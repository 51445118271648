import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { TabMenuItem } from 'models';
import { Category, ContentWidth, Gap } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import {
  localizationSelector,
  setLayoutHeader,
  useGetDietCategoryQuery,
} from 'store';
import * as routes from 'router/routes';
import { getImage } from 'utils/asset';

import RichText from 'components/fragments/RichText';
import TabMenu from 'components/UI/TabMenu';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Heading, { Tag } from 'components/UI/Heading';

import { CategoryHeader } from './styles';

const DietCategoryTabs: FC = () => {
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const intl = useIntl();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'pageDietTitle',
            defaultMessage: 'Diet',
            description: 'Page title for diet',
          }),
          inverted: true,
          icon: IconType.Back,
          link: routes.DIET,
          favorite: {
            reference: slug,
            type: 'diet',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);

  // Tab menu
  const tabMenu: TabMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'tabDietRecipes',
          defaultMessage: 'Recipes',
          description: 'Tab item for diet recipes',
        }),
        link: `${routes.DIET_CATEGORY}/${slug}`,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'tabDietPlan',
          defaultMessage: 'Diet plan',
          description: 'Tab item for diet plan',
        }),
        link: `${routes.DIET_CATEGORY}/${slug}/plan`,
      },
    ];
  }, [intl, slug]);

  // Hooks
  const { data, isLoading } = useGetDietCategoryQuery({
    slug,
    language: language?.languageCode,
  });

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!language || !data?.diet.length) {
    return (
      <EmptyState iconType={IconType.Food} padding>
        <FormattedMessage
          id="pageDietEmptyState"
          defaultMessage="Diet category not found"
          description="Empty state for diet category"
        />
      </EmptyState>
    );
  }

  const {
    diet: [category],
  } = data;
  const { title, image, content } = category;

  return (
    <Fragment>
      <HeroSmall image={getImage(image)}>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageDietTitle"
              defaultMessage="Diet"
              description="Page title for diet"
            />
          </Category>
          <Heading>{getText(title)}</Heading>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        <TabMenu menuId="diet-category" menu={tabMenu} />
        <Gap />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageDietSectionTitleAbout"
            defaultMessage="About"
            description="Page section title for about"
          />
        </Heading>
        {content && <RichText>{content}</RichText>}
        <Gap />
        <Outlet context={{ data, language, slug }} />
      </ContentWidth>
    </Fragment>
  );
};

export default DietCategoryTabs;
