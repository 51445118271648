import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { ActionContainer } from 'styles';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { Fields, Form } from './styles';

type Props = {
  submitForm: (goal: number) => void;
  deleteGoal: () => void;
  goal: number | null;
};

const StepGoalForm: FC<Props> = ({ submitForm, deleteGoal, goal }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Update goal
  useEffect(() => {
    if (goal) {
      setValue('goal', goal);
    }
  }, [setValue, goal]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => submitForm(Number(values.goal)),
    [submitForm]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fields>
        <p>
          <FormattedMessage
            id="stepGoalDescription"
            defaultMessage="Set a daily step goal to challenge yourself"
            description="Step goal description"
          />
        </p>
        <TextField
          type="number"
          label={intl.formatMessage({
            id: 'inputStepGoalLabel',
            defaultMessage: 'Step goal',
            description: 'Label for step goal input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputStepGoalPlaceholder',
            defaultMessage: 'Enter step goal',
            description: 'Placeholder for step goal input',
          })}
          error={errors.goal}
          register={register('goal', {
            required: intl.formatMessage({
              id: 'inputErrorFieldRequired',
              defaultMessage: 'Field is is required',
              description: 'Input error for field required',
            }),
          })}
        />
      </Fields>
      <ActionContainer>
        <Button
          color="error"
          background="transparent"
          border="borderDark"
          onClick={deleteGoal}
        >
          <FormattedMessage
            id="removeButton"
            defaultMessage="Remove"
            description="Remove button text"
          />
        </Button>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="confirmButton"
            defaultMessage="Confirm"
            description="Confirm button text"
          />
        </Button>
      </ActionContainer>
    </Form>
  );
};

export default StepGoalForm;
