import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Content = styled.div`
  max-width: 400px;
  text-align: center;
  padding: 48px 20px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.s}px) {
    padding-top: 96px;
  }
`;
