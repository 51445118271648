import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import { useUpdateContestTeamMutation } from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { useForm } from 'react-hook-form';
import { ActionContainer } from 'styles';
import Button from 'components/UI/Button';
import TextField from 'components/UI/TextField';

type UpdateCompetitionTeamForm = {
  description: string;
};

type Props = {
  contestTeam: {
    description: string | null;
    contestTeamId: string | null;
  } | null;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateCompetitionTeamModal: FC<Props> = ({
  contestTeam,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UpdateCompetitionTeamForm>();

  const [updateTeam, updateTeamResult] = useUpdateContestTeamMutation();

  useEffect(() => {
    if (updateTeamResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateUserModalSuccess',
          defaultMessage: 'User update successful',
          description: 'User update success message',
        })
      );
      onClose();
      setValue('description', '');
    }
  }, [updateTeamResult.isSuccess, intl, onClose, setValue]);

  const description = watch('description');

  // Content
  const content = useMemo(() => {
    if (updateTeamResult.isLoading) {
      return <Loader padding color="blue" />;
    }

    const buttonEnabled = description !== contestTeam?.description;
    return (
      <form
        onSubmit={handleSubmit((form) =>
          updateTeam({
            contestTeamId: contestTeam?.contestTeamId as string,
            name: form.description,
          })
        )}
      >
        <TextField
          label={intl.formatMessage({
            id: 'inputChangeTeamNameLabel',
            defaultMessage: 'New team name',
            description: 'Label for team name input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputChangeTeamNamePlaceholder',
            defaultMessage: 'Enter new team name',
            description: 'Placeholder for team name input',
          })}
          error={errors.description}
          register={register('description', {
            required: intl.formatMessage({
              id: 'inputErrorTeamNameRequired',
              defaultMessage: 'An team name is required',
              description: 'Input error for team name required',
            }),
            maxLength: {
              value: 35,
              message: intl.formatMessage({
                id: 'inputErrorTeamNameMaxLength',
                defaultMessage: 'A team name cannot be more than 35 characters',
                description: 'Input error for team name max length',
              }),
            },
          })}
        />
        <ActionContainer>
          <Button type="submit" background="blue" disabled={!buttonEnabled}>
            <FormattedMessage
              id="updateButton"
              defaultMessage="Update"
              description="Update button text"
            />
          </Button>
        </ActionContainer>
      </form>
    );
  }, [
    contestTeam,
    description,
    errors,
    handleSubmit,
    intl,
    register,
    updateTeam,
    updateTeamResult.isLoading,
  ]);

  const onModalClose = () => {
    onClose();
    setValue('description', '');
  };

  return (
    <SlideoutModal isOpen={isOpen} onClose={onModalClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>{contestTeam?.description}</Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateCompetitionTeamModal;
