import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import RadioButton from 'components/UI/RadioButton';
import Button from 'components/UI/Button';
import TextField from 'components/UI/TextField';

import { ActionContainer } from 'styles';
import { Grid, SelectItem } from './styles';
import { components } from 'generated/api';
import { useAppSelector } from 'hooks/redux';
import { localizationSelector } from 'store';

export interface CompetitionFormData {
  generateTeams: components['schemas']['GenerateTeamsCompanyAdmin'];
  start: string;
  stop?: string;
  title?: components['schemas']['Languages'];
}

interface CompetitionFormFields extends CompetitionFormData {
  customTitle: boolean;
}

type Props = {
  onStart: (form: CompetitionFormData) => void;
};

const CompetitionForm: FC<Props> = ({ onStart }) => {
  const intl = useIntl();

  const { languages } = useAppSelector(localizationSelector);

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<CompetitionFormFields>({
    defaultValues: {
      generateTeams: 'Random',
      customTitle: false,
    },
  });

  // Watch
  const [generateTeams, customTitle] = watch(['generateTeams', 'customTitle']);

  // Submit
  const onCompetition = useCallback(() => {
    const values = getValues();
    onStart({
      ...values,
      title: values.customTitle ? values.title : undefined,
    });
  }, [onStart, getValues]);

  // On select participants
  const onSelect = useCallback(
    (value: CompetitionFormData['generateTeams']) => () =>
      setValue('generateTeams', value),
    [setValue]
  );

  const customTitleInput = useMemo(() => {
    if (!customTitle) {
      return null;
    }
    return (
      <>
        <br />
        {languages.map((language, index) => (
          <TextField
            label={
              index === 0
                ? intl.formatMessage({
                    id: 'inputCompetitionTitleLabel',
                    defaultMessage: 'Competition title',
                    description: 'Label for competition title input',
                  })
                : undefined
            }
            placeholder={intl.formatMessage({
              id: 'inputCompetitionTitlePlaceholder',
              defaultMessage: 'Localized competition title',
              description: 'Placeholder for competition title input',
            })}
            type="text"
            error={errors.title}
            fieldCountryCode={language.countryCode}
            register={register(`title.${language.languageCode}`)}
          />
        ))}
      </>
    );
  }, [customTitle, languages, intl, errors, register]);

  return (
    <Fragment>
      <p>
        <FormattedMessage
          id="inputCompetitionParticipantsDescription"
          defaultMessage="Choose how to allocate the teams"
          description="Competition team allocation option description"
        />
      </p>
      <Grid>
        <SelectItem onClick={onSelect('Random')}>
          <RadioButton
            name="teams-random"
            isChecked={generateTeams === 'Random'}
          />
          <span>
            <FormattedMessage
              id="inputCompetitionTeamsRadioButtonLabelRandom"
              defaultMessage="Generate teams by random"
              description="Radio button label for generate teams by random"
            />
          </span>
        </SelectItem>
        <SelectItem onClick={onSelect('UserCreated')}>
          <RadioButton
            name="teams-user-created"
            isChecked={generateTeams === 'UserCreated'}
          />
          <span>
            <FormattedMessage
              id="inputCompetitionTeamsRadioButtonLabelUserCreated"
              defaultMessage="Teams defined by users"
              description="Radio button label for teams generation by UserCreated option"
            />
          </span>
        </SelectItem>
        <SelectItem onClick={onSelect('Predefined')}>
          <RadioButton
            name="teams-predefined"
            isChecked={generateTeams === 'Predefined'}
          />
          <span>
            <FormattedMessage
              id="inputCompetitionTeamsRadioButtonLabelPredefined"
              defaultMessage="Define teams yourself"
              description="Radio button label for defining teams yourself"
            />
          </span>
        </SelectItem>
      </Grid>
      <TextField
        label={intl.formatMessage({
          id: 'inputStartDateLabel',
          defaultMessage: 'Start date',
          description: 'Label for start date input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputStartDatePlaceholder',
          defaultMessage: 'Enter start date',
          description: 'Placeholder for start date input',
        })}
        helperText={intl.formatMessage({
          id: 'inputCompetitionStartDateHelperText',
          defaultMessage:
            'Make sure to select a start date such that all potential participants have time to accept the invitation.',
          description:
            'Helper text for start date input when starting competition',
        })}
        type="datetime-local"
        error={errors.start}
        register={register('start', {
          required: intl.formatMessage({
            id: 'inputErrorStartDateRequired',
            defaultMessage: 'Start date is required',
            description: 'Input error for start date required',
          }),
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputStopDateLabel',
          defaultMessage: 'Stop date',
          description: 'Label for stop date input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputStopDatePlaceholder',
          defaultMessage: 'Enter stop date',
          description: 'Placeholder for stop date input',
        })}
        type="datetime-local"
        error={errors.stop}
        register={register('stop')}
      />
      <br />
      <Grid>
        <SelectItem onClick={() => setValue('customTitle', !customTitle)}>
          <RadioButton name="custom-title" isChecked={customTitle} />
          <span>
            <FormattedMessage
              id="inputCompetitionRadioButtonLabelCustomTitle"
              defaultMessage="Set a custom competition title"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
        {customTitleInput}
      </Grid>
      <ActionContainer>
        <Button background="blue" onClick={onCompetition}>
          <FormattedMessage
            id="startCompetitionButton"
            defaultMessage="Start competition"
            description="Start competition button text"
          />
        </Button>
      </ActionContainer>
    </Fragment>
  );
};

export default CompetitionForm;
