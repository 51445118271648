import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Language } from 'models';
import {
  localizationSelector,
  layoutSelector,
  setLanguage,
  useAddFavoriteMutation,
  useCheckFavoriteExistsQuery,
  useDeleteFavoriteMutation,
  useUpdateLanguageMutation,
  useGetUserQuery,
  logoutSession,
} from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Header from './Header';
import Footer from './Footer';

import { Container, Content } from './styles';
import { useWindowSize } from 'hooks/useWindowSize';
import { breakpoints } from 'styles';
import BottomNavigationBar from 'components/fragments/BottomNavigationBar';

const Layout: FC = () => {
  const dispatch = useAppDispatch();
  const { pageTitle, icon, link, inverted, favorite, companyInHeader } =
    useAppSelector(layoutSelector);
  const { languages, language } = useAppSelector(localizationSelector);

  // Hooks
  const [updateLanguage] = useUpdateLanguageMutation();
  const [addFavorite, addResult] = useAddFavoriteMutation();
  const [deleteFavorite, deleteResult] = useDeleteFavoriteMutation();
  const user = useGetUserQuery(undefined, {
    refetchOnFocus: true,
    pollingInterval: 1000 * 60 * 5,
  });

  const checkFavorite = useCheckFavoriteExistsQuery(
    {
      reference: favorite?.reference,
      type: favorite?.type,
    },
    { skip: !favorite }
  );

  // State
  const [isFavorised, setIsFavorised] = useState<boolean>(false);

  // Init favorite
  useEffect(() => {
    if (checkFavorite.data) {
      setIsFavorised(checkFavorite.data.exists);
    }
  }, [checkFavorite]);

  // Check disabled
  const isDisabled = useMemo(() => {
    return !checkFavorite.data || addResult.isLoading || deleteResult.isLoading;
  }, [checkFavorite, addResult, deleteResult]);

  // Set favorite
  const onSetFavorite = useCallback(() => {
    if (!favorite || isDisabled) {
      return null;
    }
    if (isFavorised) {
      deleteFavorite(favorite);
    } else {
      addFavorite(favorite);
    }
    setIsFavorised(!isFavorised);
  }, [addFavorite, deleteFavorite, isFavorised, isDisabled, favorite]);

  // Set language
  const onSetLanguage = useCallback(
    (language: Language) => {
      dispatch(setLanguage(language));
      if (language) {
        updateLanguage(language.languageCode);
      }
    },
    [dispatch, updateLanguage]
  );

  // Logout
  const onLogout = useCallback(() => dispatch(logoutSession()), [dispatch]);

  const { width } = useWindowSize();
  const isMobile = useMemo(() => width < breakpoints.l, [width]);

  // No language
  if (!language) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Header
          pageTitle={pageTitle}
          companyInHeader={companyInHeader && !isMobile}
          inverted={inverted}
          languages={languages}
          language={language}
          favorite={favorite}
          icon={icon}
          link={link}
          isWellrAdmin={user.data?.wellrAdmin || false}
          isCompanyAdmin={user.data?.companyAdmin || false}
          isFavorised={isFavorised}
          onSetLanguage={onSetLanguage}
          onSetFavorite={onSetFavorite}
          onLogout={onLogout}
        />
        <main>
          <Outlet />
        </main>
        <Footer />
        <BottomNavigationBar />
      </Content>
    </Container>
  );
};

export default Layout;
