import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { ActivityType, SelectOption, ActivityBody } from 'models';
import { getDateTime } from 'utils/date';
import { ActionContainer } from 'styles';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';
import SelectField from 'components/UI/SelectField';

import { Fields, Form } from './styles';
import ConfirmActivityPopup from 'components/popups/ConfirmActivityPopup/ConfirmActivityPopup';

type Props = {
  submitForm: (data: ActivityBody) => void;
  data: ActivityType[];
  isOpen: boolean;
};

const RegisterActivityForm: FC<Props> = ({ submitForm, data, isOpen }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: getDateTime(),
      activitySlug: data[0].slug,
      activityUnitSlug: data[0].allowedUnits[0].unit.slug,
      numberOfUnits: null,
    },
  });

  const [showConfirm, setShowConfirm] = useState(false);

  // Watch
  const activitySlug = watch('activitySlug');
  const activityUnitSlug = watch('activityUnitSlug');
  const numberOfUnits = watch('numberOfUnits');

  // Close confirm dialog on modal close
  useEffect(() => {
    if (!isOpen && showConfirm) {
      setShowConfirm(false);
    }
  }, [isOpen, showConfirm]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const {
        date,
        activitySlug,
        activityUnitSlug,
        numberOfUnits: amount,
      } = values;
      const numberOfUnits = Number(amount);
      if (showConfirm) {
        setShowConfirm(false);
      } else if (
        (activityUnitSlug === 'step' && numberOfUnits >= 20000) ||
        (activityUnitSlug === 'minute' && numberOfUnits >= 120)
      ) {
        setShowConfirm(true);
        return;
      }
      submitForm({
        date,
        activitySlug,
        activityUnitSlug,
        numberOfUnits,
      });
    },
    [submitForm, showConfirm]
  );

  // Activity options
  const activityOptions: SelectOption[] = useMemo(() => {
    return data.map((item) => ({
      value: item.slug,
      name: item.title,
    }));
  }, [data]);

  // Activity unit options
  const activityUnitOptions: SelectOption[] = useMemo(() => {
    const found = data.find((item) => item.slug === activitySlug);
    const units = found ? found.allowedUnits : data[0].allowedUnits;
    return units.map((item) => ({
      value: item.unit.slug,
      name: item.unit.titleSingular,
    }));
  }, [data, activitySlug]);

  // Synchronize the UI state of the selected unit with the internal state whenever the selectable units changes
  useEffect(() => {
    const currentUnitSlug = activityUnitOptions.find(
      ({ value }) => value === activityUnitSlug
    );
    if (currentUnitSlug === undefined) {
      setValue(
        'activityUnitSlug',
        activityUnitOptions.length > 0
          ? activityUnitOptions[0].value.toString()
          : ''
      );
    } else {
      setValue('activityUnitSlug', currentUnitSlug.value.toString());
    }
  }, [activityUnitOptions, activityUnitSlug, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <TextField
          type="datetime-local"
          label={intl.formatMessage({
            id: 'inputDateLabel',
            defaultMessage: 'Date',
            description: 'Label for date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputDatePlaceholder',
            defaultMessage: 'Enter date',
            description: 'Placeholder for date input',
          })}
          error={errors.date}
          register={register('date', {
            required: intl.formatMessage({
              id: 'inputErrorFieldRequired',
              defaultMessage: 'Field is is required',
              description: 'Input error for field required',
            }),
          })}
        />
        <Fields>
          <SelectField
            label={intl.formatMessage({
              id: 'inputActivityLabel',
              defaultMessage: 'Activity',
              description: 'Label for activity input',
            })}
            register={register('activitySlug', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              }),
            })}
            options={activityOptions}
            error={errors.activitySlug}
          />
          <SelectField
            label={intl.formatMessage({
              id: 'inputActivityUnitLabel',
              defaultMessage: 'Unit',
              description: 'Label for activity unit input',
            })}
            register={register('activityUnitSlug', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              }),
            })}
            options={activityUnitOptions}
            error={errors.activityUnitSlug}
          />
        </Fields>
        <TextField
          type="number"
          label={intl.formatMessage({
            id: 'inputQuantityLabel',
            defaultMessage: 'Quantity',
            description: 'Label for quantity input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputQuantityPlaceholder',
            defaultMessage: 'Enter quantity',
            description: 'Placeholder for quantity input',
          })}
          error={errors.numberOfUnits}
          register={register('numberOfUnits', {
            required: intl.formatMessage({
              id: 'inputErrorFieldRequired',
              defaultMessage: 'Field is is required',
              description: 'Input error for field required',
            }),
          })}
        />
      </div>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="registerActivityButton"
            defaultMessage="Register activity"
            description="Register activity button"
          />
        </Button>
      </ActionContainer>
      <ConfirmActivityPopup
        isOpen={showConfirm}
        onAbort={() => setShowConfirm(false)}
        numberOfUnits={Number(numberOfUnits)}
        activityUnitSlug={activityUnitSlug}
      />
    </Form>
  );
};

export default RegisterActivityForm;
