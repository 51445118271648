import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { ContentWidth, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useDeleteMemberMutation,
  useGetCompanyDepartmentQuery,
  useGetCompanyUserQuery,
  useToggleCompanyAdminMutation,
} from 'store';
import * as routes from 'router/routes';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import { IconType } from 'components/UI/Icon';
import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';
import DataItem from 'components/UI/DataItem';
import { DataGrid } from './styles';
import StaticCard from 'components/UI/Cards/StaticCard/StaticCard';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import UpdateUserDepartmentModal from 'components/modals/UpdateUserDepartmentModal';
import toast from 'react-hot-toast';
import { usePretendCompany } from 'hooks/usePretendCompany';
import UpdateUserEmailModal from 'components/modals/UpdateUserEmailModal';
import { User } from 'models';

const AdminDepartmentUser: FC = () => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  // Hooks
  const [deleteMember, deleteMemberResult] = useDeleteMemberMutation();
  const { data, isLoading, isFetching } = useGetCompanyUserQuery(
    { userId: userId as string, companyId },
    {
      skip: !userId || deleteMemberResult.isSuccess,
    }
  );
  const [toggleCompanyAdmin, toggleCompanyAdminResult] =
    useToggleCompanyAdminMutation();

  const departmentResult = useGetCompanyDepartmentQuery(
    {
      departmentId: data?.membership?.departmentId as string,
      companyId,
    },
    { skip: data?.membership?.departmentId == null }
  );

  const onAdminToggle = () => {
    if (!data?.membership?.id) {
      console.error('No membership id found');
      return;
    }
    toggleCompanyAdmin({
      membershipId: data.membership.id,
      companyAdmin: !data.companyAdmin,
    });
  };

  useEffect(() => {
    if (deleteMemberResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'deleteMemberSuccess',
          defaultMessage: 'User membership successfully deleted.',
          description:
            'Toast success text when successfully deleted user membership.',
        })
      );
      navigate(routes.ADMIN_ORGANISATION);
    }
  }, [deleteMemberResult.isSuccess, intl, navigate]);

  // Set header
  useEffect(() => {
    const fromOrganisationRoute = pathname.includes(routes.ADMIN_ORGANISATION);
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage(
          fromOrganisationRoute
            ? {
              id: 'pageAdminOrganisationTitle',
              defaultMessage: 'Organisation',
              description: 'Page title for admin organisation page',
            }
            : {
              id: 'pageAdminUsersTitle',
              defaultMessage: 'Users',
              description: 'Page title for admin users',
            }
        ),
        icon: IconType.Back,
        link: fromOrganisationRoute
          ? routes.ADMIN_ORGANISATION
          : routes.ADMIN_COMPANY_USERS,
      })
    );
  }, [dispatch, intl, pathname]);

  // State
  const [userModalOpen, setUserModalOpen] = useState<'department' | 'email' | null>(
    null
  );

  // Close
  const onModalOpen = useCallback(
    (modal: 'department' | 'email') => setUserModalOpen(modal),
    []
  );

  const onModalClose = useCallback(() => setUserModalOpen(null), []);

  // Details
  const userDetails = useMemo(() => {
    if (!data) {
      return null;
    }
    const details = [
      {
        label: intl.formatMessage({
          id: 'adminUserNameLabel',
          description: 'Admin user details Name label',
          defaultMessage: 'Name',
        }),
        value: data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : intl.formatMessage({
          id: 'adminUnregisteredUserNameValue',
          description: 'Name for unregistered user',
          defaultMessage: 'Not Registered',
        }),
      },
      {
        label: intl.formatMessage({
          id: 'adminUserEmailLabel',
          description: 'Admin user details Email label',
          defaultMessage: 'Email',
        }),
        value: data.email,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserIDLabel',
          description: 'Admin user details ID label',
          defaultMessage: 'ID',
        }),
        value: data.id,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserDepartmentLabel',
          description: 'Admin department user details department label',
          defaultMessage: 'Department',
        }),
        value: departmentResult.data?.description,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserPointsLabel',
          description: 'Admin user details Points label',
          defaultMessage: 'Points',
        }),
        value: data.points,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserStepsLabel',
          description: 'Admin user details Steps label',
          defaultMessage: 'Steps',
        }),
        value: data.steps,
      },
    ];
    return (
      <DataGrid>
        {details.map((item) => (
          <DataItem key={item.label} description={item.label}>
            {item.value}
          </DataItem>
        ))}
      </DataGrid>
    );
  }, [data, intl, departmentResult.data?.description]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // Empty state
  if (!data) {
    return (
      <EmptyState iconType={IconType.User} padding>
        <FormattedMessage
          id="pageAdminUserDetailsNotFound"
          defaultMessage="A User with the provided email was not found"
          description="Not found text for admin user details page"
        />
      </EmptyState>
    );
  }

  if (!data.membership) {
    return (
      <EmptyState iconType={IconType.User} padding>
        <FormattedMessage
          id="pageAdminUserDetailsNoMembership"
          defaultMessage="No membership found for user."
          description="No membership text for admin user details page"
        />
      </EmptyState>
    );
  }

  return (
    <ContentWidth isSurface>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="adminUsersDetailsTitle"
          defaultMessage="User details"
          description="User details title on Admin users page"
        />
      </Heading>
      <StaticCard padding>
        {userDetails}
        <Gap />
        <Button
          color="blue"
          size="small"
          background="transparent"
          border="borderDark"
          onClick={() => onModalOpen('email')}
        >
          <FormattedMessage
            id="adminUsersChangeEmailButton"
            defaultMessage="Change email"
            description="Button for changing user email on Admin users details page"
          />
        </Button>
        <br />
        <Button
          color="blue"
          size="small"
          background="transparent"
          border="borderDark"
          onClick={() => onModalOpen('department')}
        >
          <FormattedMessage
            id="adminDepartmentUserChangeDepartmentButton"
            defaultMessage="Change department"
            description="Button for changing user department on Admin department user details page"
          />
        </Button>
        <br />
        <Button
          color={data.companyAdmin ? 'error' : 'blue'}
          size="small"
          background="transparent"
          border="borderDark"
          onClick={onAdminToggle}
          disabled={toggleCompanyAdminResult?.isLoading || isFetching}
        >
          {data.companyAdmin ? (
            <FormattedMessage
              id="adminDepartmentUserRevokeCompanyAdminButton"
              defaultMessage="Revoke company admin access"
              description="Button label for revoking user company admin access on Admin department user details page"
            />
          ) : (
            <FormattedMessage
              id="adminDepartmentUserPromoteCompany"
              defaultMessage="Promote to company admin"
              description="Button label for promoting user company admin access on Admin department user details page"
            />
          )}
        </Button>
        <br />
        <Button
          color="error"
          size="small"
          background="transparent"
          border="borderDark"
          onClick={() => setShowConfirmDelete(true)}
        >
          <FormattedMessage
            id="adminDepartmentUserDeleteMembershipButton"
            defaultMessage="Delete membership"
            description="Button for deleting user membership on Admin department user details page"
          />
        </Button>
      </StaticCard>
      <ConfirmActionPopup
        isOpen={showConfirmDelete}
        onConfirm={() =>
          deleteMember({
            userId: userId as string,
            companyId: data.membership?.companyId,
          })
        }
        onAbort={() => setShowConfirmDelete(false)}
      >
        <>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="deleteMembershipConfirmHeading"
              defaultMessage="Delete membership"
              description="Heading text in the delete membership popup"
            />
          </Heading>
          <FormattedMessage
            id="deleteMembershipConfirmBody"
            defaultMessage="Are you sure you want to delete this user's membership?"
            description="Body text in the delete membership popup"
          />
        </>
      </ConfirmActionPopup>
      <UpdateUserEmailModal
        user={data as User}
        isOpen={userModalOpen === 'email'}
        onClose={onModalClose}
      />
      <UpdateUserDepartmentModal
        departmentId={data.membership.departmentId ?? ''}
        membershipId={data.membership.id ?? ''}
        isOpen={userModalOpen === 'department'}
        onClose={onModalClose}
      />
    </ContentWidth>
  );
};

export default AdminDepartmentUser;
