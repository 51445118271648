import { Marker } from '@react-google-maps/api';
import { ContestGoal } from 'models';
import { FC, useMemo } from 'react';

import subGoalMarker from 'assets/vectors/achievement-badge.svg';

type Props = {
  goals: ContestGoal[];
};

const MapSubGoalMarkers: FC<Props> = ({ goals }) => {
  const subGoalMarkers = useMemo(() => {
    const markers: Array<{ lat: number; lng: number }> = [];
    goals.forEach((goal) => {
      goal.subGoals.forEach((subGoal) => {
        if (subGoal.markOnMap) {
          markers.push({
            lat: subGoal.lat,
            lng: subGoal.lng,
          });
        }
      });
    });
    return markers;
  }, [goals]);
  if (!subGoalMarkers.length) {
    return null;
  }
  return (
    <>
      {subGoalMarkers.map((subGoal) => (
        <Marker
          key={subGoal.lat + subGoal.lng}
          icon={{
            url: subGoalMarker,
            scaledSize: new google.maps.Size(32, 32),
            anchor: new google.maps.Point(16, 8),
            origin: new google.maps.Point(0, 0),
          }}
          position={{
            lat: subGoal.lat,
            lng: subGoal.lng,
          }}
          zIndex={10}
        />
      ))}
    </>
  );
};

export default MapSubGoalMarkers;
