import styled from 'styled-components';

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
`;

export const CardHeading = styled.span`
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
`;

export const SectionSpacing = styled.div`
  height: 16px;
`;

export const CardDescription = styled.div`
  font-size: 12px;
  fony-family: 'Mark-Medium';
  color: ${({ theme }) => theme.colors.grayText};
`;

export const BorderedList = styled.div`
  > * {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    transition: 0.2s ease-in-out;
    &:first-child {
      border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
    }
    &:last-child {
      border-bottom: none;
    }
  }
`;
