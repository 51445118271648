import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import {
  localizationSelector,
  useGetActivityTypesQuery,
  useGetMonthlyActivitiesQuery,
} from 'store';
import { useAppSelector } from 'hooks/redux';
import { isSameDay } from 'utils/date';
import { sortArrayByProperty } from 'utils/array';
import { groupActivities } from 'utils/activity';

import ActivityList from 'components/fragments/ActivityList';
import RegisterActivityModal from 'components/modals/RegisterActivityModal';
import StaticCard from 'components/UI/Cards/StaticCard';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';

import { Grid, Action, CalendarGrid } from './styles';

const ActivitiesMonthly: FC = () => {
  const { language } = useAppSelector(localizationSelector);

  // State
  const [date, setActive] = useState<Date>(new Date());
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  // Hooks
  const activityTypes = useGetActivityTypesQuery({
    language: language?.languageCode,
  });
  const activities = useGetMonthlyActivitiesQuery({
    date: date.toString(),
  });

  // Open / close modal
  const onOpenModal = useCallback(() => setRegisterModalOpen(true), []);
  const onCloseModal = useCallback(() => setRegisterModalOpen(false), []);

  // Activity dates
  const activityDates = useMemo(() => {
    const { data } = activities;
    if (!data) {
      return [];
    }
    return groupActivities(data).map((item) => new Date(item.timestamp));
  }, [activities]);

  // Last activities
  const dayActivities = useMemo(() => {
    if (!activities.data || !activityTypes.data) {
      return null;
    }
    const sortedActivities = sortArrayByProperty(
      activities.data,
      'timestamp',
      'desc'
    );
    const filteredActivities = sortedActivities.filter((item) =>
      isSameDay(new Date(item.timestamp), date)
    );

    return <ActivityList data={filteredActivities} showWalkSteps />;
  }, [activities, activityTypes, date]);

  // Loading
  if (activityTypes.isLoading || activities.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!activityTypes.data || !activities.data) {
    return null;
  }

  return (
    <StaticCard>
      <Grid>
        <CalendarGrid>
          <Calendar
            value={date}
            onActiveStartDateChange={({ activeStartDate }) =>
              setActive(activeStartDate as Date)
            }
            onChange={(value) => setActive(value as Date)}
            tileClassName={({ date, view }) => {
              if (
                view === 'month' &&
                activityDates.some((activityDate) =>
                  isSameDay(activityDate, date)
                )
              ) {
                return 'activeCalendarDay';
              }
              return '';
            }}
          />
        </CalendarGrid>
        {dayActivities}
        <Action>
          <Button color="blue" background="transparent" onClick={onOpenModal}>
            <FormattedMessage
              id="registerActivityButton"
              defaultMessage="Register activity"
              description="Register activity button"
            />
          </Button>
        </Action>
      </Grid>
      <RegisterActivityModal
        isOpen={registerModalOpen}
        onClose={onCloseModal}
      />
    </StaticCard>
  );
};

export default ActivitiesMonthly;
