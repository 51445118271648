import { FC, Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider } from 'styles';
import { ContestType, Image } from 'models';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import ClickableCard from 'components/UI/Cards/ClickableCard';
import WellrCoins from 'components/UI/WellrCoins';
import Button from 'components/UI/Button';

import {
  Grid,
  Column,
  Img,
  Container,
  ImageContainer,
  IconContainer,
  Byline,
  Action,
} from './styles';

type Props = {
  image: Image | null;
  title: string | null;
  byline?: string;
  link: string;
  buttonText?: string;
  wellrPoints?: number;
  type: ContestType;
  filter?: boolean;
  accepted?: boolean;
};

const CategoryCard: FC<Props> = ({
  image,
  title,
  link,
  type,
  buttonText,
  wellrPoints,
  filter,
  accepted,
}) => {
  const navigate = useNavigate();

  // Click
  const onClick = useCallback(() => navigate(link), [navigate, link]);

  const arrowIcon = useMemo(() => {
    if (accepted || type === 'Challenge' || type === 'Competition') {
      return (
        <IconContainer>
          <Icon type={IconType.Arrow} />
        </IconContainer>
      );
    }
    return null;
  }, [type, accepted]);

  // Actions
  const actions = useMemo(() => {
    if (accepted || type === 'Challenge' || type === 'Competition') {
      return null;
    }
    return (
      <Fragment>
        <Divider />
        <Action>
          <Button background="blue" onClick={onClick}>
            {buttonText}
          </Button>
        </Action>
      </Fragment>
    );
  }, [onClick, accepted, buttonText, type]);

  return (
    <ClickableCard onClick={onClick}>
      <Grid>
        <Container>
          {image && (
            <ImageContainer filter={filter ? 1 : 0}>
              <Img src={image.src} alt={image.alt} />
            </ImageContainer>
          )}
          <Column>
            <Heading tag={Tag.H5}>{title}</Heading>
            <Byline>
              {wellrPoints ? <WellrCoins count={wellrPoints} right /> : null}
            </Byline>
          </Column>
        </Container>
        {arrowIcon}
      </Grid>
      {actions}
    </ClickableCard>
  );
};

export default CategoryCard;
