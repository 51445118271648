import { createGlobalStyle } from 'styled-components';

import markMediumEot from './Mark-Medium.eot';
import markMediumWoff2 from './Mark-Medium.woff2';
import markMediumWoff from './Mark-Medium.woff';
import markMediumTTF from './Mark-Medium.ttf';

import markMediumItalicEot from './Mark-Medium-Italic.eot';
import markMediumItalicWoff2 from './Mark-Medium-Italic.woff2';
import markMediumItalicWoff from './Mark-Medium-Italic.woff';
import markMediumItalicTTF from './Mark-Medium-Italic.ttf';

import markRegularEot from './Mark-Regular.eot';
import markRegularWoff2 from './Mark-Regular.woff2';
import markRegularWoff from './Mark-Regular.woff';
import markRegularTTF from './Mark-Regular.ttf';

import markRegularItalicEot from './Mark-Regular-Italic.eot';
import markRegularItalicWoff2 from './Mark-Regular-Italic.woff2';
import markRegularItalicWoff from './Mark-Regular-Italic.woff';
import markRegularItalicTTF from './Mark-Regular-Italic.ttf';

import markBoldEot from './Mark-Bold.eot';
import markBoldWoff2 from './Mark-Bold.woff2';
import markBoldWoff from './Mark-Bold.woff';
import markBoldTTF from './Mark-Bold.ttf';

import markBoldItalicEot from './Mark-Bold-Italic.eot';
import markBoldItalicWoff2 from './Mark-Bold-Italic.woff2';
import markBoldItalicWoff from './Mark-Bold-Italic.woff';
import markBoldItalicTTF from './Mark-Bold-Italic.ttf';

import markBookEot from './Mark-Book.eot';
import markBookWoff2 from './Mark-Book.woff2';
import markBookWoff from './Mark-Book.woff';
import markBookTTF from './Mark-Book.ttf';

import markBookItalicEot from './Mark-Book-Italic.eot';
import markBookItalicWoff2 from './Mark-Book-Italic.woff2';
import markBookItalicWoff from './Mark-Book-Italic.woff';
import markBookItalicTTF from './Mark-Book-Italic.ttf';

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Mark';
    src: url(${markBookItalicEot});
    src: local('Mark-Book-Italic'),
        url(${markBookItalicEot}?#iefix) format('embedded-opentype'),
        url(${markBookItalicWoff2}) format('woff2'),
        url(${markBookItalicWoff}) format('woff'),
        url(${markBookItalicTTF}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Mark';
    src: url(${markBookEot});
    src: local('Mark-Book'),
        url(${markBookEot}?#iefix) format('embedded-opentype'),
        url(${markBookWoff2}) format('woff2'),
        url(${markBookWoff}) format('woff'),
        url(${markBookTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mark';
    src: url(${markMediumEot});
    src: local('Mark-Medium'),
        url(${markMediumEot}?#iefix) format('embedded-opentype'),
        url(${markMediumWoff2}) format('woff2'),
        url(${markMediumWoff}) format('woff'),
        url(${markMediumTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mark';
    src: url(${markMediumItalicEot});
    src: local('Mark-Medium-Italic'),
        url(${markMediumItalicEot}?#iefix) format('embedded-opentype'),
        url(${markMediumItalicWoff2}) format('woff2'),
        url(${markMediumItalicWoff}) format('woff'),
        url(${markMediumItalicTTF}) format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Mark';
    src: url(${markRegularEot});
    src: local('Mark-Regular'),
        url(${markRegularEot}?#iefix) format('embedded-opentype'),
        url(${markRegularWoff2}) format('woff2'),
        url(${markRegularWoff}) format('woff'),
        url(${markRegularTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mark';
    src: url(${markRegularItalicEot});
    src: local('Mark-Regular-Italic'),
        url(${markRegularItalicEot}?#iefix) format('embedded-opentype'),
        url(${markRegularItalicWoff2}) format('woff2'),
        url(${markRegularItalicWoff}) format('woff'),
        url(${markRegularItalicTTF}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Mark';
    src: url(${markBoldEot});
    src: local('Mark-Bold'),
        url(${markBoldEot}?#iefix) format('embedded-opentype'),
        url(${markBoldWoff2}) format('woff2'),
        url(${markBoldWoff}) format('woff'),
        url(${markBoldTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mark';
    src: url(${markBoldItalicEot});
    src: local('Mark-Bold-Italic'),
        url(${markBoldItalicEot}?#iefix) format('embedded-opentype'),
        url(${markBoldItalicWoff2}) format('woff2'),
        url(${markBoldItalicWoff}) format('woff'),
        url(${markBoldItalicTTF}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }
`;

export default GlobalFonts;
