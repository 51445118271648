import styled, { keyframes } from 'styled-components';
import { ThemeColor } from 'styles';

export type LoaderSize = 24 | 48 | 64;

type SpinnerProps = {
  size: LoaderSize;
  color: ThemeColor;
};

type GridProps = {
  padding?: boolean;
};

const loading = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const Spinner = styled.div<SpinnerProps>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  position: relative;
  animation: ${loading} 1s linear infinite;

  &::before,
  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: ${({ size }) => size / 8}px solid
      ${({ theme, color }) => theme.colors[color]};
    animation: ${prixClipFix} 2s linear infinite;
  }

  &::after {
    border-color: ${({ theme, color }) => theme.colors[color]};
    animation: ${prixClipFix} 2s linear infinite,
      ${loading} 0.5s linear infinite reverse;
    inset: ${({ size }) => size / 6}px;
  }
`;

export const LoaderGrid = styled.div<GridProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => (padding ? '64px 24px' : 0)};
`;
