import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';

import { GenerateMfa } from 'models';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';
import Heading, { Tag } from 'components/UI/Heading';
import StaticCard from 'components/UI/Cards/StaticCard';

import { ActionContainer, Gap } from 'styles';
import { Barcode, Container } from './styles';

type Props = {
  submitForm: (code: string) => void;
  data?: GenerateMfa;
};

const Setup2FAForm: FC<Props> = ({ submitForm, data }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => submitForm(values.code),
    [submitForm]
  );

  // On copy code
  const onCopyCode = useCallback(() => {
    if (data) {
      navigator.clipboard.writeText(data.setupCode);
      toast.success(
        intl.formatMessage({
          id: 'mfaCodeCopied',
          defaultMessage: 'Two-Factor setup code has been copied',
          description: 'Mfa code copied text',
        })
      );
    }
  }, [intl, data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        <FormattedMessage
          id="mfaCodeSetupTitle"
          defaultMessage="Two-factor authentication has not been configured for this account. Information on how to configure it is found below."
          description="Setup title for mfa code"
        />
      </p>
      <StaticCard padding>
        <Container>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="mfaCodeSetupStep1Title"
              defaultMessage="1. Configure the app"
              description="Setup step 1 title for mfa code"
            />
          </Heading>
          <p>
            <FormattedMessage
              id="mfaCodeSetupStep1Description"
              defaultMessage="Scan the QR code or enter the configuration key manually into your authenticator app (Google Authenticator or Microsoft Authenticator)."
              description="Setup step 1 description for mfa code"
            />
          </p>
          <Barcode src={data?.barcodeImageUrl} alt="setup barcode" />
          <TextField
            label={intl.formatMessage({
              id: 'inputConfigurationCodeLabel',
              defaultMessage: 'Configuration code',
              description: 'Label for configuration code input',
            })}
            defaultValue={data?.setupCode}
            disabled
          />
          <Button background="blue" size="small" onClick={onCopyCode}>
            <FormattedMessage
              id="copyCodeButton"
              defaultMessage="Copy code"
              description="Copy code button text"
            />
          </Button>
        </Container>
      </StaticCard>
      <Gap />
      <StaticCard padding>
        <Container>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="mfaCodeSetupStep2Title"
              defaultMessage="2. Enter code"
              description="Setup step 2 title for mfa code"
            />
          </Heading>
          <p>
            <FormattedMessage
              id="mfaCodeSetupStep2Description"
              defaultMessage="Enter the code generated by the authenticator app below."
              description="Setup step 2 description for mfa code"
            />
          </p>
          <TextField
            label={intl.formatMessage({
              id: 'inputAuthenticatorCodeLabel',
              defaultMessage: 'Authenticator code',
              description: 'Label for authenticator code input',
            })}
            placeholder={intl.formatMessage({
              id: 'inputAuthenticatorCodePlaceholder',
              defaultMessage: 'Enter authenticator code',
              description: 'Placeholder for authenticator code input',
            })}
            register={register('code', {
              required: intl.formatMessage({
                id: 'inputErrorCodeRequired',
                defaultMessage: 'Code is required',
                description: 'Input error for code required',
              }),
            })}
            error={errors.code}
          />
        </Container>
      </StaticCard>
      <ActionContainer>
        <Button background="blue" type="submit" disabled={!isDirty}>
          <FormattedMessage
            id="confirmButton"
            defaultMessage="Confirm"
            description="Confirm button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default Setup2FAForm;
