import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Logo = styled.img`
  height: 40px;
`;

export const LogoLink = styled(Link)`
  text-align: center;

  @media (max-width: ${breakpoints.l}px) {
    text-align: left;
  }
`;
