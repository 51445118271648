import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ChatContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  h3 {
    margin: 0;
  }
`;

export const ChatBody = styled.div`
  position: relative;
  height: calc(100vh - 130px);
  overflow-y: auto;
`;

export const ChatButton = styled.button`
  position: fixed;
  right: 16px;
  bottom: 81px;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.blue};
  border: none;
  border-radius: 28px;
  outline: none;
  cursor: pointer;
  z-index: 97;

  @media (min-width: ${breakpoints.m}px) {
    bottom: 16px;
  }
`;

export const MessageGrid = styled.div`
  margin: 12px 20px;
`;

export const MessageDate = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  margin: 8px 0;
`;

export const ChatInputGrid = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  position absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
`;

export const MessageInput = styled.textarea`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 28px;
  border: 2px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 24px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  resize: none;
  -webkit-appearance: none;
  height: 32px;
  max-height: 72px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const IconContainer = styled.div`
  transform: rotate(90deg);
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
`;

export const DropdownContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    max-width: 86px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
`;

export const Input = styled.input`
  display: none;
`;
