import StepGoalModal from 'components/modals/StepGoalModal';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';
import MultiProgressBar from 'components/UI/MultiProgressBar';
import { Activity } from 'models';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetStepGoalQuery } from 'store';
import {
  Circle,
  LargeText,
  ProgressIndicatorContainer,
  SmallText,
  StepCol,
  StepGoalButton,
  StepHeader,
  StepProgress,
  StepRefreshButton,
  Steps,
} from './styles';

type Props = {
  activitiesData: Activity[];
  refetch?: () => void;
};

const ActivitiesHeader: FC<Props> = ({ activitiesData, refetch }) => {
  const RE_FETCH_TIMEOUT_MS = 1000 * 10
  const [lastGetStepGoalFetch, setLastGetStepGoalFetch] = useState(0);

  const { dailyStepGoal, isLoading, fulfilledTimeStamp } = useGetStepGoalQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: lastGetStepGoalFetch + RE_FETCH_TIMEOUT_MS < Date.now(),
    selectFromResult: (response) => ({
      dailyStepGoal: response.data?.dailyStepGoal,
      isLoading: response.isLoading,
      fulfilledTimeStamp: response.fulfilledTimeStamp
    }),
  });

  useEffect(() => {
    setLastGetStepGoalFetch(Date.now())
  }, [fulfilledTimeStamp])

  const [goalModalOpen, setGoalModalOpen] = useState<boolean>(false);

  const onOpenGoalModal = useCallback(() => setGoalModalOpen(true), []);
  const onCloseGoalModal = useCallback(() => setGoalModalOpen(false), []);

  const {
    steps,
    activities: stepsFromActivities,
    total: stepsTotal,
  } = useMemo(() => {
    const sum = activitiesData.reduce(
      (sum, { value }) => {
        if (value.activityUnitSlug === 'step') {
          sum.steps += value.stepsAwarded;
        } else {
          sum.activities += value.stepsAwarded;
        }
        return sum;
      },
      { steps: 0, activities: 0 }
    );
    return { ...sum, total: sum.steps + sum.activities };
  }, [activitiesData]);

  // Left to complete goal
  const renderRightCol = useCallback(
    (value: number | null, leftToValue: number | null) => {
      if (value === null) {
        return (
          <SmallText>
            <FormattedMessage
              id="activitiesCreateGoalText"
              defaultMessage="Create a step goal"
              description="Create a step goal text for activities"
              values={{ value }}
            />
          </SmallText>
        );
      }
      if (!leftToValue) {
        return null;
      }
      return (
        <SmallText>
          <FormattedMessage
            id="activitiesStepsLeftText"
            defaultMessage="{leftToValue} steps left"
            description="Unit text for activities"
            values={{ leftToValue }}
          />
        </SmallText>
      );
    },
    []
  );

  const remainingSteps = useMemo(() => {
    if (dailyStepGoal == null) {
      return null;
    }
    return Math.max(0, dailyStepGoal - stepsTotal);
  }, [dailyStepGoal, stepsTotal]);

  const stepProgress = useMemo(() => {
    if (dailyStepGoal === undefined) {
      return null;
    }
    const totalOrDailySteps =
      dailyStepGoal == null ? stepsTotal : Math.max(dailyStepGoal, stepsTotal);
    const isComplete =
      dailyStepGoal == null ? false : stepsTotal >= dailyStepGoal;
    return (
      <StepProgress>
        <MultiProgressBar
          progress={[
            {
              progress:
                totalOrDailySteps > 0 ? (steps / totalOrDailySteps) * 100 : 0,
              color: 'blue',
            },
            {
              progress:
                totalOrDailySteps > 0
                  ? (stepsFromActivities / totalOrDailySteps) * 100
                  : 0,
              color: 'teal',
            },
          ]}
        />
        <ProgressIndicatorContainer>
          <div>
            <Circle color="blue" />
            <SmallText capitalize>
              <FormattedMessage
                id="activitiesUnitText"
                defaultMessage="steps"
                description="Unit text for activities"
              />
            </SmallText>
          </div>
          <div>
            <Circle color="teal" />
            <SmallText capitalize>
              <FormattedMessage
                id="stepsFromActivitiesTitle"
                defaultMessage="steps from activities"
                description="Title for steps from activities"
              />
            </SmallText>
          </div>
        </ProgressIndicatorContainer>
        {isComplete && (
          <LargeText>
            <FormattedMessage
              id="activitiesStepGoalCompleteText"
              defaultMessage="Congratulations you completed your daily goal of {value} steps!"
              description="Step goal complete text"
              values={{ value: dailyStepGoal }}
            />
          </LargeText>
        )}
      </StepProgress>
    );
  }, [dailyStepGoal, steps, stepsFromActivities, stepsTotal]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (dailyStepGoal === undefined) {
    return null;
  }

  return (
    <>
      <Steps>
        <StepHeader>
          <StepCol>
            <Heading tag={Tag.H3}>{stepsTotal}</Heading>
            <SmallText>
              <FormattedMessage
                id="activitiesUnitText"
                defaultMessage="steps"
                description="Unit text for activities"
              />
            </SmallText>
            <StepRefreshButton onClick={refetch}>
              <Icon type={IconType.Refresh} color="blue" size={16} />
            </StepRefreshButton>
          </StepCol>
          <StepCol>
            <StepGoalButton onClick={onOpenGoalModal}>
              {renderRightCol(dailyStepGoal, remainingSteps)}
            </StepGoalButton>
            <Icon type={IconType.Arrow} size={16} color="grayText" />
          </StepCol>
        </StepHeader>
        {stepProgress}
      </Steps>
      <StepGoalModal
        dailyStepGoal={dailyStepGoal}
        isOpen={goalModalOpen}
        onClose={onCloseGoalModal}
      />
    </>
  );
};

export default ActivitiesHeader;
