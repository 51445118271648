import React, { FC, Fragment, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestGoal, ContestTeam } from 'models';
import Heading, { Tag } from 'components/UI/Heading';
import ProgressBar from 'components/UI/ProgressBar';

import {
  TeamList,
  TeamItem,
  TeamItemText,
  TeamItemGrid,
  TeamItemProgress,
  TeamItemHeader,
  TeamRank,
  TeamItemData,
} from './styles';

type Props = {
  goals: ContestGoal[];
  teams: ContestTeam[];
  team: ContestTeam | null;
};

const CompetitionTeams: FC<Props> = ({ goals, teams, team }) => {
  // Render list
  const list = useMemo(() => {
    if (!team) {
      return teams;
    }
    if (teams.some((item) => item.id === team.id)) {
      return teams;
    }
    return [...teams, team];
  }, [teams, team]);

  return (
    <Fragment>
      {goals.map((goal) => (
        <TeamList key={goal.id}>
          {list.map((item, i) => {
            const cut = 100 * (item.total / goal.amount);
            const progress = cut > 99 ? Math.floor(cut) : Math.round(cut);
            const myTeam = team ? team.id === item.id : false;
            return (
              <TeamItem key={item.id}>
                <TeamRank isMine={myTeam} rank={item.position}>
                  {item.position || '-'}
                </TeamRank>
                <TeamItemData>
                  <TeamItemHeader>
                    <Heading tag={Tag.H4}>
                      {!myTeam ? (
                        item.name
                      ) : (
                        <FormattedMessage
                          id="competitionTeamsMyTeam"
                          defaultMessage="{name} (My team)"
                          description="My team text for competition teams"
                          values={{
                            name: item.name,
                          }}
                        />
                      )}
                    </Heading>
                    <TeamItemText>
                      {item.total}{' '}
                      <FormattedMessage
                        id="activitiesUnitText"
                        defaultMessage="steps"
                        description="Unit text for activities"
                      />
                    </TeamItemText>
                  </TeamItemHeader>
                  <TeamItemGrid>
                    <TeamItemText>{`${progress}%`}</TeamItemText>
                    <TeamItemProgress>
                      <ProgressBar progress={progress} color="blue" />
                    </TeamItemProgress>
                  </TeamItemGrid>
                </TeamItemData>
              </TeamItem>
            );
          })}
        </TeamList>
      ))}
    </Fragment>
  );
};

export default CompetitionTeams;
