import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { regExp } from 'utils/string';
import { UserValues } from 'models';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';

type Props = {
  submitForm: (data: UserValues) => void;
};

const SignupForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // Watch
  const currentPassword = watch('password');

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const signupValues: UserValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        verifyPassword: values.verifyPassword,
      };
      submitForm(signupValues);
    },
    [submitForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputFirstNameLabel',
          defaultMessage: 'First name',
          description: 'Label for first name input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputFirstNamePlaceholder',
          defaultMessage: 'Enter first name',
          description: 'Placeholder for first name input',
        })}
        error={errors.firstName}
        register={register('firstName', {
          required: intl.formatMessage({
            id: 'inputErrorFieldRequired',
            defaultMessage: 'Field is is required',
            description: 'Input error for field required',
          }),
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputLastNameLabel',
          defaultMessage: 'Last name',
          description: 'Label for last name input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputLastNamePlaceholder',
          defaultMessage: 'Enter last name',
          description: 'Placeholder for last name input',
        })}
        error={errors.lastName}
        register={register('lastName', {
          required: intl.formatMessage({
            id: 'inputErrorFieldRequired',
            defaultMessage: 'Field is is required',
            description: 'Input error for field required',
          }),
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputEmailLabel',
          defaultMessage: 'Email',
          description: 'Label for email input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputEmailPlaceholder',
          defaultMessage: 'Enter email',
          description: 'Placeholder for email input',
        })}
        error={errors.email}
        register={register('email', {
          required: intl.formatMessage({
            id: 'inputErrorEmailRequired',
            defaultMessage: 'Email is required',
            description: 'Input error for email required',
          }),
          pattern: {
            value: regExp.email,
            message: intl.formatMessage({
              id: 'inputErrorEmailInvalid',
              defaultMessage: 'Please enter a valid email',
              description: 'Input error for email invalid',
            }),
          },
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputPasswordLabel',
          defaultMessage: 'Password',
          description: 'Label for password input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputPasswordPlaceholder',
          defaultMessage: 'Enter password',
          description: 'Placeholder for password input',
        })}
        register={register('password', {
          required: intl.formatMessage({
            id: 'inputErrorPasswordRequired',
            defaultMessage: 'Password is required',
            description: 'Input error for password required',
          }),
        })}
        type="password"
        error={errors.password}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputVerifyPasswordLabel',
          defaultMessage: 'Verify password',
          description: 'Label for verify password input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputPasswordPlaceholder',
          defaultMessage: 'Enter password',
          description: 'Placeholder for password input',
        })}
        register={register('verifyPassword', {
          required: intl.formatMessage({
            id: 'inputErrorPasswordRequired',
            defaultMessage: 'Password is required',
            description: 'Input error for password required',
          }),
          validate: (value) =>
            value === currentPassword ||
            intl.formatMessage({
              id: 'inputErrorPasswordNoMatch',
              defaultMessage: 'Passwords do not match',
              description: 'Input error for no match passwords',
            }),
        })}
        type="password"
        error={errors.verifyPassword}
      />
      <ActionContainer>
        <Button type="submit" color="black" background="yellow">
          <FormattedMessage
            id="signupButton"
            defaultMessage="Create account"
            description="Create account button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default SignupForm;
