import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveFilters, LayoutState, MenuType, PageHeader } from 'models';
import { RootState } from '../store';

export const initialLayoutState: LayoutState = {
  pageTitle: '',
  inverted: false,
  icon: null,
  link: null,
  favorite: null,
  activeExpandable: null,
  activeFilters: null,
  companyInHeader: false,
  activeMenuTab:
    (localStorage.getItem('activeMenuTab') as MenuType) ?? MenuType.Regular,
};

// Export slice
const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialLayoutState,
  reducers: {
    setLayoutHeader: (state, action: PayloadAction<PageHeader>) => {
      const {
        title,
        inverted = false,
        link = null,
        icon = null,
        favorite = null,
        companyInHeader = false,
      } = action.payload;

      state.pageTitle = title;
      state.inverted = inverted;
      state.link = link;
      state.icon = icon;
      state.favorite = favorite;
      state.companyInHeader = companyInHeader;
    },
    setActiveExpandable: (state, action: PayloadAction<string | null>) => {
      state.activeExpandable = action.payload;
    },
    setActiveFilters: (state, action: PayloadAction<ActiveFilters>) => {
      state.activeFilters = action.payload;
    },
    setActiveMenuTab: (state, action: PayloadAction<MenuType>) => {
      state.activeMenuTab = action.payload;
      localStorage.setItem('activeMenuTab', action.payload);
    },
  },
});

// Export actions
export const {
  setLayoutHeader,
  setActiveExpandable,
  setActiveFilters,
  setActiveMenuTab,
} = layoutSlice.actions;

// Export reducer
export const layoutReducer = layoutSlice.reducer;

// Export selectors
export const layoutSelector = (state: RootState) => state.layout;
