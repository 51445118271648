import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 40px;

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const HeaderGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const HeaderCol = styled.div`
  width: 80px;
`;

export const IngredientRow = styled.div`
  display: flex;
  gap: 4px;
  margin: 4px 0;

  p {
    margin: 0;
  }
`;

export const NutritionalGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ProgressGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;
