import React, { FC } from 'react';
import { ThemeColor } from 'styles';

import { Container } from './styles';

type Props = {
  background?: ThemeColor;
  color?: ThemeColor;
  value?: number;
};

const formatValue = (value: number) => {
  if (!value || value <= 0) return ''
  if (value > 9) return '9+'
  return '' + value
}

const Bubble: FC<Props> = ({
  background = 'black',
  color = 'white',
  value = 0
}) => (
  <Container
    background={background}
    color={color}
    value={formatValue(value)}
    >
    {formatValue(value)}
  </Container >
);

export default Bubble;
