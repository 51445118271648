import { FC } from 'react';
import CountryFlag from 'react-country-flag';

import { Container } from './styles';

type Props = {
  countryCode: string;
};

const FlagIcon: FC<Props> = ({ countryCode }) => {
  return (
    <Container type="button">
      <CountryFlag
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        }}
        countryCode={countryCode}
        title={countryCode}
        svg
      />
    </Container>
  );
};

export default FlagIcon;
