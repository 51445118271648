import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useConfirmResetPasswordMutation } from 'store';
import * as routes from 'router/routes';

import Heading from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import Loader from 'components/UI/Loader';
import CoverHeader from 'components/UI/CoverHeader';
import ConfirmResetPasswordForm from 'components/forms/ConfirmResetPasswordForm';

import { CoverContent, Description } from 'styles';

const ConfirmResetPassword: FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetCode = searchParams.get('resetCode');
  // Hooks
  const intl = useIntl()
  const [confirmResetPassword, { isLoading, isSuccess }] =
    useConfirmResetPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'confirmResetPasswordSuccess',
          defaultMessage: 'Your password has successfully been updated',
          description: 'New password success message"',
        })
      );
    }
  }, [intl, isSuccess]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding />;
    }
    return <ConfirmResetPasswordForm submitForm={confirmResetPassword} resetCode={resetCode} />;
  }, [confirmResetPassword, isLoading]);

  // Redirect to login
  if (isSuccess) {
    return <Navigate to={routes.LOGIN} />;
  }

  return (
    <BackgroundCover padding>
      <CoverHeader subLevel />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="confirmResetPasswordTitle"
            defaultMessage="Confirm reset password"
            description="Confirm reset password page title"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="confirmResetPasswordDescription"
            defaultMessage="Enter the reset code sent to your email and set a new password"
            description="Confirm reset password page description"
          />
        </Description>
        {content}
      </CoverContent>
    </BackgroundCover>
  );
};

export default ConfirmResetPassword;
