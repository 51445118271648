import styled from 'styled-components';

type Props = {
  padding: boolean;
  disabled: boolean;
};

export const ButtonBase = styled.button<Props>`
  display: flex;
  margin: 0;
  padding: ${({ padding }) => (padding ? '8px' : '0')};
  background-color: transparent;
  border: none;
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
