import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  localizationSelector,
  setLayoutHeader,
  useGetArticleCategoryQuery,
} from 'store';

import { Category, ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getImage } from 'utils/asset';
import * as routes from 'router/routes';

import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import Heading from 'components/UI/Heading';
import ArticleCard from 'components/UI/Cards/ArticleCard';
import Loader from 'components/UI/Loader';

import { CardList, CategoryHeader } from './styles';

const InspirationCategory: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'pageInspirationTitle',
            defaultMessage: 'Inspiration',
            description: 'Page title for inspiration',
          }),
          icon: IconType.Back,
          link: routes.INSPIRATION,
          favorite: {
            reference: slug,
            type: 'articleCategory',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);

  // Hooks
  const { data, isLoading } = useGetArticleCategoryQuery({
    slug,
    language: language?.languageCode,
  });

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data?.articleCategory.length) {
    return (
      <EmptyState iconType={IconType.Inspiration} padding>
        <FormattedMessage
          id="pageInspirationEmptyState"
          defaultMessage="Inspiration category not found"
          description="Empty state for inspiration category"
        />
      </EmptyState>
    );
  }

  const {
    articleCategory: [category],
    articles,
  } = data;

  return (
    <ContentWidth isSurface>
      <CategoryHeader>
        <Category>
          <FormattedMessage
            id="pageInspirationTitle"
            defaultMessage="Inspiration"
            description="Page title for inspiration"
          />
        </Category>
        <Heading>{getText(category.title)}</Heading>
      </CategoryHeader>
      <CardList>
        {articles.map(({ _id, title, excerpt, image, slug }) => {
          return (
            <ArticleCard
              key={_id}
              title={getText(title)}
              description={getText(excerpt)}
              link={`${routes.INSPIRATION_ARTICLE}/${slug.current}`}
              image={getImage(image)}
            />
          );
        })}
      </CardList>
    </ContentWidth>
  );
};

export default InspirationCategory;
