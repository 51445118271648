import styled from 'styled-components';

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CategoryHeader = styled.div`
  p {
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    margin: 0 0 8px;
  }
`;

export const HealthIndex = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  h1 {
    font-size: 48px;
    margin: 0;
  }

  div {
    margin-bottom: 8px;
  }
`;
