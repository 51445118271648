import CategoryCard from 'components/UI/Cards/CategoryCard';
import ToggleCard from 'components/UI/Cards/ToggleCard';
import Heading from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import { MembershipSettings } from 'models';
import { FC, Fragment, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useGetMembershipSettingsQuery,
  useUpdateMembershipSettingsMutation,
} from 'store';
import { ContentWidth } from 'styles';
import { CardList } from './styles';
import * as routes from 'router/routes';

type Props = {
  allSettings?: boolean;
};

const UserSettingsList: FC<Props> = ({ allSettings = false }) => {
  const settings = useGetMembershipSettingsQuery();
  const [updateSettings] = useUpdateMembershipSettingsMutation();
  const intl = useIntl();

  // On toggle settings
  const onToggle = useCallback(
    (key: keyof MembershipSettings) => (active: boolean) => {
      const { data } = settings;
      if (data) {
        updateSettings({ ...data, [key]: active });
      }
    },
    [updateSettings, settings]
  );

  // Membership cards
  const membershipCards = useMemo(() => {
    const { data, isLoading } = settings;

    if (isLoading) {
      return <Loader color="blue" padding />;
    }

    if (!data) {
      return null;
    }

    return (
      <Fragment>
        {allSettings && (
          <ToggleCard
            title={intl.formatMessage({
              id: 'settingsIncludeQuizStatisticsTitle',
              defaultMessage: 'Include results in quiz statistics',
              description: 'Settings title for include in quiz statistics',
            })}
            isActive={data.includeInQuizStatistics}
            onToggle={onToggle('includeInQuizStatistics')}
          />
        )}

        <ToggleCard
          title={intl.formatMessage({
            id: 'settingsAvailableForContestsTitle',
            defaultMessage: 'Available in competitions',
            description: 'Settings title for available for contests',
          })}
          isActive={data.availableForContests}
          onToggle={onToggle('availableForContests')}
        />
        <ToggleCard
          title={intl.formatMessage({
            id: 'settingsShowInToplistTitle',
            defaultMessage: 'Show me in top list',
            description: 'Settings title for show me in top list',
          })}
          isActive={data.showInToplist}
          onToggle={onToggle('showInToplist')}
        />
      </Fragment>
    );
  }, [settings, allSettings, intl, onToggle]);

  return (
    <ContentWidth isSurface>
      <Heading>
        <FormattedMessage
          id="pageSettingsTitle"
          defaultMessage="Settings"
          description="Page title for settings"
        />
      </Heading>
      <CardList>
        <CategoryCard
          title={intl.formatMessage({
            id: 'settingsProfileTitle',
            defaultMessage: 'Profile',
            description: 'Settings title for profile',
          })}
          link={routes.SETTINGS_PROFILE}
        />
        <CategoryCard
          title={intl.formatMessage({
            id: 'settingsUntiedConnectionTitle',
            defaultMessage: 'Connect a watch',
            description: 'Settings title for Untied connection',
          })}
          link={routes.SETTINGS_WEARABLE_DEVICE}
        />
        {allSettings && (
          <CategoryCard
            title={intl.formatMessage({
              id: 'settingsMFATitle',
              defaultMessage: 'Two-Factor Authentication',
              description: 'Settings title for MFA',
            })}
            link={routes.SETTINGS_MFA}
          />
        )}
        <CategoryCard
          title={intl.formatMessage({
            id: 'settingsNotificationTitle',
            defaultMessage: 'Notifications',
            description: 'Settings title for notifications',
          })}
          link={routes.SETTINGS_NOTIFICATION}
        />

        {membershipCards}
      </CardList>
    </ContentWidth>
  );
};

export default UserSettingsList;
