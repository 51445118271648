import Button from 'components/UI/Button';
import Heading, { Tag } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import TextField from 'components/UI/TextField';
import { backendApi } from 'config';
import { usePageHeader } from 'hooks/usePageHeader';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as routes from 'router/routes';
import { useCreateCompanyFromTemplateMutation } from 'store';
import { ContentWidth, MediumGap } from 'styles';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/UI/Loader';

const templateUrl = `${backendApi}/company/register-from-template`;

const AdminCompanyCreate: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  usePageHeader({
    title: intl.formatMessage({
      id: 'pageAdminCompaniesTitle',
      defaultMessage: 'Companies',
      description: 'Page title for admin companies',
    }),
    inverted: false,
    icon: IconType.Back,
    link: routes.ADMIN_COMPANIES,
  });
  const [createCompany, createCompanyResult] =
    useCreateCompanyFromTemplateMutation();

  useEffect(() => {
    if (createCompanyResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'createCompanySuccess',
          defaultMessage: 'Company created successfully',
          description: 'Company create toast success message',
        })
      );
      navigate(routes.ADMIN_COMPANIES);
    } else if (createCompanyResult.isError) {
      toast.error(
        intl.formatMessage({
          id: 'createCompanyError',
          defaultMessage: 'Company creation failed',
          description: 'Company create toast error message',
        })
      );
    }
  }, [
    createCompanyResult.isSuccess,
    createCompanyResult.isError,
    intl,
    navigate,
  ]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{ file: FileList }>();

  const onSubmit = handleSubmit(({ file }) => createCompany({ file: file[0] }));

  return (
    <ContentWidth isSurface>
      <Heading tag={Tag.H2}>
        <FormattedMessage
          id="pageWellrAdminCompanyCreateTitle"
          defaultMessage="Create company"
          description="Heading1 for admin company create page"
        />
      </Heading>
      <FormattedMessage
        id="pageWellrAdminCompanyCreateDescription"
        defaultMessage="Create a company using the Excel company template file. Download the template {link} and upload the filled file below."
        description="Description for admin company create page"
        values={{
          link: (
            <a href={templateUrl} download>
              <FormattedMessage
                id="pageWellrAdminCompanyCreateDescriptionLink"
                defaultMessage="here"
                description="Link label for admin company create excel template download link"
              />
            </a>
          ),
        }}
      />
      <MediumGap />
      <form onSubmit={onSubmit}>
        <TextField
          label={intl.formatMessage({
            id: 'inputCompanyFileInputLabel',
            defaultMessage: 'Company excel definition',
            description: 'Label for start date input',
          })}
          type="file"
          error={errors.file}
          register={register('file', { required: true })}
        />
        <Button
          type="submit"
          background="blue"
          disabled={createCompanyResult.isLoading}
        >
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
        {createCompanyResult.isLoading && <Loader color="blue" padding />}
      </form>
    </ContentWidth>
  );
};

export default AdminCompanyCreate;
