import styled from 'styled-components';

type MessageProps = {
  isMine?: boolean;
};

export const MessageAuthor = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 12px;
  margin: 8px 0;
`;

export const MessageRow = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isMine }) => (isMine ? 'flex-end' : 'flex-start')};
  margin: 8px 0;
`;

export const Message = styled.div<MessageProps>`
  background: ${({ theme, isMine }) =>
    theme.colors[isMine ? 'blue' : 'backgroundLight']};
  color: ${({ theme, isMine }) => theme.colors[isMine ? 'white' : 'black']};
  border-radius: 12px;
  padding: 8px 12px;
  cursor: ${({ isMine }) => (isMine ? 'pointer' : 'initial')};
`;
