import { FC, useMemo } from 'react';
import Heading, { Tag } from 'components/UI/Heading';
import RadioButton from 'components/UI/RadioButton';
import Checkbox from 'components/UI/Checkbox';

import { Card } from './styles';

type Props = {
  text: string;
  isActive: boolean;
  hasMultipleAnswers: boolean;
  onClick: () => void;
};

const QuizAlternativeCard: FC<Props> = ({
  text,
  isActive,
  hasMultipleAnswers,
  onClick,
}) => {
  // Check button
  const button = useMemo(() => {
    if (hasMultipleAnswers) {
      return <Checkbox name="alt-check" isChecked={isActive} />;
    }
    return <RadioButton name="alt-radio" isChecked={isActive} />;
  }, [isActive, hasMultipleAnswers]);

  return (
    <Card onClick={onClick}>
      {button}
      <Heading tag={Tag.H5}>{text}</Heading>
    </Card>
  );
};

export default QuizAlternativeCard;
