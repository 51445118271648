import { FC, Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  setLayoutHeader,
  localizationSelector,
  useGetExerciseProgramQuery,
} from 'store';

import { Category, ContentWidth, Gap } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getImage } from 'utils/asset';

import RichText from 'components/fragments/RichText';
import { IconType } from 'components/UI/Icon';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';

import { CategoryHeader } from './styles';
import ExcerciseProgramProgress from 'components/fragments/ExcerciseProgramProgress';

const ExerciseProgramGuide: FC = () => {
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();

  const intl = useIntl();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data, isLoading } = useGetExerciseProgramQuery({
    slug,
    language: language?.languageCode,
  });

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'backButton',
            defaultMessage: 'Back',
            description: 'Back button text',
          }),
          inverted: true,
          icon: IconType.Back,
          favorite: {
            reference: slug,
            type: 'exerciseProgram',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Exercise} padding>
        <FormattedMessage
          id="pageExerciseProgramEmptyState"
          defaultMessage="Exercise program not found"
          description="Empty state for exercise program"
        />
      </EmptyState>
    );
  }

  const {
    title,
    content,
    image,
    exerciseDescriptionPairs,
    exerciseProgramSteps,
  } = data;

  return (
    <Fragment>
      <HeroSmall image={getImage(image)}>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageExerciseProgramsCategory"
              defaultMessage="Program"
              description="Page category for exercise programs"
            />
          </Category>
          <Heading>{getText(title)}</Heading>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageExerciseProgramSectionTitleAbout"
            defaultMessage="About the program"
            description="Page section title for about in exercise program"
          />
        </Heading>
        {content && <RichText>{content}</RichText>}
        <Gap />
        <ExcerciseProgramProgress
          slug={slug as string}
          exerciseDescriptionPairs={exerciseDescriptionPairs}
          exerciseProgramSteps={exerciseProgramSteps}
        />
      </ContentWidth>
    </Fragment>
  );
};

export default ExerciseProgramGuide;
