import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.div`
  h3 {
    margin: 0;
  }
`;

export const Barcode = styled.img`
  margin: 16px 0;
`;

export const ProfileImage = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  min-height: 120px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.backgroundLight};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.s}px) {
    width: 64px;
    height: 64px;
    min-height: 64px;
  }
`;

export const Input = styled.input`
  display: none;
`;
