import { IngredientItem } from 'models';

function getIngredientMass(item: IngredientItem) {
  const { unit, numberOfUnits, ingredient } = item;
  // get the mass[gram] of this ingredient
  if (unit?.unitType === 'mass' && unit.mass) {
    // unit.mass is the definition of the unit in gram. For example, unit.mass is 1000 for a kilogram
    return unit.mass * numberOfUnits;
  }
  if (unit?.unitType === 'volume' && unit.volume) {
    //unit.volume is the definition of the unit in milliliters. For example, unit.volume is 1000 for a liter
    return unit.volume * (ingredient.nutrition.density / 1000) * numberOfUnits;
  }
  return ingredient.nutrition.massOfOneUnit * numberOfUnits;
}

export function getIngredientData(item: IngredientItem) {
  const massOfIngredient = getIngredientMass(item);
  const { energy, proteinPercentage, carbohydratePercentage, fatPercentage } =
    item.ingredient.nutrition;
  return {
    energy: (massOfIngredient / 100) * energy,
    protein: massOfIngredient * proteinPercentage,
    carbohydrate: massOfIngredient * carbohydratePercentage,
    fat: massOfIngredient * fatPercentage,
    mass: massOfIngredient,
  };
}

export function getIngredientsDataSummary(ingredients: IngredientItem[]) {
  const sum = {
    energy: 0,
    protein: 0,
    carbohydrate: 0,
    fat: 0,
    total: 0,
  };

  ingredients.forEach((item) => {
    const data = getIngredientData(item);

    sum.energy += data.energy;
    sum.protein += data.protein;
    sum.carbohydrate += data.carbohydrate;
    sum.fat += data.fat;
    sum.total += data.mass;
  });

  return sum;
}
