import { FC, ChangeEvent } from 'react';

import {
  CheckboxContainer,
  StyledRadioButton,
  HiddenRadioButton,
  Dot,
} from './styles';

type Props = {
  name: string;
  isChecked?: boolean;
  isDefaultChecked?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const RadioButton: FC<Props> = ({
  name,
  isChecked,
  isDefaultChecked,
  isDisabled = false,
  isError = false,
  onChange,
}) => (
  <CheckboxContainer>
    <HiddenRadioButton
      id={name}
      name={name}
      checked={isChecked}
      defaultChecked={isDefaultChecked}
      disabled={isDisabled}
      onChange={onChange}
      readOnly={!onChange}
    />
    <StyledRadioButton
      isChecked={isChecked || isDefaultChecked || false}
      isError={isError}
    >
      <Dot />
    </StyledRadioButton>
  </CheckboxContainer>
);

export default RadioButton;
