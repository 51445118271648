import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  h3 {
    margin: 0;
  }
`;

export const Body = styled.div`
  height: calc(100vh - 188px);
  overflow-y: auto;
  padding: 20px;
`;

export const ButtonGrid = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const ActionButtons = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  position absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
`;
