import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  setLayoutHeader,
  localizationSelector,
  useGetActivityTypesQuery,
  useGetContestDarkModeContentQuery,
} from 'store';
import { getImage } from 'utils/asset';
import { getRefLink } from 'utils/link';
import { isAfter, isBefore } from 'utils/date';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { ContentWidth, Gap } from 'styles';
import * as routes from 'router/routes';
import { ContestContext, ContestTeamPendingUser } from 'models';

import RichText from 'components/fragments/RichText';
import CompetitionTopList from 'components/fragments/CompetitionTopList';
import CompetitionStatus from 'components/fragments/CompetitionStatus';
import CompetitionTeamInvites from 'components/fragments/CompetitionTeamInvites';
import CompetitionUserList from 'components/fragments/CompetitionUserList';
import LastActivities from 'components/fragments/LastActivities';
import HealthChoices from 'components/fragments/HealthChoices';
import ContestHeader from 'components/fragments/ContestHeader';

import EditAliasModal from 'components/modals/EditAliasModal';
import RegisterActivityModal from 'components/modals/RegisterActivityModal';
import InviteTeamMembersModal from 'components/modals/InviteTeamMembersModal';
import CreateTeamModal from 'components/modals/CreateTeamModal';
import LeaveTeamModal from 'components/modals/LeaveTeamModal';
import ContentModal from 'components/modals/ContentModal';

import { IconType } from 'components/UI/Icon';
import DragSlider from 'components/UI/DragSlider';
import ImageCard from 'components/UI/Cards/ImageCard';
import Heading, { Tag } from 'components/UI/Heading';
import StaticCard from 'components/UI/Cards/StaticCard';
import EmptyState from 'components/UI/EmptyState';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';
import ListMenu from 'components/UI/ListMenu';

import {
  ButtonGrid,
  SmallButtonGrid,
  SectionHeader,
  CardList,
  CardItem,
  UserStepsContainer,
  StepText,
} from './styles';
import { checkShowRegisterActivities } from 'utils/contest';
import HeadingWithInfo from 'components/UI/HeadingWithInfo/HeadingWithInfo';
import Image from 'components/UI/Image';

const CompetitionOverview: FC = () => {
  const { contest } = useOutletContext<ContestContext>();
  const darkModeContent = useGetContestDarkModeContentQuery(
    {
      contestId: contest.id,
    },
    { skip: contest.darkMode === false }
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();

  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const activityTypes = useGetActivityTypesQuery({
    language: language?.languageCode,
  });

  // State
  const [allTeamView, setAllTeamView] = useState<boolean>(false);
  const [editAliasModalOpen, setEditAliasModalOpen] = useState<boolean>(false);
  const [createTeamModalOpen, setCreateTeamModalOpen] =
    useState<boolean>(false);
  const [leaveTeamModalOpen, setLeaveTeamModalOpen] = useState<boolean>(false);
  const [inviteTeamMemberModalOpen, setInviteTeamMemberModalOpen] =
    useState<boolean>(false);
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);
  const [instructionsModalOpen, setInstructionsModalOpen] =
    useState<boolean>(false);
  const [topListInfoModalOpen, setTopListInfoModalOpen] =
    useState<boolean>(false);

  // Open / close modal
  const onSetTeamView = useCallback(
    (value: boolean) => () => setAllTeamView(value),
    []
  );
  const onOpenRegisterModal = useCallback(() => setRegisterModalOpen(true), []);
  const onCloseRegisterModal = useCallback(
    () => setRegisterModalOpen(false),
    []
  );
  const onOpenInstructionModal = useCallback(
    () => setInstructionsModalOpen(true),
    []
  );
  const onCloseInstructionModal = useCallback(
    () => setInstructionsModalOpen(false),
    []
  );
  const onOpenCreateTeamModal = useCallback(
    () => setCreateTeamModalOpen(true),
    []
  );
  const onCloseCreateTeamModal = useCallback(
    () => setCreateTeamModalOpen(false),
    []
  );
  const onOpenLeaveTeamModal = useCallback(
    () => setLeaveTeamModalOpen(true),
    []
  );
  const onCloseLeaveTeamModal = useCallback(
    () => setLeaveTeamModalOpen(false),
    []
  );
  const onOpenInviteTeamMemberModal = useCallback(
    () => setInviteTeamMemberModalOpen(true),
    []
  );
  const onCloseInviteTeamMemberModal = useCallback(
    () => setInviteTeamMemberModalOpen(false),
    []
  );
  const onOpenEditAliasModal = useCallback(
    () => setEditAliasModalOpen(true),
    []
  );
  const onCloseEditAliasModal = useCallback(
    () => setEditAliasModalOpen(false),
    []
  );
  const onOpenTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(true),
    []
  );
  const onCloseTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(false),
    []
  );

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageCompeteTitle',
          defaultMessage: 'Compete',
          description: 'Compete page title',
        }),
        inverted: true,
        icon: IconType.Back,
        link: routes.COMPETE,
      })
    );
  }, [dispatch, intl]);

  // Navigate
  const onNavigate = useCallback(
    (link: string) => () => navigate(link),
    [navigate]
  );

  // Render invite list
  const renderInviteList = useCallback(
    (pendingInvites: ContestTeamPendingUser[]) => {
      if (!pendingInvites.length) {
        return null;
      }
      return (
        <Fragment>
          <Gap />
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="pageCompetitionPendingInvitesTitle"
              defaultMessage="Pending invites"
              description="Title for pending invites section on competition page"
            />
          </Heading>
          <CardList>
            {pendingInvites.map((user) => (
              <StaticCard key={user.id}>
                <CardItem>
                  <Heading tag={Tag.H4}>
                    {user.alias != null && user.alias !== ''
                      ? user.alias
                      : user.email}
                  </Heading>
                </CardItem>
              </StaticCard>
            ))}
          </CardList>
        </Fragment>
      );
    },
    []
  );

  const showRegisteredActivitiesSection = useMemo(
    () => checkShowRegisterActivities(contest.goals),
    [contest]
  );

  // Actions
  const actions = useMemo(() => {
    const { me, start, canCreateTeam, completed } = contest;

    // Show register button if competition has started and is not completed
    if (
      isAfter(start) &&
      showRegisteredActivitiesSection &&
      completed == null
    ) {
      return (
        <Button background="blue" onClick={onOpenRegisterModal}>
          <FormattedMessage
            id="registerActivityButton"
            defaultMessage="Register activity"
            description="Register activity button"
          />
        </Button>
      );
    }

    // Hide button if unable to create team
    if (!canCreateTeam) {
      return null;
    }

    // Show create a team button if no team
    if (!me.team) {
      return (
        <Button background="blue" onClick={onOpenCreateTeamModal}>
          <FormattedMessage
            id="createTeamButton"
            defaultMessage="Create a team"
            description="Create team button"
          />
        </Button>
      );
    }

    // Show invite button if captain
    if (me.id === me.team.captain) {
      return (
        <Button background="blue" onClick={onOpenInviteTeamMemberModal}>
          <FormattedMessage
            id="inviteTeamMemberButton"
            defaultMessage="Invite team member"
            description="Invite team member button"
          />
        </Button>
      );
    }

    return null;
  }, [
    contest,
    showRegisteredActivitiesSection,
    onOpenRegisterModal,
    onOpenCreateTeamModal,
    onOpenInviteTeamMemberModal,
  ]);

  const rawUserTeamTotal = useMemo(() => {
    if (!contest.individualResults || contest.me.team?.users == null) {
      return contest.me.team?.total ?? 0;
    }
    return contest.me.team.users.reduce((acc, user) => acc + user.total, 0);
  }, [contest]);

  // Content
  const content = useMemo(() => {
    const {
      id,
      me,
      toplist,
      totalAmount,
      goals,
      teams,
      reference,
      start,
      stop,
      maxUsersPerTeam,
      individualResults,
      individualTopList,
      canCreateTeam,
      compensate,
    } = contest;

    // No team
    if (!me.team) {
      return (
        <Fragment>
          {canCreateTeam ? (
            <Fragment>
              <p>
                <FormattedMessage
                  id="pageCompetitionNoTeamDescription"
                  defaultMessage="How exciting that you're joining the competition! You now have 2 options."
                  description="Description if no team on competition page"
                />
              </p>
              <p>
                <FormattedMessage
                  id="pageCompetitionNoTeamChoise1Description"
                  defaultMessage="1. Create a team and invite colleagues."
                  description="Choice 1 if no team on competition page"
                />
              </p>
              <p>
                <FormattedMessage
                  id="pageCompetitionNoTeamChoise2Description"
                  defaultMessage="2. Be passive. Then you will be eligible for invitations to teams, or randomly assigned to a team when the competition starts."
                  description="Choice 2 if no team on competition page"
                />
              </p>
            </Fragment>
          ) : (
            <FormattedMessage
              id="pageCompetitionNoFixedTeamDescription"
              defaultMessage="How fun that you’re participating in the competition! You will be assigned a team before the competition starts. Good luck in the competition!"
              description="Description for no fixed team on competition page"
            />
          )}
          <Gap />
          <CompetitionTeamInvites
            data={me.teamInvites}
            contestId={contest.id}
          />
        </Fragment>
      );
    }

    const { captain, name, users, pendingInvites } = me.team;

    // Before competition starts
    if (isBefore(start)) {
      const canLeave = users ? users.length < 2 : true;
      const usersLength = users ? users.length : 0;
      const noUsers = pendingInvites.length + usersLength;

      return (
        <Fragment>
          <p>
            {canCreateTeam ? (
              <FormattedMessage
                id="pageCompetitionTeamDescription"
                defaultMessage="Welcome to your team!{NewLine}{NewLine} Only the creator of the team can invite new team members. If you can't find your colleague in the search function, it could be due to two reasons.{NewLine} 1. The colleague has joined another team{NewLine} 2. The colleague is not registered on Wellr.{NewLine}{NewLine} Use the chat to communicate between team members."
                description="Description for team on competition page"
                values={{ NewLine: <br /> }}
              />
            ) : (
              <FormattedMessage
                id="pageCompetitionFixedTeamDescription"
                defaultMessage="Welcome to the competition! Here you can chat with your teammates while waiting for the competition to start."
                description="Description for fixed team on competition page"
              />
            )}
          </p>
          {renderInviteList(pendingInvites)}
          <Gap />
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="pageCompetitionTeamName"
              defaultMessage="{name}"
              description="Name for team on competition page"
              values={{
                name,
                noUsers,
                maxUsersPerTeam,
              }}
            />
          </Heading>
          <CompetitionUserList
            users={users}
            userId={me.id}
            captain={captain}
            onEditUser={onOpenEditAliasModal}
          />
          <Gap />
          {/* {canLeave && ( TODO: Might want to use later, but for now we don't want to show this
            <ButtonGrid>
              <Button
                color="error"
                border="error"
                background="transparent"
                onClick={onOpenLeaveTeamModal}
              >
                <FormattedMessage
                  id="leaveTeamButton"
                  defaultMessage="Leave team"
                  description="Leave team button"
                />
              </Button>
            </ButtonGrid>
          )} */}
        </Fragment>
      );
    }

    // Ongoing competition
    return (
      <Fragment>
        {compensate && (
          <Fragment>
            <ListMenu
              menu={[
                {
                  id: 1,
                  text: intl.formatMessage({
                    id: 'menuAboutCompetitionCompensation',
                    defaultMessage:
                      'This competition compensates for uneven teams - read more here',
                    description: 'Menu item for competition compensation',
                  }),
                  link: `${routes.INSPIRATION_ARTICLE}/compensation-for-uneven-teams-in-wellr-competitions`,
                  icon: IconType.Inspiration,
                },
              ]}
            />
          </Fragment>
        )}
        <br />
        {contest.darkMode ? (
          <>
            {darkModeContent.data && (
              <>
                {darkModeContent.data.title != null && (
                  <Heading>{getText(darkModeContent.data.title)}</Heading>
                )}
                {darkModeContent.data.image != null && (
                  <Image
                    image={getImage(darkModeContent.data.image)}
                    bottomMargin={20}
                  />
                )}
                {darkModeContent.data.content != null && (
                  <RichText>{darkModeContent.data.content}</RichText>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Heading tag={Tag.H4}>
              <FormattedMessage
                id="pageCompetitionStatusTitle"
                defaultMessage="Status"
                description="Title for status section on competition page"
              />
            </Heading>
            <CompetitionStatus
              totalAmount={totalAmount}
              goals={goals}
              teams={teams}
              team={me.team}
              onClick={onNavigate(
                `${routes.COMPETE}/competitions/${id}/all-teams`
              )}
            />
          </>
        )}
        {contest.darkMode ? (
          <>
            <br />
            <Heading tag={Tag.H4}>Dina steg</Heading>
            <StaticCard>
              <UserStepsContainer>
                <Heading tag={Tag.H4}>{me.alias}</Heading>
                <StepText>
                  {me.total}{' '}
                  <FormattedMessage
                    id="activitiesUnitText"
                    defaultMessage="steps"
                    description="Unit text for activities"
                  />
                </StepText>
              </UserStepsContainer>
            </StaticCard>
          </>
        ) : (
          <>
            <Gap />
            <SectionHeader>
              {individualTopList ? (
                <Fragment>
                  <HeadingWithInfo
                    tag={Tag.H4}
                    onClickInfo={onOpenTopListInfoModal}
                  >
                    <FormattedMessage
                      id="pageCompetitionTopListTitle"
                      defaultMessage="Top list"
                      description="Title for top list section on competition page"
                    />
                  </HeadingWithInfo>
                  <SmallButtonGrid>
                    <Button
                      color={allTeamView ? 'blue' : 'white'}
                      border={allTeamView ? 'borderDark' : 'blue'}
                      background={allTeamView ? 'transparent' : 'blue'}
                      onClick={onSetTeamView(false)}
                      size="small"
                    >
                      <FormattedMessage
                        id="myTeamButton"
                        defaultMessage="My team"
                        description="Competition top list for my team button"
                      />
                    </Button>
                    <Button
                      color={allTeamView ? 'white' : 'blue'}
                      border={allTeamView ? 'blue' : 'borderDark'}
                      background={allTeamView ? 'blue' : 'transparent'}
                      onClick={onSetTeamView(true)}
                      size="small"
                    >
                      <FormattedMessage
                        id="allTeamsButton"
                        defaultMessage="All teams"
                        description="Competition top list for all teams button"
                      />
                    </Button>
                  </SmallButtonGrid>
                </Fragment>
              ) : (
                <Heading tag={Tag.H4}>{name}</Heading>
              )}
            </SectionHeader>
            {allTeamView ? (
              <CompetitionTopList
                me={me}
                users={toplist ?? []}
                total={totalAmount}
                individualResults={individualResults}
              />
            ) : (
              <CompetitionTopList
                me={me}
                users={users}
                total={rawUserTeamTotal}
                individualResults={individualResults}
              />
            )}
          </>
        )}
        <Gap />
        {goals.map((goal) => (
          <Fragment key={id}>
            <HealthChoices
              contestId={id}
              goalId={goal.id}
              data={goal.healthChoices}
              completed={me.completedHealthChoices[goal.id]}
            />
            {showRegisteredActivitiesSection && (
              <LastActivities
                startDate={start}
                endDate={stop}
                allowedActivities={goal.allowedActivities}
                allowAllActivities={goal.allowAllActivities}
              />
            )}
          </Fragment>
        ))}
        {reference != null && (
          <>
            <Heading tag={Tag.H4}>
              <FormattedMessage
                id="pageCompetitionArticlesTitle"
                defaultMessage="Get going"
                description="Title for articles section on competition page"
              />
            </Heading>
            <DragSlider id="reference" slidesPerView={2}>
              {reference.map((item, i) => (
                <ImageCard
                  key={`ref-${i + 1}`}
                  title={getText(item.title)}
                  image={getImage(item.image)}
                  onClick={onNavigate(
                    getRefLink(item._type, item.slug.current)
                  )}
                  size="large"
                />
              ))}
            </DragSlider>
          </>
        )}
      </Fragment>
    );
  }, [
    contest,
    darkModeContent.data,
    getText,
    onNavigate,
    intl,
    onOpenTopListInfoModal,
    allTeamView,
    onSetTeamView,
    rawUserTeamTotal,
    renderInviteList,
    onOpenEditAliasModal,
    onOpenLeaveTeamModal,
    showRegisteredActivitiesSection,
  ]);

  // Loading
  if (activityTypes.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!activityTypes.data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageCompetitionEmptyState"
          defaultMessage="Competition not found"
          description="Empty state for competition"
        />
      </EmptyState>
    );
  }

  const {
    id,
    finish,
    title,
    description,
    wellrPoints,
    start,
    stop,
    image,
    me,
    goals,
    maxUsersPerTeam,
  } = contest;

  return (
    <Fragment>
      <ContestHeader
        title={title}
        categoryTitle={intl.formatMessage({
          id: 'pageCompetitionCategoryTitle',
          defaultMessage: 'Competition',
          description: 'Competition category title',
        })}
        image={image}
        start={start}
        stop={stop}
        noStopDate={finish === 'Goal'}
        wellrPoints={wellrPoints}
        languageCode={language?.languageCode}
      />
      <ContentWidth isSurface>
        <ButtonGrid>
          <Button
            color="blue"
            background="transparent"
            border="borderDark"
            onClick={onOpenInstructionModal}
          >
            <FormattedMessage
              id="competitionRulesButton"
              defaultMessage="Rules and instructions"
              description="Competition rules button"
            />
          </Button>
          {actions}
        </ButtonGrid>
        {content}
      </ContentWidth>
      <RegisterActivityModal
        isOpen={registerModalOpen}
        onClose={onCloseRegisterModal}
        allowAllActivities={goals.length > 0 && goals[0].allowAllActivities}
        allowedActivities={goals.length > 0 ? goals[0].allowedActivities : []}
      />
      <ContentModal
        title={intl.formatMessage({
          id: 'pageCompetitionInstructionsTitle',
          defaultMessage: 'Rules & instructions',
          description: 'Competitions page instructions title',
        })}
        isOpen={instructionsModalOpen}
        onClose={onCloseInstructionModal}
      >
        {description ? <RichText>{description}</RichText> : '-'}
      </ContentModal>
      <CreateTeamModal
        contestId={id}
        isOpen={createTeamModalOpen}
        onClose={onCloseCreateTeamModal}
      />
      <LeaveTeamModal
        contestId={id}
        isOpen={leaveTeamModalOpen}
        onClose={onCloseLeaveTeamModal}
      />
      <EditAliasModal
        contestId={id}
        isOpen={editAliasModalOpen}
        onClose={onCloseEditAliasModal}
      />
      <ContentModal
        isOpen={topListInfoModalOpen}
        onClose={onCloseTopListInfoModal}
        title={intl.formatMessage({
          id: 'pageCompetitionTopListTitle',
          defaultMessage: 'Top list',
          description: 'Title for top list section on competition page',
        })}
      >
        <FormattedMessage
          id="pageCompetitionTopListInfo"
          defaultMessage={`
            You can see your team's status and an individual leaderboard for all teams.{NewLine}{NewLine}
            My team:{NewLine}
            Here, your team's status is presented, including the percentage of the team's total steps contributed by you or each member.{NewLine}{NewLine}
            All teams:{NewLine}
            Here, a leaderboard is presented for individuals who have taken the most steps, along with the percentage of the total distance they have covered (if your company has chosen to display it).`}
          description="Info text for top list on competition page"
          values={{ NewLine: <br /> }}
          tagName={'p'}
        />
      </ContentModal>
      {me.team && (
        <InviteTeamMembersModal
          contestId={id}
          team={me.team}
          maxUsers={maxUsersPerTeam}
          isOpen={inviteTeamMemberModalOpen}
          onClose={onCloseInviteTeamMemberModal}
        />
      )}
    </Fragment>
  );
};

export default CompetitionOverview;
