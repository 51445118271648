import React, { FC, useMemo } from 'react';
import Heading, { Tag } from '../../UI/Heading';
import Button from '../../UI/Button';
import { Action, Body, Header, ImageContainer, Img } from './styles';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string
  body: string
  ctaLabel: string | null
  link: string | null
  imageUrl: string | null
}

const NotificationFragment: FC<Props> = ({title, body, link, ctaLabel, imageUrl}) => {
  const intl = useIntl();

  const navigate = useNavigate();

  const onClick = () => {
  if (link) {
    const path = new URL(link).pathname;
    navigate(path);
  }
}

  const defaultCtaLabel = () => {
    return intl.formatMessage({
      id: 'defaultCtaLabel',
      defaultMessage: 'Go',
      description: 'Default label for Cta button in notification',
    })
  }

  const actions = useMemo(() => {
    if (link == null || !link) {
      return null;
    }
    return (
        <Action>
          <Button background="blue" onClick={onClick}>
            {ctaLabel ?? defaultCtaLabel()}
          </Button>
        </Action>
    );
  }, [onClick, ctaLabel]);

  const image = useMemo(() =>{
    if (!imageUrl) {
      return null
    }
    return (
      <ImageContainer>
        <Img src={imageUrl} alt={""} />
      </ImageContainer>
    )

  }, [imageUrl])

  return (
    <div>
      {image}
      <Header>
        <Heading tag={Tag.H3}>{title}</Heading>
      </Header>
      <Body>{body}</Body>
      {actions}
    </div>
  )
}

export default NotificationFragment;
