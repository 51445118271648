import { FC, Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as routes from 'router/routes';
import Loader from 'components/UI/Loader';
import Heading, { Tag } from 'components/UI/Heading';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import {
  localizationSelector,
  setLayoutHeader,
  useGetAvailableContestsQuery,
} from 'store';
import { ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import ChallengeCardList from 'components/fragments/ChallengeCardList';

const AvailableChallenges: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageCompeteTitle',
          defaultMessage: 'Compete',
          description: 'Compete page title',
        }),
        icon: IconType.Back,
        link: routes.COMPETE,
      })
    );
  }, [dispatch, intl]);

  // Hooks
  const available = useGetAvailableContestsQuery({
    language: language?.languageCode,
  });

  if (available.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!available.data?.length) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageAvailableChallengesEmptyState"
          defaultMessage="No available challenges found"
          description="Empty state for available challenges"
        />
      </EmptyState>
    );
  }

  return (
    <ContentWidth isSurface>
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompetitionsAvailableChallengeTitle"
            defaultMessage="Available challenges"
            description="Section title for available challenges"
          />
        </Heading>
        <ChallengeCardList languageCode={language?.languageCode} />
      </Fragment>
    </ContentWidth>
  );
};

export default AvailableChallenges;