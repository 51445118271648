import styled from 'styled-components';
import { breakpoints } from 'styles';

type GridProps = {
  isActive: boolean;
};

export const Grid = styled.div<GridProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isActive }) => (isActive ? 'column' : 'row')};

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`;

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DisabledTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
`;

export const Column = styled.div`
  flex: 1;
  margin: 16px;

  h3 {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.grayText};
    margin: 8px 0 0;
  }
`;

export const ActionColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0 16px 16px;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px;
`;
