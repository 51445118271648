import { DefaultTheme } from 'styled-components';
import {
  ContestGoal,
  ContestItem,
  ContestType,
  GoalData,
  SubGoalData,
} from 'models';
import { sortArrayByProperty } from 'utils/array';
import { isAfter, isBefore } from 'utils/date';

export function filterContests(
  type: ContestType,
  accepted: boolean,
  completed: boolean,
  list?: ContestItem[],
  started?: boolean
): ContestItem[] {
  if (!list) {
    return [];
  }
  return list.filter((item) => {
    if (typeof item.accepted === 'undefined') {
      return item.type === type;
    }
    return (
      item.type === type &&
      item.accepted === accepted &&
      item.completed === completed &&
      (started === undefined ||
        (started && isAfter(item.start)) ||
        (started === false && isBefore(item.start)))
    );
  });
}

export function getGoalDataFromContestGoal(goal: ContestGoal): GoalData {
  let subGoals: SubGoalData[] = [];
  goal.subGoals.forEach((subGoal, i) => {
    const isLast = i + 1 === goal.subGoals.length;
    const prev = i !== 0 ? goal.subGoals[i - 1] : null;

    subGoals.push({
      id: subGoal.id,
      startLat: prev?.lat || goal.startLat,
      startLng: prev?.lng || goal.startLng,
      stopLat: subGoal.lat,
      stopLng: subGoal.lng,
      amount: subGoal.amount,
    });

    if (isLast) {
      subGoals.push({
        id: goal.id,
        startLat: subGoal.lat,
        startLng: subGoal.lng,
        stopLat: goal.stopLat,
        stopLng: goal.stopLng,
        amount: goal.amount,
      });
    }
  });
  return {
    id: goal.id,
    subGoals: sortArrayByProperty(subGoals, 'amount'),
    startLat: goal.startLat || 0,
    startLng: goal.startLng || 0,
    stopLat: goal.stopLat || 0,
    stopLng: goal.stopLng || 0,
  };
}

export function getRankColor(
  theme: DefaultTheme,
  rank: number,
  isMine: boolean
) {
  const { gold, silver, bronze, blue, orangeDark } = theme.colors;
  if (rank === 1) {
    return gold;
  }
  if (rank === 2) {
    return silver;
  }
  if (rank === 3) {
    return bronze;
  }
  if (isMine) {
    return orangeDark;
  }
  return blue;
}

export function getSubGoalAmount(goals: SubGoalData[], total: number) {
  return goals.reduce((min, obj) => {
    if (obj.amount < min && obj.amount > 0 && total <= obj.amount) {
      return obj.amount;
    }
    return min;
  }, Infinity);
}

/**
 * Returns null if we are on a different subgoal than the provided `evaluatedSubGoal`
 * Returns the progress in % relative to the previous subgoal.
 * Example: if we have 3 subgoals of 100, 200 and 300 and we are at 250, we are 50% of the way to the 300 subgoal.
 */
export function getSubGoalProgress(
  currentTotal: number,
  evaluatedSubGoal: SubGoalData,
  subGoals: SubGoalData[]
) {
  if (currentTotal > evaluatedSubGoal.amount) {
    return null;
  }
  for (let i = 0; i < subGoals.length; i++) {
    const subGoal = subGoals[i];
    if (subGoal.amount > 0 && currentTotal <= subGoal.amount) {
      if (subGoal.id === evaluatedSubGoal.id) {
        // We are on the current subgoal, lets calculate the progress
        const previousGoalAmount = i > 0 ? subGoals[i - 1].amount : 0;
        const subGoalProgress =
          ((currentTotal - previousGoalAmount) /
            (subGoal.amount - previousGoalAmount)) *
          100;
        return subGoalProgress;
      }
      return null;
    }
  }
  return null;
}

export function checkShowRegisterActivities(goals?: ContestGoal[]) {
  if (!goals?.length) {
    return false;
  }
  const { allowAllActivities, allowedActivities } = goals[0];
  if (allowAllActivities) {
    return true;
  }
  if (allowedActivities.length === 1 && allowedActivities[0] === 'walk-step') {
    return false;
  }
  if (!allowedActivities.length) {
    return false;
  }
  return true;
}
