import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import DropdownMenu from 'components/UI/DropdownMenu';

import { ChatAttachment, ChatMessage } from 'models/chat/chat';

import { Message, MessageRow, MessageAuthor } from './styles';
import ChatMessageItemImage from './ChatMessageItemImage';

type Props = {
  message: ChatMessage;
  onRemove: (id: string) => void;
};

const ChatMessageItem: FC<Props> = ({ message, onRemove }) => {
  const intl = useIntl();

  const renderAttachments = useCallback(
    (attachment: ChatAttachment) => (
      <ChatMessageItemImage
        key={attachment.id}
        attachmentId={attachment.id}
        chatEntryId={message.id}
        alt={attachment.fileName}
      />
    ),
    [message]
  );

  // Message with or without button
  const messageItem = useMemo(() => {
    if (message.isMine) {
      return (
        <DropdownMenu
          menu={[
            {
              id: 1,
              text: intl.formatMessage({
                id: 'chatModalRemoveMessage',
                defaultMessage: 'Remove message',
                description: 'Action for remove message in chat modal',
              }),
              color: 'error',
              onClick: () => onRemove(message.id),
            },
          ]}
        >
          <Message isMine>
            {message.chatMessage}
            {message.chatAttachments.map(renderAttachments)}
          </Message>
        </DropdownMenu>
      );
    }
    return (
      <Message>
        {message.chatMessage}
        {message.chatAttachments.map(renderAttachments)}
      </Message>
    );
  }, [onRemove, renderAttachments, message, intl]);

  return (
    <MessageRow isMine={message.isMine}>
      <MessageAuthor>{message.userAlias}</MessageAuthor>
      {messageItem}
    </MessageRow>
  );
};

export default ChatMessageItem;
