import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  max-width: 680px;
  max-height: 400px;
  margin: 0 auto 48px;

  @media (max-width: ${breakpoints.l}px) {
    max-width: none;
    margin: 0 auto 48px;
    border-radius: 0;
  }

  @media (max-width: ${breakpoints.m}px) {
    max-height: 280px;
    margin-bottom: -24px;
  }
`;

export const ImageView = styled.img`
  width: 100%;
  min-height: 256px;
  object-fit: cover;
  user-drag: none;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 300px;
  }
`;

export const ImageOverlay = styled.div`
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

  @media (max-width: ${breakpoints.l}px) {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;
