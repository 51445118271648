import React, { FC } from 'react';

import { Section, Content } from './styles';

const Footer: FC = () => {
  return (
    <Section>
      <Content>
        <svg
          width="104"
          height="40"
          viewBox="0 0 104 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.549 19.7932C99.0329 19.7932 97.8707 20.2945 97.1001 21.4468V20.1189H93.9043V32.2578H97.2011V25.6436C97.2011 23.8396 98.2623 22.9126 99.7781 22.9126C100.448 22.9126 101.231 23.0629 101.774 23.3009L102.267 20.0562C101.812 19.8934 101.256 19.7932 100.549 19.7932ZM87.5374 32.2578H90.8344V14.2183H87.5374V32.2578ZM81.1705 32.2578H84.4675V14.2183H81.1705V32.2578ZM72.873 22.6119C74.3004 22.6119 75.2352 23.4762 75.5385 25.0422H70.1192C70.4099 23.5265 71.3066 22.6119 72.873 22.6119ZM72.8982 19.7932C69.235 19.7932 66.7086 22.4116 66.7086 26.2197C66.7086 30.0281 69.3234 32.571 73.0624 32.571C75.1343 32.571 76.8775 31.9949 78.2797 30.7297L76.3468 28.625C75.4501 29.3768 74.313 29.7901 73.2521 29.7901C71.6982 29.7901 70.435 29.0259 70.1066 27.2722H78.7849C78.8229 26.984 78.8481 26.5582 78.8481 26.1698C78.8481 22.3864 76.4226 19.7932 72.8982 19.7932ZM59.9314 28.6375L57.1524 20.1189H53.6783L50.9374 28.6001L48.3226 20.1189H44.8107L48.9539 32.2578H52.6931L55.3837 24.0524L58.0995 32.2578H61.8639L66.0074 20.1189H62.5588L59.9314 28.6375ZM8.56018 37.9848C8.20047 37.9848 7.83785 37.9317 7.4837 37.8223C6.20704 37.4287 5.25232 36.3701 5.00004 35.0681L1.79931 18.5704C1.42116 16.6199 2.70866 14.735 4.67516 14.3597C6.64225 13.9847 8.54262 15.2615 8.92105 17.2114L10.8419 27.114L16.761 21.3336C18.004 20.1195 19.9424 19.9555 21.3751 20.9432L29.691 26.6766L26.5415 5.86343C26.2444 3.89958 27.6089 2.06838 29.5894 1.77348C31.5708 1.47917 33.4162 2.83232 33.7135 4.79617L38.1104 33.8551C38.3253 35.2747 37.6682 36.6851 36.439 37.4429C35.2094 38.2005 33.6436 38.161 32.4547 37.3413L19.706 28.5513L11.105 36.9509C10.4163 37.6232 9.49821 37.9848 8.56018 37.9848Z"
            fill="#A6B7BF"
          />
        </svg>
      </Content>
    </Section>
  );
};

export default Footer;
