import { useState, useEffect, RefObject } from 'react';

export const useDetectOutsideClick = (
  { current }: RefObject<HTMLInputElement>,
  initialState: boolean
): [boolean, (active: boolean) => void] => {
  // State
  const [isActive, setIsActive] = useState<boolean>(initialState);

  // Handle outside click
  useEffect(() => {
    const clickEvent = (event: Event) => {
      // Check if element exist and contains target
      if (current && !current.contains(event.target as HTMLElement)) {
        setIsActive(false);
      }
    };

    // Event listener
    if (isActive) {
      window.addEventListener('click', clickEvent, true);
    }
    return () => window.removeEventListener('click', clickEvent, true);
  }, [isActive, current]);

  return [isActive, setIsActive];
};
