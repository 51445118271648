import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { localizationSelector, useGetContestQuery } from 'store';

import { useAppSelector } from 'hooks/redux';

import { IconType } from 'components/UI/Icon';
import ChatModal from 'components/modals/ChatModal';
import EmptyState from 'components/UI/EmptyState';
import Loader from 'components/UI/Loader';

const Competition: FC = () => {
  const intl = useIntl();
  const RE_FETCH_TIMEOUT_MS = 1000 * 10
  const [lastGetContestFetch, setLastGetContestFetch] = useState(0);
  const { contestId } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data, isLoading, isFetching, fulfilledTimeStamp} = useGetContestQuery(
    {
      contestId,
      language: language?.languageCode,
    },
    {
      refetchOnMountOrArgChange: 20,
      refetchOnFocus: lastGetContestFetch + RE_FETCH_TIMEOUT_MS < Date.now()
    }
  );

  useEffect(() => {
    setLastGetContestFetch(Date.now())
  }, [fulfilledTimeStamp])

  // Chatbot
  const chatBot = useMemo(() => {
    if (!contestId || !data?.me.team) {
      return null;
    }
    const { id, name } = data.me.team;
    return (
      <ChatModal
        rooms={[
          {
            title: intl.formatMessage({
              id: 'pageCompetitionChatRoomAll',
              defaultMessage: 'All',
              description: 'Competitions page chatroom all teams',
            }),
            chatId: contestId,
          },
          {
            title: name,
            chatId: id,
          },
        ]}
      />
    );
  }, [contestId, data, intl]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageCompetitionEmptyState"
          defaultMessage="Competition not found"
          description="Empty state for competition"
        />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <Outlet context={{ contest: data, isFetching }} />
      {chatBot}
    </Fragment>
  );
};

export default Competition;
