import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

export const ModalBackground = styled(motion.div)`
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const ModalContainer = styled(motion.div)`
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 424px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${breakpoints.s}px) {
    max-width: none;
  }
`;
