import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  h3 {
    margin: 0;
  }
`;

export const Body = styled.div`
  height: auto;
  overflow-y: auto;
  padding: 20px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Action = styled.div`
  flex: 1;
  margin: 16px;

  button {
    width: 100%;
  }
`;

type ImageProps = {
  filter?: number;
};
export const ImageContainer = styled.div<ImageProps>`
  position: relative;
  min-width: 78px;

  ${({ theme, filter }) => {
  if (filter) {
    return `
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          mix-blend-mode: multiply;
          background: ${theme.colors.black};
          opacity: 0.4;
        }
      `;
  }
}};
`;
