import React, { FC, Fragment, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLocalizedText } from 'hooks/useLocalizedText';
import { useCompleteHealthChoiceMutation } from 'store';
import { HealthChoice } from 'models';
import { Gap } from 'styles';

import Button from 'components/UI/Button';
import StaticCard from 'components/UI/Cards/StaticCard';
import Heading, { Tag } from 'components/UI/Heading';

import { List, Item, ItemText, Column } from './styles';
import CheckBadge from 'components/UI/CheckBadge';

type Props = {
  data: HealthChoice[];
  contestId: string;
  goalId: string;
  completed?: string[] | null;
};

const HealthChoices: FC<Props> = ({ data, contestId, goalId, completed }) => {
  const getText = useLocalizedText();
  const [checkedHealthChoices, setCheckedHealthChoices] = React.useState<
    string[]
  >([]);

  // Hooks
  const [completeHealthChoice, result] = useCompleteHealthChoiceMutation();

  // Complete
  const onComplete = useCallback(
    (healthChoiceId: string) => () => {
      completeHealthChoice({
        contestId,
        goalId,
        healthChoiceId,
      });
    },
    [completeHealthChoice, contestId, goalId]
  );

  useEffect(() => {
    if (result.isLoading) {
      setCheckedHealthChoices((prev) => [
        ...prev,
        result.originalArgs?.healthChoiceId as string,
      ]);
    }
  }, [result.isLoading, result.originalArgs]);

  // No data
  if (!data.length) {
    return null;
  }

  return (
    <Fragment>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="healthChoiceTitle"
          defaultMessage="Health choices"
          description="Title for health choices"
        />
      </Heading>
      <StaticCard>
        <List>
          {data.map((item, i) => {
            return (
              <Item key={`health-choise-${i}`} isLast={data.length === i + 1}>
                <Column>
                  <Heading tag={Tag.H4}>{getText(item.title)}</Heading>
                  <ItemText>
                    <FormattedMessage
                      id="healthChoiceBonus"
                      defaultMessage="{bonus} steps"
                      description="Bonus text for health choices"
                      values={{ bonus: item.bonus }}
                    />
                  </ItemText>
                </Column>
                <div>
                  {!completed?.includes(item.id) &&
                  !checkedHealthChoices.includes(item.id) ? (
                    <Button background="blue" onClick={onComplete(item.id)}>
                      <FormattedMessage
                        id="doneButton"
                        defaultMessage="Done"
                        description="Done button text"
                      />
                    </Button>
                  ) : (
                    <CheckBadge type="grey" />
                  )}
                </div>
              </Item>
            );
          })}
        </List>
      </StaticCard>
      <Gap />
    </Fragment>
  );
};

export default HealthChoices;
