import { CompanyHealthQuiz } from 'models';
import {
  getEndpointURL,
  ObjectWithoutUndefined,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

/** Get health choice stats */
const contestHealthChoiceStatsPath: WellrEndpointPath =
  '/api/contest/{contestId}/healthchoicecount';
const contestHealthChoiceStatsMethod = 'get';
type contestHealthChoiceStats = WellrEndpointDefinition<
  typeof contestHealthChoiceStatsPath,
  typeof contestHealthChoiceStatsMethod
>;

export type GetContestHealthChoiceStatsType = {
  result: contestHealthChoiceStats['responseBody'];
  queryArgs: contestHealthChoiceStats['request']['params']['path'];
};

/** Get activity statistics */
const contestActivityStatsPath: WellrEndpointPath =
  '/api/contest/{contestId}/activity-statistics';
const contestActivityStatsMethod = 'get';
type contestActivityStats = WellrEndpointDefinition<
  typeof contestActivityStatsPath,
  typeof contestActivityStatsMethod
>;

export type GetContestActivityStatsType = {
  result: contestActivityStats['responseBody'];
  queryArgs: contestActivityStats['request']['params']['path'] &
    Required<contestActivityStats['request']['params']['query']>;
};

/** Update company */
const updateCompanyPath: WellrEndpointPath = '/api/company/company/{companyId}';
const updateCompanyMethod = 'patch';
type UpdateCompany = WellrEndpointDefinition<
  typeof updateCompanyPath,
  typeof updateCompanyMethod
>;

export type UpdateCompanyType = {
  result: UpdateCompany['responseBody'];
  queryArgs: UpdateCompany['request']['params']['path'] &
    UpdateCompany['request']['body'];
};

/** List umbrella companies */
const listUmbrellaCompaniesPath: WellrEndpointPath =
  '/api/company/umbrella-company/all';
const listUmbrellaCompaniesMethod = 'get';
type ListUmbrellaCompanies = WellrEndpointDefinition<
  typeof listUmbrellaCompaniesPath,
  typeof listUmbrellaCompaniesMethod
>;

export type ListUmbrellaCompaniesType = {
  result: ListUmbrellaCompanies['responseBody'];
  queryArgs: void;
};

/** Get umbrella company */
const getUmbrellaCompanyPath: WellrEndpointPath =
  '/api/company/umbrella-company';
const getUmbrellaCompanyMethod = 'get';
type GetUmbrellaCompany = WellrEndpointDefinition<
  typeof getUmbrellaCompanyPath,
  typeof getUmbrellaCompanyMethod
>;

export type GetUmbrellaCompaniesType = {
  result: GetUmbrellaCompany['responseBody'];
  queryArgs: ObjectWithoutUndefined<
    GetUmbrellaCompany['request']['params']['query']
  >;
};

/** Get umbrella company communication */
const getUmbrellaCompanyCommunicationPath: WellrEndpointPath =
  '/api/company/umbrella-company/communication/{umbrellaCompanyId}';
const getUmbrellaCompanyCommunicationMethod = 'get';
type GetUmbrellaCompanyCommunication = WellrEndpointDefinition<
  typeof getUmbrellaCompanyCommunicationPath,
  typeof getUmbrellaCompanyCommunicationMethod
>;

export type GetUmbrellaCompanyCommunicationType = {
  result: GetUmbrellaCompanyCommunication['responseBody'];
  queryArgs: GetUmbrellaCompanyCommunication['request']['params']['path'];
};

/** Update umbrella company communication */
const updateUmbrellaCompanyCommunicationPath: WellrEndpointPath =
  '/api/company/umbrella-company/communication/{umbrellaCompanyId}';
const updateUmbrellaCompanyCommunicationMethod = 'patch';
type UpdateUmbrellaCompanyCommunication = WellrEndpointDefinition<
  typeof updateUmbrellaCompanyCommunicationPath,
  typeof updateUmbrellaCompanyCommunicationMethod
>;

export type UpdateUmbrellaCompanyCommunicationType = {
  result: UpdateUmbrellaCompanyCommunication['responseBody'];
  queryArgs: ObjectWithoutUndefined<
    UpdateUmbrellaCompanyCommunication['request']['params']['path'] &
      UpdateUmbrellaCompanyCommunication['request']['params']['query']
  >;
};

/** Delete umbrella company communication */
const deleteUmbrellaCompanyCommunicationPath: WellrEndpointPath =
  '/api/company/umbrella-company/communication/{umbrellaCompanyId}';
const deleteUmbrellaCompanyCommunicationMethod = 'delete';
type DeleteUmbrellaCompanyCommunication = WellrEndpointDefinition<
  typeof deleteUmbrellaCompanyCommunicationPath,
  typeof deleteUmbrellaCompanyCommunicationMethod
>;

export type DeleteUmbrellaCompanyCommunicationType = {
  result: DeleteUmbrellaCompanyCommunication['responseBody'];
  queryArgs: ObjectWithoutUndefined<
    DeleteUmbrellaCompanyCommunication['request']['params']['path'] &
      DeleteUmbrellaCompanyCommunication['request']['params']['query']
  >;
};

/** Get  company communication */
const getCompanyCommunicationPath: WellrEndpointPath =
  '/api/company/company/communication/{companyId}';
const getCompanyCommunicationMethod = 'get';
type GetCompanyCommunication = WellrEndpointDefinition<
  typeof getCompanyCommunicationPath,
  typeof getCompanyCommunicationMethod
>;

export type GetCompanyCommunicationType = {
  result: GetCompanyCommunication['responseBody'];
  queryArgs: GetCompanyCommunication['request']['params']['path'];
};

/** Update  company communication */
const updateCompanyCommunicationPath: WellrEndpointPath =
  '/api/company/company/communication/{companyId}';
const updateCompanyCommunicationMethod = 'patch';
type UpdateCompanyCommunication = WellrEndpointDefinition<
  typeof updateCompanyCommunicationPath,
  typeof updateCompanyCommunicationMethod
>;

export type UpdateCompanyCommunicationType = {
  result: UpdateCompanyCommunication['responseBody'];
  queryArgs: ObjectWithoutUndefined<
    UpdateCompanyCommunication['request']['params']['path'] &
      UpdateCompanyCommunication['request']['params']['query']
  >;
};

/** Delete  company communication */
const deleteCompanyCommunicationPath: WellrEndpointPath =
  '/api/company/company/communication/{companyId}';
const deleteCompanyCommunicationMethod = 'delete';
type DeleteCompanyCommunication = WellrEndpointDefinition<
  typeof deleteCompanyCommunicationPath,
  typeof deleteCompanyCommunicationMethod
>;

export type DeleteCompanyCommunicationType = {
  result: DeleteCompanyCommunication['responseBody'];
  queryArgs: ObjectWithoutUndefined<
    DeleteCompanyCommunication['request']['params']['path'] &
      DeleteCompanyCommunication['request']['params']['query']
  >;
};

/** Fetch filtered health quiz results */
const getFilteredHealthquizResultsPath: WellrEndpointPath =
  '/api/company/me/quiz/from-date-all-quizes';
const getFilteredHealthquizResultsMethod = 'get';
type GetFilteredHealthquizResults = WellrEndpointDefinition<
  typeof getFilteredHealthquizResultsPath,
  typeof getFilteredHealthquizResultsMethod
>;

export type GetFilteredHealthquizResultsType = {
  // TODO:: Change when type fixed in backend
  // result: GetFilteredHealthquizResults['responseBody'];
  result: Array<{
    averageResult: NonNullable<CompanyHealthQuiz['averageResults']>[number];
    quizDefinition: CompanyHealthQuiz['quizDefinition'];
  }>;
  queryArgs: GetFilteredHealthquizResults['request']['params']['query'];
};

/** Get pulse quizes that can be started */
const getAvailablePulseQuizesPath: WellrEndpointPath =
  '/api/pulsequiz/definitions';
const getAvailablePulseQuizesMethod = 'get';
type GetAvailablePulseQuizes = WellrEndpointDefinition<
  typeof getAvailablePulseQuizesPath,
  typeof getAvailablePulseQuizesMethod
>;

export type GetAvailablePulseQuizesType = {
  result: GetAvailablePulseQuizes['responseBody'];
  queryArgs: void;
};

/** Start pulse quiz */
const startPulseQuizPath: WellrEndpointPath = '/api/pulsequiz';
const startPulseQuizMethod = 'post';
type StartPulseQuiz = WellrEndpointDefinition<
  typeof startPulseQuizPath,
  typeof startPulseQuizMethod
>;

export type StartPulseQuizType = {
  result: StartPulseQuiz['responseBody'];
  queryArgs: StartPulseQuiz['request']['body'];
};

/** Get pulse quizes that can be started */
const getUsageStatisticsPath: WellrEndpointPath = '/api/statistics';
const getUsageStatisticsMethod = 'get';
type GetUsageStatistics = WellrEndpointDefinition<
  typeof getUsageStatisticsPath,
  typeof getUsageStatisticsMethod
>;

export type GetUsageStatisticsType = {
  result: GetUsageStatistics['responseBody'];
  queryArgs: Required<GetUsageStatistics['request']['params']['query']>;
};

/** Update competition bonus steps for compelted health quizes */
const updateContestBonusStepsPath: WellrEndpointPath =
  '/api/contest/{contestId}/bonus-steps';
const updateContestBonusStepsMethod = 'patch';
type UpdateContestBonusSteps = WellrEndpointDefinition<
  typeof updateContestBonusStepsPath,
  typeof updateContestBonusStepsMethod
>;

export type UpdateContestBonusStepsType = {
  result: UpdateContestBonusSteps['responseBody'];
  queryArgs: UpdateContestBonusSteps['request']['body'] &
    UpdateContestBonusSteps['request']['params']['path'];
};

/** Get wellr statistics baseline */
const getStatisticsBaselinePath: WellrEndpointPath =
  '/api/company/me/organization-entity-statistics/baseline';
const getStatisticsBaselineMethod = 'get';
type GetStatisticsBaseline = WellrEndpointDefinition<
  typeof getStatisticsBaselinePath,
  typeof getStatisticsBaselineMethod
>;

export type GetStatisticsBaselineType = {
  result: GetStatisticsBaseline['responseBody'];
  queryArgs: void;
};

/** Get wellr quiz result baseline */
const getQuizResultBaselinePath: WellrEndpointPath =
  '/api/company/me/quiz/baseline';
const getQuizResultBaselineMethod = 'get';
type GetQuizResultBaseline = WellrEndpointDefinition<
  typeof getQuizResultBaselinePath,
  typeof getQuizResultBaselineMethod
>;

export type GetQuizResultBaselineType = {
  result: GetQuizResultBaseline['responseBody'][number]; // Wrong type in swagger, adding [number] fixes it
  queryArgs: Required<GetQuizResultBaseline['request']['params']['query']>;
};

/** Get company health quiz info */
const getHealthQuizInfoPath: WellrEndpointPath =
  '/api/company/company/health-quizes/{companyId}';
const getHealthQuizInfoMethod = 'get';
type GetHealthQuizInfo = WellrEndpointDefinition<
  typeof getHealthQuizInfoPath,
  typeof getHealthQuizInfoMethod
>;

export type GetHealthQuizInfoType = {
  result: GetHealthQuizInfo['responseBody'];
  queryArgs: GetHealthQuizInfo['request']['params']['path'];
};

/** Get content for company health quiz info */
const getHealthQuizInfoContentPath: WellrEndpointPath = '/api/quiz/reference';
const getHealthQuizInfoContentMethod = 'get';
type GetHealthQuizInfoContent = WellrEndpointDefinition<
  typeof getHealthQuizInfoContentPath,
  typeof getHealthQuizInfoContentMethod
>;

export type GetHealthQuizInfoContentType = {
  result: GetHealthQuizInfoContent['responseBody'];
  queryArgs: Required<GetHealthQuizInfoContent['request']['params']['query']>;
};

export const endpoints = {
  getContestHealthChoiceStats: {
    path: (contestId: string) =>
      getEndpointURL(contestHealthChoiceStatsPath, { contestId }),
    method: contestHealthChoiceStatsMethod.toUpperCase(),
  },
  contest: {
    updateBonusSteps: {
      path: (contestId: string) =>
        getEndpointURL(updateContestBonusStepsPath, { contestId }),
      method: updateContestBonusStepsMethod.toUpperCase(),
    },
  },
  getContestActivityStats: {
    path: (contestId: string) =>
      getEndpointURL(contestActivityStatsPath, { contestId }),
    method: contestActivityStatsMethod.toUpperCase(),
  },
  updateCompany: {
    path: (companyId: string) =>
      getEndpointURL(updateCompanyPath, { companyId }),
    method: updateCompanyMethod.toUpperCase(),
  },
  umbrellaCompany: {
    list: {
      path: getEndpointURL(listUmbrellaCompaniesPath),
      method: listUmbrellaCompaniesMethod.toUpperCase(),
    },
    get: {
      path: getEndpointURL(getUmbrellaCompanyPath),
      method: getUmbrellaCompanyMethod.toUpperCase(),
    },
    getCommunication: {
      path: (umbrellaCompanyId: string) =>
        getEndpointURL(getUmbrellaCompanyCommunicationPath, {
          umbrellaCompanyId,
        }),
      method: getUmbrellaCompanyCommunicationMethod.toUpperCase(),
    },
    updateCommunication: {
      path: (umbrellaCompanyId: string) =>
        getEndpointURL(updateUmbrellaCompanyCommunicationPath, {
          umbrellaCompanyId,
        }),
      method: updateUmbrellaCompanyCommunicationMethod.toUpperCase(),
    },
    deleteCommunication: {
      path: (umbrellaCompanyId: string) =>
        getEndpointURL(deleteUmbrellaCompanyCommunicationPath, {
          umbrellaCompanyId,
        }),
      method: deleteUmbrellaCompanyCommunicationMethod.toUpperCase(),
    },
  },
  company: {
    getCommunication: {
      path: (companyId: string) =>
        getEndpointURL(getCompanyCommunicationPath, {
          companyId,
        }),
      method: getCompanyCommunicationMethod.toUpperCase(),
    },
    updateCommunication: {
      path: (companyId: string) =>
        getEndpointURL(updateCompanyCommunicationPath, {
          companyId,
        }),
      method: updateCompanyCommunicationMethod.toUpperCase(),
    },
    deleteCommunication: {
      path: (companyId: string) =>
        getEndpointURL(deleteCompanyCommunicationPath, {
          companyId,
        }),
      method: deleteCompanyCommunicationMethod.toUpperCase(),
    },
  },
  getFilteredHealthquizResults: {
    path: getEndpointURL(getFilteredHealthquizResultsPath),
    method: getFilteredHealthquizResultsMethod.toUpperCase(),
  },
  pulseQuiz: {
    getAvailable: {
      path: getEndpointURL(getAvailablePulseQuizesPath),
      method: getAvailablePulseQuizesMethod.toUpperCase(),
    },
    start: {
      path: getEndpointURL(startPulseQuizPath),
      method: startPulseQuizMethod.toUpperCase(),
    },
  },
  getUsageStats: {
    path: getEndpointURL(getUsageStatisticsPath),
    method: getUsageStatisticsMethod.toUpperCase(),
  },
  getStatisticsBaseline: {
    path: getEndpointURL(getStatisticsBaselinePath),
    method: getStatisticsBaselineMethod,
  },
  getQuizResultBaseline: {
    path: getEndpointURL(getQuizResultBaselinePath),
    method: getQuizResultBaselineMethod,
  },
  getHealthQuizInfo: {
    path: (companyId: string) =>
      getEndpointURL(getHealthQuizInfoPath, { companyId }),
    method: getHealthQuizInfoMethod,
  },
  getHealthQuizInfoContent: {
    path: getEndpointURL(getHealthQuizInfoContentPath),
    method: getHealthQuizInfoContentMethod,
  },
};
