import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import { useUpdateMemberDepartmentMutation } from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import UpdateUserDepartmentForm from 'components/forms/UpdateUserDepartmentForm';
import { usePretendCompany } from 'hooks/usePretendCompany';

type Props = {
  membershipId: string;
  departmentId: string;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateUserDepartmentModal: FC<Props> = ({
  membershipId,
  departmentId,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const companyId = usePretendCompany();

  const [updatedMemberDepartment, updatedMemberDepartmentResult] =
    useUpdateMemberDepartmentMutation();

  useEffect(() => {
    if (updatedMemberDepartmentResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateUserModalSuccess',
          defaultMessage: 'User update successful',
          description: 'User update success message',
        })
      );
      onClose();
    }
  }, [updatedMemberDepartmentResult, intl, onClose]);

  // Content
  const content = useMemo(() => {
    if (updatedMemberDepartmentResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <UpdateUserDepartmentForm
        departmentId={departmentId}
        submitForm={(data) =>
          updatedMemberDepartment({
            companyId,
            membershipId: membershipId,
            departmentId: data.departmentId === '' ? null : data.departmentId,
          })
        }
      />
    );
  }, [
    companyId,
    departmentId,
    membershipId,
    updatedMemberDepartment,
    updatedMemberDepartmentResult.isLoading,
  ]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="manageUserDepartmentModalTitle"
              defaultMessage="Change user department"
              description="Change user department modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateUserDepartmentModal;
