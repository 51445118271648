import React, { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as routes from 'router/routes';
import CategoryCard from 'components/UI/Cards/CategoryCard';
import Loader from 'components/UI/Loader';

import {
  localizationSelector,
  setLayoutHeader,
  useGetDietCategoryListQuery,
} from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getImage } from 'utils/asset';
import { ContentWidth } from 'styles';

import { CardList } from './styles';

const Diet: FC = () => {
  const intl = useIntl();
  const getText = useLocalizedText();
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageDietTitle',
          defaultMessage: 'Diet',
          description: 'Page title for diet',
        }),
      })
    );
  }, [dispatch, intl]);

  // Hooks
  const { data, isLoading } = useGetDietCategoryListQuery({
    language: language?.languageCode,
  });

  // Render content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!data || !data.length) {
      return null;
    }

    return (
      <CardList>
        {data.map(({ _id, slug, title, image, numberOfRecipes }) => {
          return (
            <CategoryCard
              key={_id}
              title={getText(title)}
              byline={`${numberOfRecipes} ${intl.formatMessage({
                id: 'pageDietNoRecipesByline',
                defaultMessage: 'recipes',
                description: 'No recipes byline for diet',
              })}`}
              link={`${routes.DIET_CATEGORY}/${slug.current}`}
              image={getImage(image)}
            />
          );
        })}
      </CardList>
    );
  }, [getText, data, isLoading, intl]);

  return <ContentWidth isSurface>{content}</ContentWidth>;
};

export default Diet;
