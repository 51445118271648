import { FC, useEffect, useMemo, useState } from 'react';

import Button from 'components/UI/Button';
import Heading, { Tag } from 'components/UI/Heading';
import StaticCard from 'components/UI/Cards/StaticCard';
import WellrCoins from 'components/UI/WellrCoins';

import { getDateDistance } from 'utils/date';
import { MediaDisplay } from 'styles';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { ContentText } from 'models';
import { getAsset } from 'services';

import {
  CardTop,
  Grid,
  Column,
  Header,
  HeaderColLeft,
  HeaderColRight,
  ActionColumn,
  Description,
  Byline,
} from './styles';

type Props = {
  title: ContentText | null;
  summary: ContentText | null;
  date: string;
  wellrPoints: number;
  buttonText?: string;
  buttonActiveText?: string;
  logoAssetId?: string | null;
  isActive?: boolean;
  onClick?: () => void;
};

const PulseCard: FC<Props> = ({
  title,
  summary,
  date,
  wellrPoints,
  onClick,
  buttonText,
  logoAssetId,
}) => {
  const getText = useLocalizedText();

  // State
  const [companyLogoSrc, setCompanyLogoSrc] = useState<string | null>(null);

  // Fetch logo
  useEffect(() => {
    async function fetchLogo(assetId: string) {
      const src = await getAsset(assetId);
      setCompanyLogoSrc(src);
    }
    if (logoAssetId) {
      fetchLogo(logoAssetId);
    }
  }, [logoAssetId]);

  // Card top
  const cardTop = useMemo(() => {
    if (!companyLogoSrc) {
      return null;
    }
    return (
      <CardTop>
        <img src={companyLogoSrc} alt="company logo" />
      </CardTop>
    );
  }, [companyLogoSrc]);

  return (
    <div>
      {cardTop}
      <StaticCard>
        <Grid>
          <Column>
            <Header>
              <HeaderColLeft>
                <Heading tag={Tag.H3}>{getText(title)}</Heading>
                <Byline>{getDateDistance(date)}</Byline>
              </HeaderColLeft>
              <HeaderColRight>
                <MediaDisplay breakpoint="s">
                  <WellrCoins count={wellrPoints} />
                </MediaDisplay>
              </HeaderColRight>
            </Header>
            <Description>{getText(summary)}</Description>
          </Column>
          <ActionColumn>
            <MediaDisplay breakpoint="s" isLarger>
              <WellrCoins count={wellrPoints} />
            </MediaDisplay>
            <Button background="blue" onClick={onClick}>
              {buttonText}
            </Button>
          </ActionColumn>
        </Grid>
      </StaticCard>
    </div>
  );
};

export default PulseCard;
