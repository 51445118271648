import { parseISO, getWeek, getMonth, getYear, startOfWeek, startOfMonth } from 'date-fns';
import { AggregatedData, DataItem } from 'models/statitics/statitics';

const groupByTimeFrame = (data: DataItem[], selectedTimeFrame: 'day' | 'week' | 'month'): AggregatedData[] => {
  const groupedData: Record<string, AggregatedData> = {};

  data.forEach(item => {
    const date = parseISO(item.date);
    let periodKey: string;
    let periodStartDate: Date;

    switch (selectedTimeFrame) {
      case 'week':
        periodStartDate = startOfWeek(date, { weekStartsOn: 1 });
         periodKey = `${getYear(periodStartDate)}-W${getWeek(periodStartDate)}`;
        break;
      case 'month':
        periodStartDate = startOfMonth(date);
        periodKey = `${getYear(periodStartDate)}-M${getMonth(periodStartDate) + 1}`;
        break;
      default:
        periodStartDate = date;
        periodKey = item.date;
    }

    const aggregatedData = groupedData[periodKey] || { ...item, periodStart: periodStartDate, totalUsers: 0, gdprUsers: 0, activeUsers: 0, noPendingHealthQuizUsers: 0, totalWellrPoints: 0 };
    groupedData[periodKey] = aggregatedData;

    aggregatedData.totalUsers = item.totalUsers;
    aggregatedData.gdprUsers = item.gdprUsers;
    aggregatedData.activeUsers += item.activeUsers;
    aggregatedData.noPendingHealthQuizUsers += item.noPendingHealthQuizUsers;
    aggregatedData.totalWellrPoints = item.totalWellrPoints;
  });

  return Object.values(groupedData);
};

export { groupByTimeFrame };
