import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import BackgroundCover from 'components/UI/BackgroundCover';
import * as routes from 'router/routes';

import { Content } from './styles';

const NotFound: FC = () => {
  const navigate = useNavigate();
  return (
    <BackgroundCover padding>
      <Content>
        <Icon type={IconType.Search} size={80} color="white" />
        <Heading>
          <FormattedMessage
            id="notFoundTitle"
            defaultMessage="Page not found"
            description="Not found page title"
          />
        </Heading>
        <p>
          <FormattedMessage
            id="notFoundText"
            defaultMessage="Could not find the page you are looking for. Click the button below to go to the start page."
            description="Not found page text"
          />
        </p>
        <Button onClick={() => navigate(routes.ROOT)} background="orange">
          <FormattedMessage
            id="goToStartButton"
            defaultMessage="Go to start"
            description="Go to start button text"
          />
        </Button>
      </Content>
    </BackgroundCover>
  );
};

export default NotFound;
