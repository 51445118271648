import { FC, ReactNode, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { motion } from 'framer-motion';

import { Image } from 'models';
import { ThemeColor } from 'styles';

import {
  Container,
  Content,
  Illustration,
  ImageOverlay,
  ImageView,
  ImageWrapper,
  NoImageWrapper,
} from './styles';

type Props = {
  children: ReactNode;
  image?: Image | null;
  background?: ThemeColor;
  illustrationColor?: ThemeColor;
};

const HeroSmall: FC<Props> = ({
  children,
  image,
  illustrationColor = 'blueDark',
  background = 'blue',
}) => {
  const theme = useTheme();

  // Background image
  const bgImage = useMemo(() => {
    if (image) {
      return (
        <ImageWrapper>
          <ImageView src={image.src} alt={image.alt} />
          <ImageOverlay />
        </ImageWrapper>
      );
    }
    return (
      <NoImageWrapper>
        <Illustration width="777px" height="737px" viewBox="0 0 777 737">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <motion.path
              d="M686.587703,616.812113 L522.472586,454.730999 L358.228838,616.947057 C342.743498,632.242936 321.280567,639.838193 299.61762,637.621223 C277.983816,635.42368 258.472233,623.670534 246.406414,605.581766 L-1.75125493,233.394274 C-24.0815166,199.913291 -15.0261521,154.643663 18.4626784,132.283687 C51.9515089,109.923711 97.1994651,118.939828 119.524868,152.420814 L318.715229,451.169748 L471.337682,300.432892 C499.73233,272.388826 545.367824,272.370071 573.739395,300.390809 L700.00794,425.094747 L724.741279,333.264875 C735.207825,294.380688 775.207273,271.332778 814.065772,281.770638 C852.929128,292.213355 875.94661,332.201577 865.470349,371.080907 L808.179306,583.826021 C801.430308,608.88688 781.876672,628.480658 756.833387,635.27549 C750.540985,636.983931 744.137193,637.81762 737.787191,637.820229 C718.868355,637.828004 700.404394,630.458134 686.587703,616.812113 Z"
              initial={false}
              transition={{ duration: 0.4 }}
              animate={{
                fill: theme.colors[illustrationColor],
              }}
              fillRule="nonzero"
              transform="translate(427.000000, 379.000000) scale(-1, 1) rotate(21.000000) translate(-427.000000, -379.000000) "
            />
          </g>
        </Illustration>
      </NoImageWrapper>
    );
  }, [image, theme, illustrationColor]);

  return (
    <Container background={background}>
      {bgImage}
      <Content>{children}</Content>
    </Container>
  );
};

export default HeroSmall;
