import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Login2FAValues } from 'models';
import { ActionContainer } from 'styles';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

type Props = {
  id: string;
  submitForm: (code: Login2FAValues) => void;
};

const Login2FAForm: FC<Props> = ({ id, submitForm }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => submitForm({ id, twoFactorAuthCode: values.code }),
    [submitForm, id]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        <FormattedMessage
          id="login2FADescription"
          defaultMessage="Enter a valid code displayed in your authentication app on your device"
          description="Login 2FA description"
        />
      </p>
      <TextField
        label={intl.formatMessage({
          id: 'inputCodeLabel',
          defaultMessage: 'Code',
          description: 'Label for code input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputCodePlaceholder',
          defaultMessage: 'Enter code',
          description: 'Placeholder for code input',
        })}
        error={errors.code}
        register={register('code', {
          required: intl.formatMessage({
            id: 'inputErrorCodeRequired',
            defaultMessage: 'Code is required',
            description: 'Input error for code required',
          }),
        })}
      />
      <ActionContainer>
        <Button type="submit" color="black" background="yellow">
          <FormattedMessage
            id="confirmButton"
            defaultMessage="Confirm"
            description="Confirm button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default Login2FAForm;
