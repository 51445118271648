import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CardTop = styled.div`
  background: ${({ theme }) => theme.colors.black};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0 0;
  padding-bottom: 16px;
  margin-bottom: -16px;

  img {
    height: 24px;
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const HeaderColLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HeaderColRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Byline = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 12px;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
`;

export const Column = styled.div`
  flex: 1;
  margin: 16px;

  h3 {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.grayText};
    margin: 8px 0 0;
  }
`;

export const ActionColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0 16px 16px;
  }
`;
