import styled from 'styled-components';

export const Section = styled.footer`
  margin-top: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 48px;
`;

export const Content = styled.div`
  text-align: center;
`;

export const Logo = styled.img`
  width: 100%;
  height: 48px;
  margin: 0;
`;
