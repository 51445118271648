import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StatsCardList = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

export const HeroGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 120px;

  @media (max-width: ${breakpoints.m}px) {
    margin: 32px 0 16px;
    gap: 56px;
  }

  @media (max-width: ${breakpoints.s}px) {
    gap: 36px;
  }
`;

export const HeroCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 96px;
    height: 96px;
  }

  h2 {
    margin: 16px 0 4px;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
`;
