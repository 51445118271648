import styled from 'styled-components';
import { breakpoints } from 'styles';

export const HeroGrid = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 48px;
`;

export const HeroCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 48px;
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-weight: 500;
    margin: 32px 0 0;
  }
`;

export const HeroActions = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
  }
`;

export const CardList = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  gap: 16px;
`;

export const ChartWrap = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const HealthCardChartWrap = styled.div`
  width: 75%;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`;

export const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;

  h3 {
    margin: 0;
  }
`;

export const OverallResult = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.backgroundDark};
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
`;
